import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, Navigate } from 'react-router-dom';
import { FirebaseProvider, useFirebase } from './contexts/FirebaseContext';
import { RoleProvider, useRole, ProtectedRoute } from './contexts/RoleContext';
import LandingPage from './features/landing/LandingPage';
import CurrentOrganization from './features/current-org/CurrentOrganization';
import { AdminPanel } from './features/admin/AdminPanel';
import ScenarioPlanning from './features/scenarios/ScenarioPlanning';
import Analysis from './features/analysis/Analysis';
import ApprovalQueue from './features/approvals/ApprovalQueue';
import TalentAcquisition from './features/talent/TalentAcquisition';
import CompensationFramework from './features/compensation/CompensationFramework';
import TeamViewer from './features/team-viewer/TeamViewer';
import Commission from './features/commission/Commission';
import LeaversPage from './features/leavers/LeaversPage';
import MobileMenu from './components/MobileMenu';
import NotificationBell from './components/NotificationBell';
import './App.css';

function Navigation() {
  const { canAccessNavItem } = useRole();
  
  return (
    <nav className="main-nav desktop-only">
      {canAccessNavItem('organization') && (
        <NavLink to="/" className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`} end>
          Organization
        </NavLink>
      )}
      {canAccessNavItem('team-viewer') && (
        <NavLink to="/team-viewer" className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}>
          Team Viewer
        </NavLink>
      )}
      {canAccessNavItem('leavers') && (
        <NavLink to="/leavers" className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}>
          Leavers
        </NavLink>
      )}
      {canAccessNavItem('scenarios') && (
        <NavLink to="/scenarios" className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}>
          Scenarios
        </NavLink>
      )}
      {canAccessNavItem('analysis') && (
        <NavLink to="/analysis" className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}>
          Analysis
        </NavLink>
      )}
      {canAccessNavItem('approvals') && (
        <NavLink to="/approvals" className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}>
          Approvals
        </NavLink>
      )}
      {canAccessNavItem('talent') && (
        <NavLink to="/talent" className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}>
          Talent
        </NavLink>
      )}
      {canAccessNavItem('compensation') && (
        <NavLink to="/compensation" className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}>
          Compensation
        </NavLink>
      )}
      {canAccessNavItem('commission') && (
        <NavLink to="/commission" className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}>
          Commission
        </NavLink>
      )}
      {canAccessNavItem('admin') && (
        <NavLink to="/admin" className={({ isActive }) => `nav-button admin ${isActive ? 'active' : ''}`}>
          Admin Panel
        </NavLink>
      )}
    </nav>
  );
}

function MobileNavigation() {
  const { canAccessNavItem } = useRole();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className="mobile-menu-container">
      <button 
        className={`mobile-menu-button ${isOpen ? 'active' : ''}`}
        onClick={toggleMenu}
        aria-label="Toggle menu"
      >
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
      </button>

      <div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
        <nav className="mobile-nav">
          {canAccessNavItem('organization') && (
            <NavLink to="/" className={({ isActive }) => `mobile-nav-button ${isActive ? 'active' : ''}`} end onClick={closeMenu}>
              Organization
            </NavLink>
          )}
          {canAccessNavItem('team-viewer') && (
            <NavLink to="/team-viewer" className={({ isActive }) => `mobile-nav-button ${isActive ? 'active' : ''}`} onClick={closeMenu}>
              Team Viewer
            </NavLink>
          )}
          {canAccessNavItem('leavers') && (
            <NavLink to="/leavers" className={({ isActive }) => `mobile-nav-button ${isActive ? 'active' : ''}`} onClick={closeMenu}>
              Leavers
            </NavLink>
          )}
          {canAccessNavItem('scenarios') && (
            <NavLink to="/scenarios" className={({ isActive }) => `mobile-nav-button ${isActive ? 'active' : ''}`} onClick={closeMenu}>
              Scenarios
            </NavLink>
          )}
          {canAccessNavItem('analysis') && (
            <NavLink to="/analysis" className={({ isActive }) => `mobile-nav-button ${isActive ? 'active' : ''}`} onClick={closeMenu}>
              Analysis
            </NavLink>
          )}
          {canAccessNavItem('approvals') && (
            <NavLink to="/approvals" className={({ isActive }) => `mobile-nav-button ${isActive ? 'active' : ''}`} onClick={closeMenu}>
              Approvals
            </NavLink>
          )}
          {canAccessNavItem('talent') && (
            <NavLink to="/talent" className={({ isActive }) => `mobile-nav-button ${isActive ? 'active' : ''}`} onClick={closeMenu}>
              Talent
            </NavLink>
          )}
          {canAccessNavItem('compensation') && (
            <NavLink to="/compensation" className={({ isActive }) => `mobile-nav-button ${isActive ? 'active' : ''}`} onClick={closeMenu}>
              Compensation
            </NavLink>
          )}
          {canAccessNavItem('commission') && (
            <NavLink to="/commission" className={({ isActive }) => `mobile-nav-button ${isActive ? 'active' : ''}`} onClick={closeMenu}>
              Commission
            </NavLink>
          )}
          {canAccessNavItem('admin') && (
            <NavLink to="/admin" className={({ isActive }) => `mobile-nav-button admin ${isActive ? 'active' : ''}`} onClick={closeMenu}>
              Admin Panel
            </NavLink>
          )}
        </nav>
      </div>
    </div>
  );
}

function AppContent() {
  const { 
    user, 
    isAdmin, 
    logout, 
    scenarios: firebaseScenarios, 
    employees: firebaseEmployees,
    updateScenario,
    updateEmployee,
    createEmployee,
    deleteEmployee
  } = useFirebase();
  const [showLanding, setShowLanding] = useState(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    return !hasVisited;
  });
  const [exchangeRates, setExchangeRates] = useState(() => {
    const savedRates = localStorage.getItem('exchangeRates');
    return savedRates ? JSON.parse(savedRates) : {
      USD: 1,
      EUR: 1.1,
      GBP: 1.27,
      CHF: 1.12,
      SEK: 0.096,
      PHP: 0.018,
      INR: 0.012
    };
  });
  const [presentationCurrency, setPresentationCurrency] = useState(() => {
    const saved = localStorage.getItem('presentationCurrency');
    return saved || 'USD';
  });

  // Handle approval updates
  const handleApprovalUpdate = useCallback(async (scenarioId, updatedData) => {
    try {
      if (!user?.orgId) {
        throw new Error('Organization ID is required for updates');
      }

      console.log('Updating scenario with ID:', scenarioId);
      console.log('Update data:', updatedData);

      // Update the scenario in Firebase under the correct organization
      await updateScenario(scenarioId, updatedData);

      return true;
    } catch (error) {
      console.error('Error updating scenario:', error);
      return false;
    }
  }, [updateScenario, user?.orgId]);

  // Add effect to listen for exchange rates changes
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'exchangeRates' && e.newValue) {
        setExchangeRates(JSON.parse(e.newValue));
      }
      if (e.key === 'presentationCurrency' && e.newValue) {
        setPresentationCurrency(e.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const handleGetStarted = () => {
    localStorage.setItem('hasVisited', 'true');
    setShowLanding(false);
  };

  if (showLanding) {
    return <LandingPage onGetStarted={handleGetStarted} />;
  }

  if (!user) {
    return <LandingPage onGetStarted={handleGetStarted} />;
  }

  return (
    <div className="app">
      <header className="app-header">
        <div className="header-content">
          <div className="header-left">
            <div className="header-brand">
              <button onClick={() => setShowLanding(true)} className="logo-button">
                <img src="/images/logo.png" alt="multipl.org" className="logo-image" />
              </button>
            </div>
            <MobileNavigation />
          </div>
          <Navigation />
          <div className="header-right">
            <NotificationBell />
            <button onClick={logout} className="auth-button">Log Out</button>
          </div>
        </div>
      </header>

      <main className="main-content">
        <Routes>
          <Route 
            path="/" 
            element={
              <ProtectedRoute>
                <CurrentOrganization 
                  employees={firebaseEmployees} 
                  setEmployees={async (newEmployees) => {
                    // Handle bulk employee updates by comparing with existing employees
                    const existingIds = new Set(firebaseEmployees.map(e => e.id));
                    const newIds = new Set(newEmployees.map(e => e.id));
                    
                    // Handle deletions
                    for (const emp of firebaseEmployees) {
                      if (!newIds.has(emp.id)) {
                        await deleteEmployee(emp.id);
                      }
                    }
                    
                    // Handle updates and additions
                    for (const emp of newEmployees) {
                      if (existingIds.has(emp.id)) {
                        await updateEmployee(emp.id, emp);
                      } else {
                        await createEmployee(emp);
                      }
                    }
                  }}
                  scenarios={firebaseScenarios}
                  exchangeRates={exchangeRates}
                  setExchangeRates={setExchangeRates}
                  presentationCurrency={presentationCurrency}
                  setPresentationCurrency={setPresentationCurrency}
                />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/analysis" 
            element={
              <ProtectedRoute>
                <Analysis employees={firebaseEmployees} scenarios={firebaseScenarios} />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/scenarios" 
            element={
              <ProtectedRoute>
                <ScenarioPlanning 
                  scenarios={firebaseScenarios}
                  employees={firebaseEmployees}
                  onScenarioChange={async (updatedScenarios) => {
                    // Update each changed scenario
                    for (const scenario of updatedScenarios) {
                      await updateScenario(scenario.id, scenario);
                    }
                  }}
                />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/approvals" 
            element={
              <ProtectedRoute>
                <ApprovalQueue 
                  scenarios={firebaseScenarios}
                  employees={firebaseEmployees}
                  onUpdateApproval={handleApprovalUpdate}
                />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/talent" 
            element={
              <ProtectedRoute>
                <TalentAcquisition scenarios={firebaseScenarios} employees={firebaseEmployees} />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/compensation" 
            element={
              <ProtectedRoute>
                <CompensationFramework 
                  employees={firebaseEmployees} 
                  scenarios={firebaseScenarios}
                />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/commission" 
            element={
              <ProtectedRoute>
                <Commission 
                  employees={firebaseEmployees}
                />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/team-viewer" 
            element={
              <ProtectedRoute>
                <TeamViewer 
                  employees={firebaseEmployees} 
                  scenarios={firebaseScenarios}
                  setEmployees={async (newEmployees) => {
                    // Handle bulk employee updates by comparing with existing employees
                    const existingIds = new Set(firebaseEmployees.map(e => e.id));
                    const newIds = new Set(newEmployees.map(e => e.id));
                    
                    // Handle deletions
                    for (const emp of firebaseEmployees) {
                      if (!newIds.has(emp.id)) {
                        await deleteEmployee(emp.id);
                      }
                    }
                    
                    // Handle updates and additions
                    for (const emp of newEmployees) {
                      if (existingIds.has(emp.id)) {
                        await updateEmployee(emp.id, emp);
                      } else {
                        await createEmployee(emp);
                      }
                    }
                  }}
                />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/leavers" 
            element={
              <ProtectedRoute>
                <LeaversPage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/admin" 
            element={
              <ProtectedRoute>
                <AdminPanel />
              </ProtectedRoute>
            } 
          />
        </Routes>
      </main>
    </div>
  );
}

function App() {
  return (
    <Router>
      <FirebaseProvider>
        <RoleProvider>
          <AppContent />
        </RoleProvider>
      </FirebaseProvider>
    </Router>
  );
}

export default App;