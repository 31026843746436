import { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import './Analysis.css';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useFirebase } from '../../contexts/FirebaseContext';

const COLORS = ['#5e6ad2', '#2dd4bf', '#f43f5e', '#6366f1', '#8b5cf6', '#ec4899'];

function Analysis({ employees = [], scenarios = [] }) {
  const { user } = useFirebase();
  const [approvalStatuses, setApprovalStatuses] = useState({});
  const [backfillStatuses, setBackfillStatuses] = useState({});
  const [backfills, setBackfills] = useState({});
  const [recruitmentStatus, setRecruitmentStatus] = useState({});
  const [roleDetails, setRoleDetails] = useState({});
  const [selectedOrg, setSelectedOrg] = useState('All Organizations');
  const [exchangeRates, setExchangeRates] = useState(() => {
    const saved = localStorage.getItem('exchangeRates');
    return saved ? JSON.parse(saved) : {
      USD: 1,
      EUR: 0.85,
      GBP: 0.73,
      JPY: 110.0
    };
  });
  const [presentationCurrency, setPresentationCurrency] = useState(() => {
    const saved = localStorage.getItem('presentationCurrency');
    return saved || 'USD';
  });

  // Define today at component level since it's used in multiple functions
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Subscribe to backfills from Firestore
  useEffect(() => {
    if (!user?.orgId) return;

    const backfillsRef = collection(db, `organizations/${user.orgId}/backfills`);
    const unsubscribe = onSnapshot(backfillsRef, (snapshot) => {
      const backfillData = {};
      snapshot.forEach((doc) => {
        const data = doc.data();
        backfillData[doc.id] = {
          ...data,
          id: doc.id
        };
      });
      setBackfills(backfillData);
    });

    return () => unsubscribe();
  }, [user?.orgId]);

  // Get approved backfills
  const getApprovedBackfills = () => {
    return Object.values(backfills)
      .filter(backfill => {
        const isApproved = backfill.approvalStatus === 'Approved' || backfill.approvalStatus === 'approved';
        return isApproved && backfill.isVisible !== false && backfill.isActive !== false;
      })
      .map(backfill => {
        // Convert totalCost to presentation currency
        const totalCostPresentation = backfill.totalCost ? 
          convertCurrency(backfill.totalCost, backfill.currency || 'USD') : 
          (backfill.salary ? convertCurrency(backfill.salary, backfill.currency || 'USD') : 0);

        // Get the correct start date - this should be the leaver's end date
        const startDate = backfill.leaverDate || backfill.temporaryLeaveEnd || backfill.endDate || backfill.startDate;

        return {
          ...backfill,
          id: backfill.id || '',
          status: 'Approved',
          totalCost: backfill.totalCost || backfill.salary || 0,
          totalCostPresentation: totalCostPresentation,
          startDate: startDate,
          title: backfill.title || backfill.roleTitle || '',
          organization: backfill.organization || backfill.org || ''
        };
      });
  };

  const convertCurrency = (amount, fromCurrency) => {
    if (!amount || !fromCurrency) return 0;
    if (fromCurrency === presentationCurrency) return amount;
    
    // Convert to USD first if not already in USD
    let inUSD = fromCurrency === 'USD' 
      ? amount 
      : amount * exchangeRates[fromCurrency];
    
    // Convert from USD to presentation currency
    return presentationCurrency === 'USD' 
      ? inUSD 
      : inUSD * exchangeRates[presentationCurrency];
  };

  const getUniqueOrgs = () => {
    const orgs = [...new Set(employees.map(emp => emp.org))].filter(Boolean);
    return ['All Organizations', ...orgs];
  };

  const getFilteredEmployees = () => {
    if (selectedOrg === 'All Organizations') return employees;
    return employees.filter(emp => emp.org === selectedOrg);
  };

  // Helper function to calculate total cost for an employee
  const calculateEmployeeCost = (emp) => {
    if (!emp.totalCostPresentation) {
      console.log('Employee missing totalCostPresentation:', emp.id);
      return 0;
    }
    return emp.totalCostPresentation;
  };

  // Get approved hires from scenarios
  const getApprovedHires = () => {
    console.log('Scenarios in getApprovedHires:', scenarios);
    const hires = scenarios.flatMap(scenario => {
      console.log('Processing scenario:', scenario);
      if (!scenario.plannedHires) {
        console.log('No plannedHires in scenario:', scenario);
        return [];
      }
      return scenario.plannedHires
        .filter(hire => {
          const isApproved = hire.approvalStatus === 'Approved';
          console.log('Hire:', hire, 'isApproved:', isApproved);
          return isApproved;
        })
        .map(hire => {
          console.log('Processing approved hire:', hire);
          const details = roleDetails[hire.id] || {};
          const isCompleted = recruitmentStatus[hire.id]?.status === 'Completed';
          
          const startDate = isCompleted && details.confirmedStartDate 
            ? details.confirmedStartDate 
            : hire.startDate;

          // Use totalCostPresentation if available, otherwise convert totalCost
          const totalCostPresentation = hire.totalCostPresentation || 
            (hire.totalCost ? convertCurrency(hire.totalCost, hire.currency || 'USD') : 0);

          console.log('Processed hire cost:', {
            id: hire.id,
            originalTotalCost: hire.totalCost,
            originalCurrency: hire.currency,
            convertedCost: totalCostPresentation,
            startDate: startDate
          });

          return {
            ...hire,
            status: 'Approved',
            totalCostPresentation: totalCostPresentation,
            startDate: startDate
          };
        });
    });
    console.log('Final processed hires:', hires);
    return hires;
  };

  // Cost distribution calculations
  const costsByOrg = () => {
    console.log('Total filtered employees:', getFilteredEmployees().length);
    const costs = {};
    getFilteredEmployees().forEach(emp => {
      if (!emp.totalCostPresentation) {
        console.log('Employee missing totalCostPresentation:', emp.id);
        return;
      }
      const org = emp.organization || emp.org || 'Unassigned';
      const cost = calculateEmployeeCost(emp);
      console.log('Adding cost for org:', org, 'Cost:', cost);
      costs[org] = (costs[org] || 0) + cost;
    });
    
    const result = Object.entries(costs)
      .sort((a, b) => b[1] - a[1]) // Sort by value in descending order
      .map(([name, value]) => ({ name, value }));
    
    console.log('Final org costs:', result);
    return result;
  };

  const costsByLocation = () => {
    console.log('Total filtered employees:', getFilteredEmployees().length);
    const costs = {};
    getFilteredEmployees().forEach(emp => {
      if (!emp.totalCostPresentation) {
        console.log('Employee missing totalCostPresentation:', emp.id);
        return;
      }
      const location = emp.location || 'Unassigned';
      const cost = calculateEmployeeCost(emp);
      console.log('Adding cost for location:', location, 'Cost:', cost);
      costs[location] = (costs[location] || 0) + cost;
    });
    
    // Sort by cost and take top 8 locations, group rest into "Others"
    const sortedCosts = Object.entries(costs)
      .sort((a, b) => b[1] - a[1]);
    
    const mainLocations = sortedCosts.slice(0, 8);
    const otherLocations = sortedCosts.slice(8);
    
    const result = mainLocations.map(([name, value]) => ({ name, value }));
    
    if (otherLocations.length > 0) {
      const othersValue = otherLocations.reduce((sum, [_, value]) => sum + value, 0);
      result.push({ name: 'Others', value: othersValue });
    }
    
    console.log('Final location costs:', result);
    return result;
  };

  const costsByLevel = () => {
    console.log('Total filtered employees:', getFilteredEmployees().length);
    const costs = {};
    getFilteredEmployees().forEach(emp => {
      if (!emp.totalCostPresentation) {
        console.log('Employee missing totalCostPresentation:', emp.id);
        return;
      }
      
      // Use exact job level without standardization
      const level = emp.jobLevel || emp.level;
      if (!level) {
        console.log('Employee missing level:', emp.id);
        return;
      }
      
      const cost = emp.totalCostPresentation;
      console.log('Adding cost for level:', level, 'Cost:', cost);
      costs[level] = (costs[level] || 0) + cost;
    });
    
    // Sort alphabetically to maintain consistent order
    const result = Object.entries(costs)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([name, value]) => ({ name, value }));
    
    console.log('Final level costs:', result);
    return result;
  };

  // Future projections calculations
  const calculateProjections = (type = 'cost') => {
    console.log('Starting projections calculation for type:', type);
    const months = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Generate next 24 months
    for (let i = 0; i < 24; i++) {
      const date = new Date(today.getFullYear(), today.getMonth() + i, 1);
      months.push({
        name: date.toLocaleString('default', { month: 'short', year: '2-digit' }),
        date: date,
        currentTeam: 0,
        newJoiners: 0,
        backfills: 0,
        approvedHires: 0,
        leavers: 0
      });
    }

    // Process current employees
    getFilteredEmployees().forEach(emp => {
      if (!emp.totalCostPresentation) {
        console.log('Employee missing totalCostPresentation:', emp.id);
        return;
      }
      const monthlyCost = type === 'cost' ? emp.totalCostPresentation / 12 : 1;
      
      months.forEach(month => {
        const startDate = emp.startDate ? new Date(emp.startDate) : null;
        const endDate = emp.endDate ? new Date(emp.endDate) : null;
        
        if (startDate) startDate.setDate(1);
        if (endDate) endDate.setDate(1);

        // Skip if they've already left by this month
        if (endDate && endDate < month.date) return;

        // Handle future joiners
        if (startDate && startDate > today) {
          if (startDate.getMonth() === month.date.getMonth() && 
              startDate.getFullYear() === month.date.getFullYear()) {
            month.newJoiners += monthlyCost;
          } else if (startDate < month.date) {
            month.currentTeam += monthlyCost;
          }
        } else {
          // Current team member
          if (!startDate || startDate <= month.date) {
            month.currentTeam += monthlyCost;
          }
        }

        // Handle leavers in their last month
        if (endDate && endDate.getMonth() === month.date.getMonth() && 
            endDate.getFullYear() === month.date.getFullYear()) {
          month.leavers -= monthlyCost;
          month.currentTeam -= monthlyCost;
        }
      });
    });

    // Process approved new hires
    const approvedHires = getApprovedHires();
    console.log('Processing approved hires for projections:', approvedHires);
    
    approvedHires.forEach(hire => {
      if (!hire.totalCostPresentation) {
        console.log('Hire missing totalCostPresentation:', hire);
        return;
      }
      
      const monthlyCost = type === 'cost' ? hire.totalCostPresentation / 12 : 1;
      console.log('Monthly cost for hire:', {
        id: hire.id,
        totalCost: hire.totalCostPresentation,
        monthlyCost: monthlyCost,
        startDate: hire.startDate
      });

      if (!hire.startDate) {
        console.log('Hire missing start date:', hire);
        return;
      }

      const startDate = new Date(hire.startDate);
      startDate.setHours(0, 0, 0, 0);
      startDate.setDate(1);
      
      months.forEach(month => {
        const monthDate = new Date(month.date);
        monthDate.setHours(0, 0, 0, 0);
        
        if (startDate <= monthDate) {
          month.approvedHires += monthlyCost;
          console.log('Added hire cost to month:', {
            month: month.name,
            hireId: hire.id,
            addedCost: monthlyCost,
            newTotal: month.approvedHires
          });
        }
      });
    });

    // Process approved backfills
    getApprovedBackfills().forEach(backfill => {
      if (!backfill.totalCostPresentation) {
        console.log('Backfill missing totalCostPresentation:', backfill.id);
        return;
      }
      const monthlyCost = type === 'cost' ? backfill.totalCostPresentation / 12 : 1;
      const startDate = new Date(backfill.startDate);
      startDate.setDate(1);
      
      months.forEach(month => {
        if (startDate <= month.date) {
          month.backfills += monthlyCost;
        }
      });
    });

    return months;
  };

  const formatCurrency = (value) => {
    return `${presentationCurrency} ${Math.abs(value).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })}`;
  };

  return (
    <div className="analysis">
      <div className="analysis-header">
        <div className="filter-controls">
          <select
            value={selectedOrg}
            onChange={(e) => setSelectedOrg(e.target.value)}
            className="org-filter"
          >
            {getUniqueOrgs().map(org => (
              <option key={org} value={org}>
                {org}
              </option>
            ))}
          </select>
        </div>
      </div>
      
      <div className="distribution-charts">
        <div className="distribution-charts-grid">
          <div>
            <h3>Cost by Organization</h3>
            <ResponsiveContainer width="100%" height={280}>
              <PieChart>
                <Pie
                  data={costsByOrg()}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {costsByOrg().map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => `${presentationCurrency} ${value.toLocaleString()}`} />
              </PieChart>
            </ResponsiveContainer>
          </div>

          <div>
            <h3>Cost by Location</h3>
            <ResponsiveContainer width="100%" height={280}>
              <PieChart>
                <Pie
                  data={costsByLocation()}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {costsByLocation().map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => `${presentationCurrency} ${value.toLocaleString()}`} />
              </PieChart>
            </ResponsiveContainer>
          </div>

          <div>
            <h3>Cost by Level</h3>
            <ResponsiveContainer width="100%" height={280}>
              <PieChart>
                <Pie
                  data={costsByLevel()}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {costsByLevel().map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => `${presentationCurrency} ${value.toLocaleString()}`} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className="chart-wrapper">
        <h3>Future Cost Projection</h3>
        <div className="chart-container">
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={calculateProjections('cost')}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              stackOffset="sign"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={(value) => `${presentationCurrency} ${Math.abs(value / 1000)}k`} />
              <Tooltip 
                formatter={(value, name) => [
                  formatCurrency(value),
                  name.charAt(0).toUpperCase() + name.slice(1)
                ]}
              />
              <Legend />
              <Bar dataKey="currentTeam" stackId="stack" fill="#4ade80" name="Current Team" />
              <Bar dataKey="newJoiners" stackId="stack" fill="#2dd4bf" name="New Joiners" />
              <Bar dataKey="backfills" stackId="stack" fill="#6366f1" name="Backfills" />
              <Bar dataKey="approvedHires" stackId="stack" fill="#8b5cf6" name="Approved Hires" />
              <Bar dataKey="leavers" stackId="stack" fill="#f43f5e" name="Leavers" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="chart-wrapper">
        <h3>Future Headcount Projection</h3>
        <div className="chart-container">
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={calculateProjections('headcount')}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              stackOffset="sign"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip 
                formatter={(value, name) => [
                  `${Math.abs(value)} people`,
                  name.charAt(0).toUpperCase() + name.slice(1)
                ]}
              />
              <Legend />
              <Bar dataKey="currentTeam" stackId="stack" fill="#4ade80" name="Current Team" />
              <Bar dataKey="newJoiners" stackId="stack" fill="#2dd4bf" name="New Joiners" />
              <Bar dataKey="backfills" stackId="stack" fill="#6366f1" name="Backfills" />
              <Bar dataKey="approvedHires" stackId="stack" fill="#8b5cf6" name="Approved Hires" />
              <Bar dataKey="leavers" stackId="stack" fill="#f43f5e" name="Leavers" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default Analysis; 