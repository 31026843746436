import React, { useState, useEffect, useRef } from 'react';
import { useFirebase } from '../../contexts/FirebaseContext';
import { getCompensationRanges, getLocationTiers } from '../../services/firestore';
import { countries } from '../../utils/countries';
import './OfferDetailsModal.css';

const OfferDetailsModal = ({ isOpen, onClose, role, onSave }) => {
  const { user, exchangeRates } = useFirebase();
  const levelComboboxRef = useRef(null);
  const countryComboboxRef = useRef(null);
  const currencyComboboxRef = useRef(null);
  const [level, setLevel] = useState('');
  const [country, setCountry] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [offerAmount, setOfferAmount] = useState('');
  const [compensationRange, setCompensationRange] = useState(null);
  const [locationMultiplier, setLocationMultiplier] = useState(1);
  const [loading, setLoading] = useState(true);
  const [frameworkCurrency, setFrameworkCurrency] = useState('USD');
  const [error, setError] = useState('');
  const [openCombobox, setOpenCombobox] = useState(null);
  
  // Search states
  const [levelSearch, setLevelSearch] = useState('');
  const [countrySearch, setCountrySearch] = useState('');
  const [currencySearch, setCurrencySearch] = useState('');

  // Display states
  const [levelDisplay, setLevelDisplay] = useState('');
  const [countryDisplay, setCountryDisplay] = useState('');
  const [currencyDisplay, setCurrencyDisplay] = useState('USD');

  const levels = [
    { id: 'ic1', display: 'IC1' },
    { id: 'ic2', display: 'IC2' },
    { id: 'ic3', display: 'IC3 / M3' },
    { id: 'ic4', display: 'IC4 / M4' },
    { id: 'ic5', display: 'IC5 / M5' },
    { id: 'ic6', display: 'IC6 / M6' },
    { id: 'l1', display: 'L1' },
    { id: 'l2', display: 'L2' },
    { id: 'l3', display: 'L3' },
    { id: 's1', display: 'S1' },
    { id: 's2', display: 'S2' },
    { id: 's3', display: 'S3' },
    { id: 's4', display: 'S4' },
    { id: 's5', display: 'S5' }
  ];

  const currencies = ['USD', 'EUR', 'GBP', 'CHF', 'SEK', 'PHP', 'INR'];

  // Filter functions
  const filteredLevels = levels.filter(level => 
    level.display.toLowerCase().includes(levelSearch.toLowerCase())
  );

  const filteredCountries = countries.filter(c => 
    c.toLowerCase().includes(countrySearch.toLowerCase())
  );

  const filteredCurrencies = currencies.filter(c => 
    c.toLowerCase().includes(currencySearch.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (levelComboboxRef.current && !levelComboboxRef.current.contains(event.target)) &&
        (countryComboboxRef.current && !countryComboboxRef.current.contains(event.target)) &&
        (currencyComboboxRef.current && !currencyComboboxRef.current.contains(event.target))
      ) {
        setOpenCombobox(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const loadCompensationData = async () => {
      if (!user?.orgId || !level || !country) return;

      try {
        setLoading(true);
        setError('');
        // Load compensation ranges
        const ranges = await getCompensationRanges(user.orgId);
        const locationTiers = await getLocationTiers(user.orgId);

        // Get the framework currency
        const fwCurrency = ranges.currency || 'USD';
        setFrameworkCurrency(fwCurrency);

        // Get the location tier for the selected country
        const locationTier = locationTiers?.assignments?.[country] || 'Tier 2';
        const multiplier = locationTiers?.tiers?.[locationTier] || 1;
        setLocationMultiplier(multiplier);

        // Get compensation range for the selected level and function
        const range = ranges[`${level}-${role.function || 'Software Engineering'}`] || null;
        
        if (range) {
          // First apply location multiplier to the base amounts in framework currency
          const adjustedRange = {
            min: range.min * multiplier,
            mid: range.mid * multiplier,
            max: range.max * multiplier
          };

          // Then convert to the selected currency
          const convertedRange = {
            min: convertCurrency(adjustedRange.min, fwCurrency, currency),
            mid: convertCurrency(adjustedRange.mid, fwCurrency, currency),
            max: convertCurrency(adjustedRange.max, fwCurrency, currency)
          };
          
          setCompensationRange(convertedRange);
        }
      } catch (error) {
        console.error('Error loading compensation data:', error);
        setError('Failed to load compensation data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    loadCompensationData();
  }, [user?.orgId, level, country, currency, role.function]);

  const convertCurrency = (amount, fromCurrency, toCurrency) => {
    if (!amount || !fromCurrency || !toCurrency) return 0;
    if (fromCurrency === toCurrency) return amount;
    
    // Get rates from Firebase
    const rates = exchangeRates?.rates || {};
    
    // Try to get the rates in both formats (direct and legacy)
    const sourceRate = rates[fromCurrency] || rates[`${fromCurrency}:USD`] || rates[`USD:${fromCurrency}`] || 1;
    const targetRate = rates[toCurrency] || rates[`${toCurrency}:USD`] || rates[`USD:${toCurrency}`] || 1;
    
    // If we're using USD:X format instead of X:USD, we need to invert the rate
    const finalSourceRate = rates[`USD:${fromCurrency}`] ? 1 / sourceRate : sourceRate;
    const finalTargetRate = rates[`USD:${toCurrency}`] ? 1 / targetRate : targetRate;
    
    // Convert through USD: source -> USD -> target
    const inUSD = amount * finalSourceRate;
    const inTarget = inUSD / finalTargetRate;
    
    return Math.round(inTarget);
  };

  const handleSave = () => {
    // Validate required fields
    if (!level) {
      setError('Please select a level');
      return;
    }
    if (!country) {
      setError('Please select a country');
      return;
    }
    if (!offerAmount || offerAmount <= 0) {
      setError('Please enter a valid offer amount');
      return;
    }
    if (!compensationRange) {
      setError('Please wait for compensation range to load');
      return;
    }

    // Validate offer amount is within range
    const amount = parseFloat(offerAmount);
    if (amount < compensationRange.min || amount > compensationRange.max) {
      if (!window.confirm('The offer amount is outside the compensation range. Do you want to proceed anyway?')) {
        return;
      }
    }

    // Clear any previous errors
    setError('');

    // Call the parent's onSave with all the details
    onSave({
      level,
      country,
      currency,
      offerAmount: amount,
      compensationRange,
      frameworkCurrency,
      locationMultiplier
    });
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content framework-section">
        <h2 className="compensation-header-title">Offer Details</h2>
        <div className="modal-form">
          <div className="form-group">
            <label>Function:</label>
            <input 
              type="text" 
              value={role.function || ''} 
              readOnly 
              className="compensation-input"
              disabled
            />
          </div>

          <div className="form-group">
            <label>Level:</label>
            <div className="combobox-container" ref={levelComboboxRef}>
              <input
                type="text"
                className="combobox-input"
                value={levelSearch || levelDisplay}
                onChange={(e) => {
                  setLevelSearch(e.target.value);
                  setLevelDisplay(e.target.value);
                }}
                onFocus={() => {
                  setOpenCombobox('level');
                  setLevelSearch(levelDisplay);
                }}
                placeholder="Search level..."
                required
              />
              {openCombobox === 'level' && (
                <div className="combobox-options">
                  {filteredLevels.map(lvl => (
                    <div
                      key={lvl.id}
                      className="combobox-option"
                      onClick={() => {
                        setLevel(lvl.id);
                        setLevelDisplay(lvl.display);
                        setLevelSearch('');
                        setOpenCombobox(null);
                      }}
                    >
                      {lvl.display}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="form-group">
            <label>Country:</label>
            <div className="combobox-container" ref={countryComboboxRef}>
              <input
                type="text"
                className="combobox-input"
                value={countrySearch || countryDisplay}
                onChange={(e) => {
                  setCountrySearch(e.target.value);
                  setCountryDisplay(e.target.value);
                }}
                onFocus={() => {
                  setOpenCombobox('country');
                  setCountrySearch(countryDisplay);
                }}
                placeholder="Search country..."
                required
              />
              {openCombobox === 'country' && (
                <div className="combobox-options">
                  {filteredCountries.map(c => (
                    <div
                      key={c}
                      className="combobox-option"
                      onClick={() => {
                        setCountry(c);
                        setCountryDisplay(c);
                        setCountrySearch('');
                        setOpenCombobox(null);
                      }}
                    >
                      {c}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="form-group">
            <label>Currency:</label>
            <div className="combobox-container" ref={currencyComboboxRef}>
              <input
                type="text"
                className="combobox-input"
                value={currencySearch || currencyDisplay}
                onChange={(e) => {
                  setCurrencySearch(e.target.value);
                  setCurrencyDisplay(e.target.value);
                }}
                onFocus={() => {
                  setOpenCombobox('currency');
                  setCurrencySearch(currencyDisplay);
                }}
                placeholder="Search currency..."
                required
              />
              {openCombobox === 'currency' && (
                <div className="combobox-options">
                  {filteredCurrencies.map(c => (
                    <div
                      key={c}
                      className="combobox-option"
                      onClick={() => {
                        setCurrency(c);
                        setCurrencyDisplay(c);
                        setCurrencySearch('');
                        setOpenCombobox(null);
                      }}
                    >
                      {c}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {compensationRange && !loading && (
            <div className="compensation-range">
              <h3>Compensation Range</h3>
              <div className="range-details">
                <div>
                  <label>Minimum:</label>
                  <span>{currency} {compensationRange.min.toLocaleString()}</span>
                </div>
                <div>
                  <label>Mid-point:</label>
                  <span>{currency} {compensationRange.mid.toLocaleString()}</span>
                </div>
                <div>
                  <label>Maximum:</label>
                  <span>{currency} {compensationRange.max.toLocaleString()}</span>
                </div>
              </div>
            </div>
          )}

          <div className="form-group">
            <label>Offer Amount ({currency}):</label>
            <input
              type="number"
              value={offerAmount}
              onChange={(e) => setOfferAmount(e.target.value)}
              placeholder="Enter offer amount"
              className="compensation-input"
            />
          </div>

          {error && (
            <div className="error-message">
              {error}
            </div>
          )}

          <div className="modal-actions">
            <button onClick={onClose} className="compensation-action-button">Cancel</button>
            <button 
              onClick={handleSave} 
              className="compensation-action-button primary"
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Save'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferDetailsModal; 