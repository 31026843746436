import React, { useState, useEffect } from 'react';
import './MentionsDropdown.css';

export default function MentionsDropdown({ users, searchTerm, onSelect }) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  
  const filteredUsers = users.filter(user => 
    user.displayName?.toLowerCase().includes((searchTerm || '').toLowerCase()) ||
    user.email?.toLowerCase().includes((searchTerm || '').toLowerCase())
  );

  useEffect(() => {
    setSelectedIndex(0);
  }, [searchTerm]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!filteredUsers.length) return;

      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setSelectedIndex(prev => (prev + 1) % filteredUsers.length);
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedIndex(prev => (prev - 1 + filteredUsers.length) % filteredUsers.length);
          break;
        case 'Enter':
          e.preventDefault();
          if (filteredUsers[selectedIndex]) {
            onSelect(filteredUsers[selectedIndex]);
          }
          break;
        case 'Escape':
          e.preventDefault();
          onSelect(null);
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [filteredUsers, selectedIndex, onSelect]);

  console.log('MentionsDropdown rendering with:', {
    searchTerm,
    filteredUsers,
    selectedIndex
  });

  return (
    <div className="mentions-dropdown">
      {filteredUsers.length > 0 ? (
        filteredUsers.map((user, index) => (
          <div
            key={user.uid}
            className={`mention-item ${index === selectedIndex ? 'selected' : ''}`}
            onClick={() => onSelect(user)}
            onMouseEnter={() => setSelectedIndex(index)}
          >
            <div className="mention-user-info">
              <span className="mention-name">{user.displayName || 'Anonymous'}</span>
              <span className="mention-email">{user.email}</span>
            </div>
          </div>
        ))
      ) : (
        <div className="mention-no-results">
          No users found matching "{searchTerm}"
        </div>
      )}
    </div>
  );
} 