import { db } from '../config/firebase';
import { 
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  writeBatch,
  onSnapshot,
  serverTimestamp
} from 'firebase/firestore';

// Cache management
export const cache = new Map();
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

const setInCache = (key, value) => {
  cache.set(key, {
    value,
    timestamp: Date.now()
  });
};

const getFromCache = (key) => {
  const cached = cache.get(key);
  if (!cached) return null;
  if (Date.now() - cached.timestamp > CACHE_DURATION) {
    cache.delete(key);
    return null;
  }
  return cached.value;
};

// Batch operations manager
let currentBatch = null;
let batchOperations = 0;
const MAX_BATCH_OPERATIONS = 500;

const getBatch = () => {
  if (!currentBatch) {
    currentBatch = writeBatch(db);
    batchOperations = 0;
  }
  if (batchOperations >= MAX_BATCH_OPERATIONS) {
    commitBatchIfNeeded();
    currentBatch = writeBatch(db);
    batchOperations = 0;
  }
  batchOperations++;
  return currentBatch;
};

const commitBatchIfNeeded = async () => {
  if (currentBatch && batchOperations > 0) {
    const batchToCommit = currentBatch;
    currentBatch = null;
    batchOperations = 0;
    await batchToCommit.commit();
  }
};

// Organization Operations
export const createOrganization = async (orgData) => {
  const orgRef = doc(db, 'organizations', orgData.id);
  await setDoc(orgRef, orgData);
};

export const getOrganization = async (orgId) => {
  const orgRef = doc(db, 'organizations', orgId);
  const orgSnap = await getDoc(orgRef);
  return orgSnap.exists() ? orgSnap.data() : null;
};

export const updateOrganization = async (orgId, updateData) => {
  const orgRef = doc(db, 'organizations', orgId);
  await updateDoc(orgRef, {
    ...updateData,
    updatedAt: serverTimestamp()
  });
};

// Employee Operations
export const createEmployee = async (orgId, employeeData) => {
  if (!navigator.onLine) {
    // Offline mode - store in localStorage and sync later
    const employees = JSON.parse(localStorage.getItem('employees') || '[]');
    employees.push({ ...employeeData, pendingSync: true });
    localStorage.setItem('employees', JSON.stringify(employees));
    return employeeData;
  }

  try {
    const employeeRef = doc(db, `organizations/${orgId}/employees`, employeeData.id);
    await setDoc(employeeRef, employeeData);

    // Update cache
    const cacheKey = `employees_${orgId}`;
    const cachedEmployees = getFromCache(cacheKey) || [];
    setInCache(cacheKey, [...cachedEmployees, employeeData]);

    // Keep a copy in localStorage for offline access
    try {
      const employees = JSON.parse(localStorage.getItem('employees') || '[]');
      employees.push(employeeData);
      localStorage.setItem('employees', JSON.stringify(employees));
    } catch (error) {
      console.warn('localStorage sync failed:', error);
    }

    return employeeData;
  } catch (error) {
    console.error('Error creating employee:', error);
    
    if (!navigator.onLine || error.code === 'resource-exhausted' || error.code === 'permission-denied') {
      // Fallback to localStorage if offline or Firebase fails
      const employees = JSON.parse(localStorage.getItem('employees') || '[]');
      employees.push({ ...employeeData, pendingSync: true });
      localStorage.setItem('employees', JSON.stringify(employees));
      return employeeData;
    }
    throw error;
  }
};

export const getEmployees = async (orgId) => {
  // Check cache first for immediate response
  const cacheKey = `employees_${orgId}`;
  const cachedEmployees = getFromCache(cacheKey);
  if (cachedEmployees) {
    return cachedEmployees;
  }

  if (!navigator.onLine) {
    // Offline mode - return localStorage data
    return JSON.parse(localStorage.getItem('employees') || '[]');
  }

  try {
    // Get data from Firestore
    const employeesRef = collection(db, `organizations/${orgId}/employees`);
    const snapshot = await getDocs(employeesRef);
    const employees = snapshot.docs.map(doc => doc.data());
    
    // Update cache
    setInCache(cacheKey, employees);

    // Update localStorage for offline access
    try {
      localStorage.setItem('employees', JSON.stringify(employees));
    } catch (error) {
      console.warn('localStorage sync failed:', error);
    }

    return employees;
  } catch (error) {
    console.error('Error getting employees:', error);
    
    // If offline or error, fallback to localStorage
    return JSON.parse(localStorage.getItem('employees') || '[]');
  }
};

export const updateEmployee = async (orgId, employeeId, data) => {
  if (!navigator.onLine) {
    // Offline mode - update localStorage and mark for sync
    const employees = JSON.parse(localStorage.getItem('employees') || '[]');
    const updatedEmployees = employees.map(emp => 
      emp.id === employeeId ? { ...emp, ...data, pendingSync: true } : emp
    );
    localStorage.setItem('employees', JSON.stringify(updatedEmployees));
    return;
  }

  try {
    // Update Firestore
    const employeeRef = doc(db, `organizations/${orgId}/employees`, employeeId);
    await updateDoc(employeeRef, data);

    // Update cache
    const cacheKey = `employees_${orgId}`;
    const cachedEmployees = getFromCache(cacheKey);
    if (cachedEmployees) {
      const updatedEmployees = cachedEmployees.map(emp => 
        emp.id === employeeId ? { ...emp, ...data } : emp
      );
      setInCache(cacheKey, updatedEmployees);
    }

    // Update localStorage for offline access
    try {
      const employees = JSON.parse(localStorage.getItem('employees') || '[]');
      const updatedEmployees = employees.map(emp => 
        emp.id === employeeId ? { ...emp, ...data } : emp
      );
      localStorage.setItem('employees', JSON.stringify(updatedEmployees));
    } catch (error) {
      console.warn('localStorage sync failed:', error);
    }
  } catch (error) {
    console.error('Error updating employee:', error);
    
    if (!navigator.onLine || error.code === 'resource-exhausted' || error.code === 'permission-denied') {
      // Fallback to localStorage if offline or Firebase fails
      const employees = JSON.parse(localStorage.getItem('employees') || '[]');
      const updatedEmployees = employees.map(emp => 
        emp.id === employeeId ? { ...emp, ...data, pendingSync: true } : emp
      );
      localStorage.setItem('employees', JSON.stringify(updatedEmployees));
    } else {
      throw error;
    }
  }
};

export const deleteEmployee = async (orgId, employeeId) => {
  if (!navigator.onLine) {
    // Offline mode - mark for deletion in localStorage
    const employees = JSON.parse(localStorage.getItem('employees') || '[]');
    const updatedEmployees = employees.map(emp => 
      emp.id === employeeId ? { ...emp, pendingDelete: true } : emp
    ).filter(emp => emp.id !== employeeId);
    localStorage.setItem('employees', JSON.stringify(updatedEmployees));
    return;
  }

  try {
    // Delete from Firestore
    const employeeRef = doc(db, `organizations/${orgId}/employees`, employeeId);
    await deleteDoc(employeeRef);

    // Update cache
    const cacheKey = `employees_${orgId}`;
    const cachedEmployees = getFromCache(cacheKey);
    if (cachedEmployees) {
      setInCache(cacheKey, cachedEmployees.filter(emp => emp.id !== employeeId));
    }

    // Update localStorage for offline access
    try {
      const employees = JSON.parse(localStorage.getItem('employees') || '[]');
      localStorage.setItem('employees', JSON.stringify(employees.filter(emp => emp.id !== employeeId)));
    } catch (error) {
      console.warn('localStorage sync failed:', error);
    }
  } catch (error) {
    console.error('Error deleting employee:', error);
    
    if (!navigator.onLine || error.code === 'resource-exhausted' || error.code === 'permission-denied') {
      // Fallback to localStorage if offline or Firebase fails
      const employees = JSON.parse(localStorage.getItem('employees') || '[]');
      const updatedEmployees = employees.map(emp => 
        emp.id === employeeId ? { ...emp, pendingDelete: true } : emp
      ).filter(emp => emp.id !== employeeId);
      localStorage.setItem('employees', JSON.stringify(updatedEmployees));
    } else {
      throw error;
    }
  }
};

// Add a function to sync pending changes when back online
export const syncPendingChanges = async (orgId) => {
  if (!navigator.onLine) return;

  const employees = JSON.parse(localStorage.getItem('employees') || '[]');
  const pendingEmployees = employees.filter(emp => emp.pendingSync || emp.pendingDelete);

  for (const employee of pendingEmployees) {
    try {
      if (employee.pendingDelete) {
        await deleteEmployee(orgId, employee.id);
      } else if (employee.pendingSync) {
        const { pendingSync, ...employeeData } = employee;
        await updateEmployee(orgId, employee.id, employeeData);
      }
    } catch (error) {
      console.error('Error syncing employee:', error);
    }
  }
};

// Scenario Operations
export const createScenario = async (orgId, scenarioData) => {
  try {
    if (!orgId || typeof orgId !== 'string') {
      throw new Error('Invalid organization ID');
    }

    if (!scenarioData || typeof scenarioData !== 'object') {
      throw new Error('Invalid scenario data');
    }

    const scenarioId = scenarioData.id?.toString() || Date.now().toString();
    const cleanScenarioData = {
      id: scenarioId,
      name: scenarioData.name || '',
      organization: scenarioData.organization || '',
      description: scenarioData.description || '',
      plannedHires: Array.isArray(scenarioData.plannedHires) ? scenarioData.plannedHires : [],
      status: scenarioData.status || 'draft',
      createdAt: scenarioData.createdAt || new Date().toISOString(),
      updatedAt: scenarioData.updatedAt || new Date().toISOString()
    };

    // Save to localStorage first
    const savedScenarios = JSON.parse(localStorage.getItem('scenarios') || '[]');
    const existingIndex = savedScenarios.findIndex(s => s.id === scenarioId);
    if (existingIndex >= 0) {
      savedScenarios[existingIndex] = cleanScenarioData;
    } else {
      savedScenarios.push(cleanScenarioData);
    }
    localStorage.setItem('scenarios', JSON.stringify(savedScenarios));

    try {
      const batch = getBatch();
      const scenarioRef = doc(db, `organizations/${orgId}/scenarios`, scenarioId);
      batch.set(scenarioRef, cleanScenarioData);
      await commitBatchIfNeeded();

      // Update cache
      const cacheKey = `scenarios_${orgId}`;
      const cachedScenarios = getFromCache(cacheKey) || [];
      setInCache(cacheKey, [...cachedScenarios, cleanScenarioData]);
    } catch (error) {
      if (error.code === 'resource-exhausted') {
        console.log('Firebase quota exceeded, using localStorage version');
        return cleanScenarioData;
      }
      throw error;
    }

    return cleanScenarioData;
  } catch (error) {
    console.error('Error creating scenario:', error);
    throw error;
  }
};

export const getScenarios = async (orgId) => {
  try {
    // Check cache first
    const cacheKey = `scenarios_${orgId}`;
    const cachedScenarios = getFromCache(cacheKey);
    if (cachedScenarios) {
      return cachedScenarios;
    }

    // Fetch from Firestore
    const scenariosRef = collection(db, `organizations/${orgId}/scenarios`);
    const snapshot = await getDocs(scenariosRef);
    const scenarios = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    // Update cache
    setInCache(cacheKey, scenarios);
    return scenarios;
  } catch (error) {
    console.error('Error fetching scenarios:', error);
    // Fallback to localStorage
    return JSON.parse(localStorage.getItem('scenarios') || '[]');
  }
};

export const updateScenario = async (orgId, scenarioId, data) => {
  try {
    // Update localStorage first
    const savedScenarios = JSON.parse(localStorage.getItem('scenarios') || '[]');
    const scenarioIndex = savedScenarios.findIndex(s => s.id === scenarioId);
    const updateData = {
      ...data,
      updatedAt: new Date().toISOString()
    };

    if (scenarioIndex >= 0) {
      savedScenarios[scenarioIndex] = {
        ...savedScenarios[scenarioIndex],
        ...updateData
      };
    } else {
      savedScenarios.push({
        id: scenarioId,
        ...updateData
      });
    }
    localStorage.setItem('scenarios', JSON.stringify(savedScenarios));

    try {
      const batch = getBatch();
      const scenarioRef = doc(db, `organizations/${orgId}/scenarios`, scenarioId);
      batch.update(scenarioRef, updateData);
      await commitBatchIfNeeded();

      // Update cache
      const cacheKey = `scenarios_${orgId}`;
      const cachedScenarios = getFromCache(cacheKey);
      if (cachedScenarios) {
        const updatedScenarios = cachedScenarios.map(s => 
          s.id === scenarioId ? { ...s, ...updateData } : s
        );
        setInCache(cacheKey, updatedScenarios);
      }
    } catch (error) {
      if (error.code === 'resource-exhausted') {
        console.log('Firebase quota exceeded, using localStorage version');
        return savedScenarios[scenarioIndex];
      }
      throw error;
    }

    return savedScenarios[scenarioIndex];
  } catch (error) {
    console.error('Error updating scenario:', error);
    throw error;
  }
};

// Compensation Range Operations
export const updateCompensationRanges = async (orgId, ranges) => {
  if (!navigator.onLine) {
    localStorage.setItem('compensationRanges', JSON.stringify({
      ...ranges,
      pendingSync: true
    }));
    return;
  }

  try {
    const rangesRef = doc(db, `organizations/${orgId}/compensationRanges`, 'default');
    await setDoc(rangesRef, ranges, { merge: true });

    // Update localStorage for offline access
    try {
      localStorage.setItem('compensationRanges', JSON.stringify(ranges));
    } catch (error) {
      console.warn('localStorage sync failed:', error);
    }
  } catch (error) {
    console.error('Error updating compensation ranges:', error);
    
    if (!navigator.onLine || error.code === 'resource-exhausted' || error.code === 'permission-denied') {
      localStorage.setItem('compensationRanges', JSON.stringify({
        ...ranges,
        pendingSync: true
      }));
    } else {
      throw error;
    }
  }
};

export const getCompensationRanges = async (orgId) => {
  try {
    const rangesRef = doc(db, `organizations/${orgId}/compensationRanges`, 'default');
    const snapshot = await getDoc(rangesRef);
    return snapshot.exists() ? snapshot.data() : {};
  } catch (error) {
    console.error('Error getting compensation ranges:', error);
    // Fallback to localStorage
    try {
      return JSON.parse(localStorage.getItem('compensationRanges') || '{}');
    } catch (localError) {
      console.error('Error reading from localStorage:', localError);
      return {};
    }
  }
};

// Exchange Rates Operations
export const updateExchangeRates = async (orgId, rates) => {
  if (!navigator.onLine) {
    localStorage.setItem('exchangeRates', JSON.stringify(rates));
    return;
  }

  try {
    // Ensure we have a presentationCurrency field
    const ratesData = {
      ...rates,
      presentationCurrency: rates.presentationCurrency || 'USD'
    };

    const ratesRef = doc(db, `organizations/${orgId}/settings/exchangeRates`);
    await setDoc(ratesRef, ratesData, { merge: true });
    setInCache(`exchangeRates_${orgId}`, ratesData);
    localStorage.setItem('exchangeRates', JSON.stringify(ratesData));
  } catch (error) {
    console.error('Error updating exchange rates:', error);
    if (!navigator.onLine || error.code === 'resource-exhausted') {
      localStorage.setItem('exchangeRates', JSON.stringify(rates));
    }
    throw error;
  }
};

export const getExchangeRates = async (orgId) => {
  const ratesRef = doc(db, `organizations/${orgId}/settings/exchangeRates`);
  const snapshot = await getDoc(ratesRef);
  return snapshot.exists() ? snapshot.data() : {};
};

// Migration Helper
export const migrateLocalStorageToFirestore = async (orgId) => {
  try {
    // First, ensure the organization exists
    const orgRef = doc(db, 'organizations', orgId);
    const orgSnap = await getDoc(orgRef);
    
    if (!orgSnap.exists()) {
      await setDoc(orgRef, {
        id: orgId,
        name: 'Default Organization',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });
    }

    // Migrate employees
    const employees = JSON.parse(localStorage.getItem('employees') || '[]');
    for (const employee of employees) {
      await createEmployee(orgId, employee);
    }

    // Migrate scenarios
    const scenarios = JSON.parse(localStorage.getItem('scenarios') || '[]');
    for (const scenario of scenarios) {
      await createScenario(orgId, scenario);
    }

    // Migrate compensation ranges
    const compensationRanges = JSON.parse(localStorage.getItem('compensationRanges') || '{}');
    await updateCompensationRanges(orgId, compensationRanges);

    // Migrate exchange rates
    const exchangeRates = JSON.parse(localStorage.getItem('exchangeRates') || '{}');
    await updateExchangeRates(orgId, exchangeRates);
  } catch (error) {
    throw error;
  }
};

// Delete all data (for testing/cleanup)
export const deleteAllData = async (orgId) => {
  try {
    // Delete all employees
    const employeesRef = collection(db, `organizations/${orgId}/employees`);
    const employeesSnapshot = await getDocs(employeesRef);
    for (const doc of employeesSnapshot.docs) {
      await deleteDoc(doc.ref);
    }

    // Delete all scenarios
    const scenariosRef = collection(db, `organizations/${orgId}/scenarios`);
    const scenariosSnapshot = await getDocs(scenariosRef);
    for (const doc of scenariosSnapshot.docs) {
      await deleteDoc(doc.ref);
    }

    // Delete compensation ranges
    const rangesRef = doc(db, `organizations/${orgId}/settings`, 'compensationRanges');
    await deleteDoc(rangesRef);

    // Delete exchange rates
    const ratesRef = doc(db, `organizations/${orgId}/settings`, 'exchangeRates');
    await deleteDoc(ratesRef);

    // Finally, delete the organization
    const orgRef = doc(db, 'organizations', orgId);
    await deleteDoc(orgRef);
  } catch (error) {
    throw error;
  }
};

// Real-time updates subscription
export const subscribeToUpdates = (orgId, collectionName, onUpdate) => {
  const collectionRef = collection(db, `organizations/${orgId}/${collectionName}`);
  return onSnapshot(collectionRef, (snapshot) => {
    const updates = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    
    // Update cache
    const cacheKey = `${collectionName}_${orgId}`;
    setInCache(cacheKey, updates);
    
    // Notify subscriber
    onUpdate(updates);
  }, (error) => {
    console.error(`Error in ${collectionName} subscription:`, error);
    if (error.code === 'resource-exhausted') {
      // Fallback to localStorage
      const data = JSON.parse(localStorage.getItem(collectionName) || '[]');
      onUpdate(data);
    }
  });
};

// Add subscription management
const activeSubscriptions = new Map();

export const subscribeToEmployees = (orgId, callback) => {
  if (activeSubscriptions.has(`employees_${orgId}`)) {
    return () => {};
  }

  const employeesRef = collection(db, `organizations/${orgId}/employees`);
  const unsubscribe = onSnapshot(employeesRef, 
    (snapshot) => {
      const employees = snapshot.docs.map(doc => doc.data());
      
      // Update cache
      const cacheKey = `employees_${orgId}`;
      setInCache(cacheKey, employees);
      
      // Update localStorage for offline access
      try {
        localStorage.setItem('employees', JSON.stringify(employees));
      } catch (error) {
        console.warn('localStorage sync failed:', error);
      }
      
      // Notify subscribers
      callback(employees);
    },
    (error) => {
      console.error('Error in employees subscription:', error);
      // Fallback to localStorage on error
      const employees = JSON.parse(localStorage.getItem('employees') || '[]');
      callback(employees);
    }
  );

  activeSubscriptions.set(`employees_${orgId}`, unsubscribe);
  return () => {
    unsubscribe();
    activeSubscriptions.delete(`employees_${orgId}`);
  };
};

export const unsubscribeFromEmployees = (orgId) => {
  const unsubscribe = activeSubscriptions.get(`employees_${orgId}`);
  if (unsubscribe) {
    unsubscribe();
    activeSubscriptions.delete(`employees_${orgId}`);
  }
};

// Add subscription management for scenarios
export const subscribeToScenarios = (orgId, callback) => {
  if (activeSubscriptions.has(`scenarios_${orgId}`)) {
    return () => {};
  }

  const scenariosRef = collection(db, `organizations/${orgId}/scenarios`);
  const unsubscribe = onSnapshot(scenariosRef, 
    (snapshot) => {
      const scenarios = snapshot.docs.map(doc => doc.data());
      
      // Update cache
      const cacheKey = `scenarios_${orgId}`;
      setInCache(cacheKey, scenarios);
      
      // Update localStorage for offline access
      try {
        localStorage.setItem('scenarios', JSON.stringify(scenarios));
      } catch (error) {
        console.warn('localStorage sync failed:', error);
      }
      
      // Notify subscribers
      callback(scenarios);
    },
    (error) => {
      console.error('Error in scenarios subscription:', error);
      // Fallback to localStorage on error
      const scenarios = JSON.parse(localStorage.getItem('scenarios') || '[]');
      callback(scenarios);
    }
  );

  activeSubscriptions.set(`scenarios_${orgId}`, unsubscribe);
  return () => {
    unsubscribe();
    activeSubscriptions.delete(`scenarios_${orgId}`);
  };
};

export const unsubscribeFromScenarios = (orgId) => {
  const unsubscribe = activeSubscriptions.get(`scenarios_${orgId}`);
  if (unsubscribe) {
    unsubscribe();
    activeSubscriptions.delete(`scenarios_${orgId}`);
  }
};

// Add subscription management for exchange rates
export const subscribeToExchangeRates = (callback) => {
  if (activeSubscriptions.has('exchangeRates')) {
    return () => {};
  }

  const ratesRef = doc(db, 'exchangeRates', 'default');
  const unsubscribe = onSnapshot(ratesRef, 
    (snapshot) => {
      const rates = snapshot.exists() ? snapshot.data().rates : {
        USD: 1,
        EUR: 1.1,
        GBP: 1.27,
        CHF: 1.12,
        SEK: 0.096,
        PHP: 0.018,
        INR: 0.012
      };
      
      // Update cache
      setInCache('exchangeRates', rates);
      
      // Update localStorage for offline access
      try {
        localStorage.setItem('exchangeRates', JSON.stringify(rates));
      } catch (error) {
        console.warn('localStorage sync failed:', error);
      }
      
      // Notify subscribers
      callback(rates);
    },
    (error) => {
      console.error('Error in exchange rates subscription:', error);
      // Fallback to localStorage on error
      const rates = JSON.parse(localStorage.getItem('exchangeRates') || '{}');
      callback(rates);
    }
  );

  activeSubscriptions.set('exchangeRates', unsubscribe);
  return () => {
    unsubscribe();
    activeSubscriptions.delete('exchangeRates');
  };
};

export const unsubscribeFromExchangeRates = () => {
  const unsubscribe = activeSubscriptions.get('exchangeRates');
  if (unsubscribe) {
    unsubscribe();
    activeSubscriptions.delete('exchangeRates');
  }
};

// Location Tiers Operations
export const updateLocationTiers = async (orgId, tiers) => {
  if (!navigator.onLine) {
    localStorage.setItem('locationTiers', JSON.stringify({
      ...tiers,
      pendingSync: true
    }));
    return;
  }

  try {
    const tiersRef = doc(db, `organizations/${orgId}/settings/locationTiers`);
    await setDoc(tiersRef, tiers, { merge: true });

    // Update localStorage for offline access
    try {
      localStorage.setItem('locationTiers', JSON.stringify(tiers));
    } catch (error) {
      console.warn('localStorage sync failed:', error);
    }
  } catch (error) {
    console.error('Error updating location tiers:', error);
    
    if (!navigator.onLine || error.code === 'resource-exhausted' || error.code === 'permission-denied') {
      localStorage.setItem('locationTiers', JSON.stringify({
        ...tiers,
        pendingSync: true
      }));
    } else {
      throw error;
    }
  }
};

export const getLocationTiers = async (orgId) => {
  try {
    const tiersRef = doc(db, `organizations/${orgId}/settings/locationTiers`);
    const snapshot = await getDoc(tiersRef);
    return snapshot.exists() ? snapshot.data() : null;
  } catch (error) {
    console.error('Error getting location tiers:', error);
    // Fallback to localStorage
    try {
      return JSON.parse(localStorage.getItem('locationTiers') || 'null');
    } catch (localError) {
      console.error('Error reading from localStorage:', localError);
      return null;
    }
  }
}; 