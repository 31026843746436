import React, { useState, useEffect } from 'react';
import './EditEmployeeModal.css';

function EditEmployeeModal({ employee, isOpen, closeModal, onEditEmployee, onDeleteEmployee, existingEmployees, organizationOptions, contractTypeOptions, teamOptions }) {
  // Helper function to normalize contract type
  const normalizeContractType = (contractType) => {
    if (!contractType) return '';
    // Find matching contract type ignoring case
    const matchingType = contractTypeOptions?.find(type => 
      type.toLowerCase() === contractType.toLowerCase() ||
      type.toLowerCase().replace(/\s+/g, '') === contractType.toLowerCase().replace(/\s+/g, '')
    );
    return matchingType || contractType;
  };

  // Initialize form data with employee data or empty values
  const getInitialFormData = () => {
    if (!employee) {
      return {
        firstName: '',
        surname: '',
        title: '',
        org: '',
        function: '',
        team: '',
        jobLevel: '',
        location: '',
        directManager: '',
        startDate: '',
        resignationDate: '',
        endDate: '',
        lastDayWorked: '',
        contractType: '',
        baseSalary: 0,
        currency: 'USD',
        stipend: 0,
        employerTax: 0,
        nationalInsurance: 0,
        commission: 0,
        adminFees: 0,
        otherRecurringPayments: 0,
        employeeId: '',
        jobId: ''
      };
    }

    return {
      firstName: employee.firstName || '',
      surname: employee.surname || '',
      title: employee.title || '',
      org: employee.org || '',
      function: employee.function || '',
      team: employee.team || '',
      jobLevel: employee.jobLevel || employee.level || '',
      location: employee.location || '',
      directManager: employee.directManager || '',
      startDate: employee.startDate || '',
      resignationDate: employee.resignationDate || '',
      endDate: employee.endDate || '',
      lastDayWorked: employee.lastDayWorked || '',
      contractType: normalizeContractType(employee.contractType),
      baseSalary: employee.baseSalary || 0,
      currency: employee.currency || 'USD',
      stipend: employee.stipend || 0,
      employerTax: employee.employeeTax || 0,
      nationalInsurance: employee.nationalInsurance || 0,
      commission: employee.commission || 0,
      adminFees: employee.platformFees || 0,
      otherRecurringPayments: employee.otherRecurringPayments || 0,
      employeeId: employee.employeeId || '',
      jobId: employee.jobId || ''
    };
  };

  const [formData, setFormData] = useState(getInitialFormData());
  const [showTemporaryLeave, setShowTemporaryLeave] = useState(false);
  const [isOnTemporaryLeave, setIsOnTemporaryLeave] = useState(false);
  const [temporaryLeave, setTemporaryLeave] = useState({
    startDate: '',
    endDate: '',
    leaveType: ''
  });

  const currencyOptions = [
    'USD', 'EUR', 'GBP', 'CAD', 'AUD', 'SGD', 'ILS', 'INR'
  ];

  // Update form data when employee changes
  useEffect(() => {
    if (!employee) return;
    setFormData(getInitialFormData());
    // Check if employee has temporary leave and set state accordingly
    if (employee.temporaryLeave) {
      setTemporaryLeave(employee.temporaryLeave);
      setShowTemporaryLeave(true);
      setIsOnTemporaryLeave(true);
    } else {
      setShowTemporaryLeave(false);
      setIsOnTemporaryLeave(false);
      setTemporaryLeave({
        startDate: '',
        endDate: '',
        leaveType: ''
      });
    }
  }, [employee]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name.includes('Salary') || name.includes('stipend') || 
               name.includes('Tax') || name.includes('Insurance') || 
               name.includes('commission') || name.includes('Fees') || 
               name.includes('Payments')
        ? Number(value)
        : value
    }));
  };

  const handleTemporaryLeaveChange = (e) => {
    const { name, value } = e.target;
    setTemporaryLeave(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleTemporaryLeaveSubmit = (e) => {
    e.preventDefault();
    console.log('Submitting temporary leave:', { temporaryLeave, employee });
    if (!employee || !temporaryLeave.startDate || !temporaryLeave.endDate || !temporaryLeave.leaveType) return;

    const submissionData = {
      ...employee,  // Include all existing employee data
      id: employee.id,
      temporaryLeave: {
        startDate: temporaryLeave.startDate,
        endDate: temporaryLeave.endDate,
        leaveType: temporaryLeave.leaveType,
        status: 'temporary'
      }
    };
    console.log('Submitting data to onEditEmployee:', submissionData);

    onEditEmployee(submissionData);
    closeModal();  // Close the entire modal after submission
  };

  const handleCancelTemporaryLeave = () => {
    if (!employee) return;

    const submissionData = {
      ...employee,
      id: employee.id,
      temporaryLeave: null  // Remove temporary leave data
    };

    onEditEmployee(submissionData);
    setShowTemporaryLeave(false);
    setIsOnTemporaryLeave(false);
    setTemporaryLeave({
      startDate: '',
      endDate: '',
      leaveType: ''
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!employee) return;
    
    // Map new field names back to old ones for Firebase
    const submissionData = {
      id: employee.id,
      ...formData
    };

    // Remove undefined fields and handle field mappings
    if (formData.employerTax !== undefined) {
      submissionData.employeeTax = formData.employerTax;
    }
    if (formData.adminFees !== undefined) {
      submissionData.platformFees = formData.adminFees;
    }

    // Add temporary leave data if it's being set
    if (showTemporaryLeave && temporaryLeave.startDate && temporaryLeave.endDate) {
      submissionData.temporaryLeave = {
        startDate: temporaryLeave.startDate,
        endDate: temporaryLeave.endDate,
        leaveType: temporaryLeave.leaveType,
        status: 'temporary'
      };
    }
    
    onEditEmployee(submissionData);
    closeModal();
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this employee? This action cannot be undone.')) {
      onDeleteEmployee(employee.id);
      closeModal();
    }
  };

  if (!isOpen || !employee) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-panel">
        <h2 className="modal-title">Edit Employee</h2>
        <form onSubmit={handleSubmit} className="modal-form">
          <div className="form-grid">
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="surname">Surname</label>
              <input
                type="text"
                id="surname"
                name="surname"
                value={formData.surname}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="org">Organization</label>
              <div className="combobox-wrapper">
                <input
                  type="text"
                  list="org-options"
                  id="org"
                  name="org"
                  value={formData.org}
                  onChange={handleChange}
                  required
                  placeholder="Select or enter organization"
                />
                <datalist id="org-options">
                  {organizationOptions?.map(org => (
                    <option key={org} value={org} />
                  ))}
                </datalist>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="function">Function</label>
              <div className="combobox-wrapper">
                <input
                  type="text"
                  list="function-options"
                  id="function"
                  name="function"
                  value={formData.function}
                  onChange={handleChange}
                  placeholder="Select or enter function"
                />
                <datalist id="function-options">
                  {existingEmployees
                    ?.map(emp => emp.function)
                    .filter((function_, index, self) => 
                      function_ && self.indexOf(function_) === index
                    )
                    .map(function_ => (
                      <option key={function_} value={function_} />
                    ))
                  }
                </datalist>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="team">Team</label>
              <div className="combobox-wrapper">
                <input
                  type="text"
                  list="team-options"
                  id="team"
                  name="team"
                  value={formData.team}
                  onChange={handleChange}
                  required
                  placeholder="Select or enter team name"
                />
                <datalist id="team-options">
                  {teamOptions?.map(team => (
                    <option key={team} value={team} />
                  ))}
                </datalist>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="jobLevel">Job Level</label>
              <input
                type="text"
                id="jobLevel"
                name="jobLevel"
                value={formData.jobLevel}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="location">Location</label>
              <div className="combobox-wrapper">
                <input
                  type="text"
                  list="location-options"
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  required
                  placeholder="Select or enter location"
                />
                <datalist id="location-options">
                  {[...new Set(existingEmployees.map(emp => emp.location))].map(location => (
                    <option key={location} value={location} />
                  ))}
                </datalist>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="directManager">Direct Manager</label>
              <div className="combobox-wrapper">
                <input
                  type="text"
                  list="manager-options"
                  id="directManager"
                  name="directManager"
                  value={formData.directManager}
                  onChange={handleChange}
                  placeholder="Select or enter manager"
                />
                <datalist id="manager-options">
                  {existingEmployees?.map(emp => (
                    <option key={emp.id} value={`${emp.firstName} ${emp.surname}`} />
                  ))}
                </datalist>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="startDate">Start Date</label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="resignationDate">Resignation Date (Optional)</label>
              <input
                type="date"
                id="resignationDate"
                name="resignationDate"
                value={formData.resignationDate}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="endDate">End Date (Optional)</label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="lastDayWorked">Last Day Worked (Optional)</label>
              <input
                type="date"
                id="lastDayWorked"
                name="lastDayWorked"
                value={formData.lastDayWorked}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="contractType">Contract Type</label>
              <div className="combobox-wrapper">
                <input
                  type="text"
                  list="contract-type-options"
                  id="contractType"
                  name="contractType"
                  value={formData.contractType}
                  onChange={handleChange}
                  required
                  placeholder="Select or enter contract type"
                />
                <datalist id="contract-type-options">
                  {contractTypeOptions?.map(type => (
                    <option key={type} value={type} />
                  ))}
                </datalist>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="baseSalary">Base Salary</label>
              <input
                type="number"
                id="baseSalary"
                name="baseSalary"
                value={formData.baseSalary}
                onChange={handleChange}
                required
                min="0"
              />
            </div>

            <div className="form-group">
              <label htmlFor="currency">Currency</label>
              <div className="combobox-wrapper">
                <input
                  type="text"
                  list="currency-options"
                  id="currency"
                  name="currency"
                  value={formData.currency}
                  onChange={handleChange}
                  required
                  placeholder="Select or enter currency"
                />
                <datalist id="currency-options">
                  {currencyOptions.map(currency => (
                    <option key={currency} value={currency} />
                  ))}
                </datalist>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="stipend">Stipend</label>
              <input
                type="number"
                id="stipend"
                name="stipend"
                value={formData.stipend}
                onChange={handleChange}
                min="0"
              />
            </div>

            <div className="form-group">
              <label htmlFor="employerTax">Employer Tax</label>
              <input
                type="number"
                id="employerTax"
                name="employerTax"
                value={formData.employerTax}
                onChange={handleChange}
                min="0"
              />
            </div>

            <div className="form-group">
              <label htmlFor="nationalInsurance">National Insurance</label>
              <input
                type="number"
                id="nationalInsurance"
                name="nationalInsurance"
                value={formData.nationalInsurance}
                onChange={handleChange}
                min="0"
              />
            </div>

            <div className="form-group">
              <label htmlFor="commission">Commission</label>
              <input
                type="number"
                id="commission"
                name="commission"
                value={formData.commission}
                onChange={handleChange}
                min="0"
              />
            </div>

            <div className="form-group">
              <label htmlFor="adminFees">Admin Fees</label>
              <input
                type="number"
                id="adminFees"
                name="adminFees"
                value={formData.adminFees}
                onChange={handleChange}
                min="0"
              />
            </div>

            <div className="form-group">
              <label htmlFor="otherRecurringPayments">Other Recurring Payments</label>
              <input
                type="number"
                id="otherRecurringPayments"
                name="otherRecurringPayments"
                value={formData.otherRecurringPayments}
                onChange={handleChange}
                min="0"
              />
            </div>

            <div className="form-group">
              <label htmlFor="employeeId">Employee ID</label>
              <input
                type="text"
                id="employeeId"
                name="employeeId"
                value={formData.employeeId}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="jobId">Job ID</label>
              <input
                type="text"
                id="jobId"
                name="jobId"
                value={formData.jobId}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="modal-actions">
            <button 
              type="button"
              className="delete-button"
              onClick={handleDelete}
            >
              Delete Employee
            </button>
            <button
              type="button"
              className="temporary-leave-button"
              onClick={() => {
                if (isOnTemporaryLeave) {
                  handleCancelTemporaryLeave();
                } else {
                  setShowTemporaryLeave(!showTemporaryLeave);
                  if (showTemporaryLeave) {
                    // Reset temporary leave data when canceling
                    setTemporaryLeave({
                      startDate: '',
                      endDate: '',
                      leaveType: ''
                    });
                  }
                }
              }}
            >
              {isOnTemporaryLeave ? 'Cancel Temporary Leave' : showTemporaryLeave ? 'Cancel' : 'Place on Temporary Leave'}
            </button>
            <div className="right-buttons">
              <button 
                type="button" 
                className="cancel-button" 
                onClick={closeModal}
              >
                Cancel
              </button>
              <button 
                type="submit" 
                className="submit-button"
              >
                Save Changes
              </button>
            </div>
          </div>

          {showTemporaryLeave && !employee?.temporaryLeave && (
            <div className="temporary-leave-section">
              <h3>Temporary Leave Details</h3>
              <div className="form-group">
                <label htmlFor="leaveType">Leave Type</label>
                <input
                  type="text"
                  id="leaveType"
                  name="leaveType"
                  value={temporaryLeave.leaveType}
                  onChange={handleTemporaryLeaveChange}
                  placeholder="Enter leave type"
                />
              </div>
              <div className="form-group">
                <label htmlFor="tempLeaveStart">Commencing</label>
                <input
                  type="date"
                  id="tempLeaveStart"
                  name="startDate"
                  value={temporaryLeave.startDate}
                  onChange={handleTemporaryLeaveChange}
                  min={new Date().toISOString().split('T')[0]}
                  required={showTemporaryLeave}
                />
              </div>
              <div className="form-group">
                <label htmlFor="tempLeaveEnd">Ending</label>
                <input
                  type="date"
                  id="tempLeaveEnd"
                  name="endDate"
                  value={temporaryLeave.endDate}
                  onChange={handleTemporaryLeaveChange}
                  min={temporaryLeave.startDate || new Date().toISOString().split('T')[0]}
                  required={showTemporaryLeave}
                />
              </div>
              <div className="temporary-leave-actions">
                <button
                  type="button"
                  className="temporary-leave-submit"
                  onClick={handleTemporaryLeaveSubmit}
                  disabled={!temporaryLeave.startDate || !temporaryLeave.endDate || !temporaryLeave.leaveType}
                >
                  Submit Temporary Leave
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default EditEmployeeModal;