import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirebase } from '../contexts/FirebaseContext';
import { collection, query, where, orderBy, onSnapshot, updateDoc, doc, writeBatch } from 'firebase/firestore';
import { db } from '../config/firebase';
import './NotificationBell.css';

const NotificationBell = () => {
  const navigate = useNavigate();
  const { getUserOrganizations, handleInviteResponse, user } = useFirebase();
  const [pendingInvites, setPendingInvites] = useState([]);
  const [commentMentions, setCommentMentions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Check if we should open notifications automatically
    const shouldOpen = sessionStorage.getItem('openNotifications');
    if (shouldOpen === 'true') {
      setIsOpen(true);
      // Clear the flag so it doesn't reopen on subsequent renders
      sessionStorage.removeItem('openNotifications');
    }
  }, [user]); // Add user as dependency so it runs after login

  // Fetch organization invites
  useEffect(() => {
    const fetchInvites = async () => {
      try {
        const orgs = await getUserOrganizations();
        // Filter to only get organizations with pending invites
        const pendingOrgs = orgs?.filter(org => org.hasPendingInvite) || [];
        setPendingInvites(pendingOrgs);
      } catch (error) {
        console.error('Error fetching invites:', error);
      }
    };

    fetchInvites();
    // Fetch invites every minute
    const interval = setInterval(fetchInvites, 60000);
    return () => clearInterval(interval);
  }, [getUserOrganizations]);

  // Fetch comment mentions
  useEffect(() => {
    if (!user?.orgId) return;

    const notificationsRef = collection(db, 'organizations', user.orgId, 'notifications');
    const q = query(
      notificationsRef,
      where('userId', '==', user.uid),
      where('type', '==', 'mention'),
      orderBy('timestamp', 'desc')
    );

    console.log('Fetching notifications for user:', user.uid); // Debug log

    const unsubscribe = onSnapshot(q, (snapshot) => {
      console.log('Notifications snapshot:', snapshot.docs.map(doc => doc.data())); // Debug log
      const notificationsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCommentMentions(notificationsData);
    }, (error) => {
      console.error('Error fetching notifications:', error); // Debug log
    });

    return () => unsubscribe();
  }, [user?.orgId, user?.uid]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleAccept = async (orgId) => {
    try {
      await handleInviteResponse(orgId, true);
      setPendingInvites(prevInvites => prevInvites.filter(invite => invite.id !== orgId));
    } catch (error) {
      console.error('Error accepting invite:', error);
    }
  };

  const handleDecline = async (orgId) => {
    try {
      await handleInviteResponse(orgId, false);
      setPendingInvites(prevInvites => prevInvites.filter(invite => invite.id !== orgId));
    } catch (error) {
      console.error('Error declining invite:', error);
    }
  };

  const handleMentionClick = async (notification) => {
    // Mark as read first
    if (!notification.read) {
      const notificationRef = doc(db, 'organizations', user.orgId, 'notifications', notification.id);
      await updateDoc(notificationRef, { read: true });
    }
    
    // Store the role info in localStorage
    localStorage.setItem('openCommentsPanel', 'true');
    localStorage.setItem('commentsPanelRoleId', notification.roleId);
    localStorage.setItem('commentsPanelRoleName', notification.roleName);
    
    // Close the notifications dropdown
    setIsOpen(false);
    
    // Check if we're already on the talent page
    const currentPath = window.location.pathname;
    if (currentPath === '/talent') {
      // If we're already on the talent page, dispatch a custom event
      window.dispatchEvent(new CustomEvent('openCommentsPanelForRole', {
        detail: {
          roleId: notification.roleId,
          roleName: notification.roleName
        }
      }));
    } else {
      // If we're on a different page, navigate to talent
      navigate('/talent');
    }
  };

  const formatTimeAgo = (timestamp) => {
    if (!timestamp) return '';
    const now = new Date();
    const date = timestamp.toDate();
    const seconds = Math.floor((now - date) / 1000);
    
    if (seconds < 60) return 'just now';
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) return `${minutes}m ago`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours}h ago`;
    const days = Math.floor(hours / 24);
    if (days < 7) return `${days}d ago`;
    return date.toLocaleDateString();
  };

  const totalNotifications = pendingInvites.length + commentMentions.filter(n => !n.read).length;

  return (
    <div className="notification-bell-container" ref={dropdownRef}>
      <button 
        className="notification-bell-button" 
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Notifications"
      >
        <svg 
          width="20" 
          height="20" 
          viewBox="0 0 24 24" 
          fill="none" 
          stroke="currentColor" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        >
          <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
          <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
        </svg>
        {totalNotifications > 0 && (
          <span className="notification-badge">{totalNotifications}</span>
        )}
      </button>

      {isOpen && (
        <div className="notification-dropdown">
          <div className="notification-header">
            <h3>Notifications</h3>
            {commentMentions.filter(n => n.read).length > 0 && (
              <button 
                className="clear-all-button"
                onClick={async () => {
                  try {
                    const batch = writeBatch(db);
                    commentMentions.forEach(notification => {
                      if (notification.read) {
                        const notificationRef = doc(db, 'organizations', user.orgId, 'notifications', notification.id);
                        batch.delete(notificationRef);
                      }
                    });
                    await batch.commit();
                  } catch (error) {
                    console.error('Error clearing notifications:', error);
                  }
                }}
              >
                Clear Read Notifications
              </button>
            )}
          </div>
          <div className="notification-list">
            {/* Organization Invites */}
            {pendingInvites.map((org) => (
              <div key={org.id} className="notification-item invite">
                <div className="notification-content">
                  <p className="org-name">Invitation to join {org.name}</p>
                </div>
                <div className="notification-actions">
                  <button 
                    onClick={() => handleDecline(org.id)}
                    className="decline-button"
                  >
                    Decline
                  </button>
                  <button 
                    onClick={() => handleAccept(org.id)}
                    className="accept-button"
                  >
                    Accept
                  </button>
                </div>
              </div>
            ))}

            {/* Comment Mentions */}
            {commentMentions.map(mention => (
              <div 
                key={mention.id} 
                className={`notification-item mention ${!mention.read ? 'unread' : ''}`}
                onClick={() => handleMentionClick(mention)}
              >
                <div className="notification-content">
                  <div className="mention-message">
                    <strong>{mention.sourceUserName}</strong> mentioned you in a comment
                  </div>
                  <div className="mention-role">
                    Role: {mention.roleName}
                  </div>
                  <div className="mention-text">
                    {mention.commentText}
                  </div>
                  <div className="mention-time">
                    {formatTimeAgo(mention.timestamp)}
                  </div>
                </div>
                {!mention.read && <div className="unread-dot" />}
              </div>
            ))}

            {totalNotifications === 0 && (
              <div className="notification-item no-notifications">
                <p>No new notifications</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationBell; 