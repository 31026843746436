import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import './ScenarioPlanning.css';
import ScenarioOrgChart from './ScenarioOrgChart.jsx';
import ScenarioRoleModal from '../../components/modals/ScenarioRoleModal';
import UnallocatedRoles from '../../UnallocatedRoles';
import { utils, writeFile, read } from 'xlsx';
import { useFirebase } from '../../contexts/FirebaseContext';
import { db } from '../../config/firebase';
import { doc, writeBatch, getDoc, collection, onSnapshot } from 'firebase/firestore';
import { useRole } from '../../contexts/RoleContext';

const PAGE_SIZE = 10;

function ScenarioPlanning({ scenarios = [], onScenarioChange, employees = [] }) {
    const { user, scenarios: firebaseScenarios, loadMoreData, createScenario, updateScenario, compensationRanges, exchangeRates, presentationCurrency } = useFirebase();
    const { hasPermission } = useRole();
    
    // Helper Functions
    const calculateBaseCompensation = (role) => {
        if (!role.function || !role.level) return 0;
        
        // Get the key in the format used by the compensation framework
        const key = `${role.level}-${role.function}`;
        
        // Get the range and currency
        const range = compensationRanges[key];
        const sourceCurrency = compensationRanges.currency || 'USD';  // Default to USD if not specified
        
        if (!range || !range.mid) {
            console.warn(`No compensation range found for ${key}`);
            return 0;
        }

        // Get the base amount in the compensation range currency
        const baseAmount = range.mid;

        // Convert to presentation currency if needed
        if (sourceCurrency !== presentationCurrency && exchangeRates?.rates) {
            console.log('Debug - Exchange rates structure:', JSON.stringify(exchangeRates.rates, null, 2));
            console.log('Debug - Exchange rates:', {
                allRates: exchangeRates.rates,
                sourceCurrency,
                presentationCurrency,
                lookingFor: [
                    sourceCurrency,
                    `${sourceCurrency}:USD`,
                    `USD:${sourceCurrency}`,
                    presentationCurrency,
                    `${presentationCurrency}:USD`,
                    `USD:${presentationCurrency}`
                ]
            });

            // Try to get the rate in both formats (direct and legacy)
            const sourceRate = exchangeRates.rates[sourceCurrency] || exchangeRates.rates[`${sourceCurrency}:USD`] || exchangeRates.rates[`USD:${sourceCurrency}`] || 1;
            const targetRate = exchangeRates.rates[presentationCurrency] || exchangeRates.rates[`${presentationCurrency}:USD`] || exchangeRates.rates[`USD:${presentationCurrency}`] || 1;
            
            // If we're using USD:X format instead of X:USD, we need to invert the rate
            const finalSourceRate = exchangeRates.rates[`USD:${sourceCurrency}`] ? 1 / sourceRate : sourceRate;
            const finalTargetRate = exchangeRates.rates[`USD:${presentationCurrency}`] ? 1 / targetRate : targetRate;
            
            console.log('Debug - Found rates:', {
                sourceRate,
                targetRate,
                finalSourceRate,
                finalTargetRate,
                rateSource: exchangeRates.rates[`USD:${sourceCurrency}`] ? 'USD:Currency' : 'Currency:USD'
            });
            
            // Convert through USD: source -> USD -> presentation
            const inUSD = baseAmount * finalSourceRate;
            const inPresentation = inUSD / finalTargetRate;
            
            console.log('Currency conversion:', {
                from: { currency: sourceCurrency, amount: baseAmount, rate: finalSourceRate },
                intermediate: { currency: 'USD', amount: inUSD },
                to: { currency: presentationCurrency, amount: inPresentation, rate: finalTargetRate }
            });
            
            return inPresentation;
        }

        return baseAmount;
    };

    // Helper function to get unique teams
    const getUniqueTeams = (employees) => {
        // Filter out empty or null values and get unique teams
        const teams = [...new Set(employees.map(emp => emp.team).filter(team => team && team.trim()))];
        // Sort alphabetically
        return teams.sort();
    };

    const [hasMoreEmployees, setHasMoreEmployees] = useState(true);
    const [hasMoreScenarios, setHasMoreScenarios] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [error, setError] = useState(null);
    const [expandedOrg, setExpandedOrg] = useState(null);
    const [selectedOrg, setSelectedOrg] = useState(() => {
        const saved = localStorage.getItem('selectedOrg');
        return saved || 'all';
    });
    const [selectedScenario, setSelectedScenario] = useState(() => {
        const saved = localStorage.getItem('selectedScenario');
        return saved || 'all';
    });
    const [expandedScenario, setExpandedScenario] = useState(null);
    const [activeScenarioId, setActiveScenarioId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [isOrgChartVisible, setIsOrgChartVisible] = useState(true);
    const [showPlannedHires, setShowPlannedHires] = useState(true);
    const [isPlanningOpen, setIsPlanningOpen] = useState(() => {
        const saved = localStorage.getItem('planningStatus');
        return saved ? JSON.parse(saved) : true;
    });
    const [localApprovalRequests, setLocalApprovalRequests] = useState(() => {
        return JSON.parse(localStorage.getItem('approvalStatuses') || '{}');
    });
    const [recruitmentStatus, setRecruitmentStatus] = useState({});
    const [isCondensedView, setIsCondensedView] = useState(false);
    const [currentHire, setCurrentHire] = useState({
        roleTitle: '',
        team: '',
        function: '',
        level: '',
        directManager: '',
        preferredStartMonth: 'January',
        preferredStartYear: '2024',
        priority: 'Medium',
        exceptionReason: '',
        jobId: ''
    });

    // Load more data when scrolling near bottom
    const handleScroll = useCallback((e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && !isLoadingMore) {
            setIsLoadingMore(true);
            Promise.all([
                hasMoreEmployees && loadMoreData('employees'),
                hasMoreScenarios && loadMoreData('scenarios')
            ]).finally(() => {
                setIsLoadingMore(false);
            });
        }
    }, [loadMoreData, hasMoreEmployees, hasMoreScenarios, isLoadingMore]);

    useEffect(() => {
        // Update hasMore flags based on last batch size
        setHasMoreEmployees(employees.length % PAGE_SIZE === 0);
        setHasMoreScenarios(firebaseScenarios.length % PAGE_SIZE === 0);
    }, [employees.length, firebaseScenarios.length]);

    // Subscribe to approval statuses from Firestore
    useEffect(() => {
        if (!user?.orgId) return;

        const approvalStatusesRef = collection(db, `organizations/${user.orgId}/approvalStatuses`);
        const unsubscribe = onSnapshot(approvalStatusesRef, (snapshot) => {
            const statuses = {};
            snapshot.forEach((doc) => {
                statuses[doc.id] = doc.data();
            });
            setLocalApprovalRequests(statuses);
        });

        return () => unsubscribe();
    }, [user?.orgId]);

    // Helper function to check approval status
    const getApprovalStatus = (hireId) => {
        return localApprovalRequests[hireId]?.status || null;
    };

    // Update budgets when compensation framework currency changes
    useEffect(() => {
        const updateScenarioBudgets = async () => {
            if (!compensationRanges?.currency || !scenarios.length) {
                console.log('Skipping budget update:', { 
                    hasCurrency: Boolean(compensationRanges?.currency), 
                    scenariosCount: scenarios.length,
                    currentCurrency: compensationRanges?.currency
                });
                return;
            }

            console.log('Starting budget update for all scenarios:', {
                currency: compensationRanges.currency,
                presentationCurrency,
                exchangeRates: exchangeRates?.rates,
                hasRanges: Boolean(compensationRanges)
            });

            // Update all scenarios
            for (const scenario of scenarios) {
                if (!scenario.plannedHires) continue;

                const updatedHires = scenario.plannedHires.map(hire => {
                    // Get the new budget in the new compensation range currency
                    const newBudget = calculateBaseCompensation({
                        function: hire.function,
                        level: hire.level
                    });

                    console.log(`Calculated budget for ${hire.function} ${hire.level}:`, {
                        oldBudget: hire.budget,
                        newBudget,
                        currency: compensationRanges.currency
                    });

                    return {
                        ...hire,
                        budget: newBudget,
                        totalCost: newBudget // Update totalCost to match the new budget
                    };
                });

                if (JSON.stringify(updatedHires) !== JSON.stringify(scenario.plannedHires)) {
                    const updatedScenario = {
                        ...scenario,
                        plannedHires: updatedHires,
                        lastModified: new Date().toISOString()
                    };
                    await updateScenario(scenario.id, updatedScenario);
                    console.log(`Updated budgets for scenario ${scenario.id} with currency ${presentationCurrency}`);
                } else {
                    console.log(`No budget changes needed for scenario ${scenario.id}`);
                }
            }
        };

        updateScenarioBudgets();
    }, [scenarios, compensationRanges, presentationCurrency, exchangeRates, updateScenario, calculateBaseCompensation]);

    // Helper Functions - Define these after all state declarations
    const getUniqueOrgs = () => {
        const orgs = new Set(employees.map(emp => emp.org).filter(Boolean));
        return Array.from(orgs).sort() || ['Default Organization'];
    };

    const getFilteredScenarios = () => {
        if (!firebaseScenarios) return [];
        
        const filtered = firebaseScenarios.filter(scenario => {
            const matchesOrg = selectedOrg === 'all' || scenario.organization === selectedOrg;
            const matchesScenario = selectedScenario === 'all' || scenario.name === selectedScenario;
            return matchesOrg && matchesScenario;
        });
        return filtered;
    };

    // Add storage event listener
    useEffect(() => {
        const handleStorageChange = () => {
            const savedStatuses = JSON.parse(localStorage.getItem('approvalStatuses') || '{}');
            setLocalApprovalRequests(savedStatuses);
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    // Helper function to safely find scenario
    const findScenarioForType = (scenarioType, org) => {
        if (!firebaseScenarios || !Array.isArray(firebaseScenarios)) {
            return null;
        }
        
        // First try exact match with valid ID
        let scenario = firebaseScenarios.find(s => 
            s?.id && 
            s?.name === scenarioType && 
            s?.organization === org
        );

        if (scenario?.id) {
            return scenario;
        }

        // Try case-insensitive match for organization
        scenario = firebaseScenarios.find(s => 
            s?.id && 
            s?.name === scenarioType && 
            s?.organization?.toLowerCase() === org?.toLowerCase()
        );

        if (scenario?.id) {
            return scenario;
        }

        // Try matching by planned hires
        scenario = firebaseScenarios.find(s => 
            s?.id && 
            s?.name === scenarioType && 
            Array.isArray(s?.plannedHires) && 
            s.plannedHires.some(hire => 
                hire?.organization?.toLowerCase() === org?.toLowerCase()
            )
        );

        if (scenario?.id) {
            return scenario;
        }

        // Only check for broken scenarios and log errors if we're actively searching
        if (activeScenarioId || expandedScenario) {
            // Check for broken scenarios (without IDs) that need repair
            const brokenScenario = firebaseScenarios.find(s => 
                !s.id && 
                s?.name === scenarioType && 
                (s?.organization === org || 
                 s?.organization?.toLowerCase() === org?.toLowerCase())
            );

            if (brokenScenario) {
                console.warn('Found broken scenario, attempting repair:', {
                    name: brokenScenario.name,
                    organization: brokenScenario.organization
                });

                // Create a new scenario with the same data but a valid ID
                const repairedScenario = {
                    ...brokenScenario,
                    id: Date.now().toString(),
                    updatedAt: new Date().toISOString()
                };
                
                // Update the scenario in Firebase
                createScenario(repairedScenario).catch(error => {
                    console.error('Failed to repair scenario:', error);
                });

                return repairedScenario;
            }

            // Only log if we're actively searching and debugging is needed
            if (process.env.NODE_ENV === 'development') {
                console.debug('No matching scenario found:', { 
                    scenarioType, 
                    org,
                    matchingTypeCount: firebaseScenarios.filter(s => s?.name === scenarioType).length,
                    matchingOrgCount: firebaseScenarios.filter(s => s?.organization === org).length,
                    scenariosWithoutIds: firebaseScenarios.filter(s => !s.id).length
                });
            }
        }

        return null;
    };

    // Initialize a single scenario when requested
    const initializeScenario = async (scenarioType, organization) => {
        try {
            const newScenario = {
                id: Date.now().toString(),
                name: scenarioType,
                organization: organization,
                description: `${scenarioType} scenario for ${organization}`,
                plannedHires: [],
                status: 'draft',
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
            };
            
            await createScenario(newScenario);
            console.log(`Created scenario: ${scenarioType} for ${organization}`);
        } catch (error) {
            console.error(`Failed to create scenario: ${scenarioType} for ${organization}`, error);
            throw error;
        }
    };

    // Handle approval request
    const handleApprovalRequest = async (hire, scenarioType) => {
        console.log('=== Starting handleApprovalRequest ===', {
            hire,
            scenarioType,
            userOrgId: user?.orgId
        });

        if (!firebaseScenarios || !hire || !scenarioType || !user?.orgId) {
            console.error('Missing required data for approval request:', {
                hasScenarios: !!firebaseScenarios,
                hasHire: !!hire,
                hasScenarioType: !!scenarioType,
                hasOrgId: !!user?.orgId
            });
            return;
        }
        
        const currentScenario = firebaseScenarios.find(s => s.name === scenarioType);
        
        if (!currentScenario) {
            console.error('No scenario found for type:', scenarioType);
            return;
        }
        
        // Get current status from Firestore
        const approvalRef = doc(db, `organizations/${user.orgId}/approvalStatuses/${hire.id}`);
        console.log('Checking current approval status at path:', `organizations/${user.orgId}/approvalStatuses/${hire.id}`);
        
        const approvalDoc = await getDoc(approvalRef);
        const currentStatus = approvalDoc.exists() ? approvalDoc.data().status : null;
        const newStatus = currentStatus === 'Pending' ? null : 'Pending';
        
        console.log('Status check:', {
            currentStatus,
            newStatus,
            docExists: approvalDoc.exists()
        });
        
        try {
            const batch = writeBatch(db);
            
            // When reverting, delete the approval status
            if (newStatus === null) {
                console.log('Reverting approval - deleting document');
                batch.delete(approvalRef);
            } else {
                // Create new approval status
                const approvalData = {
                    status: newStatus,
                    date: new Date().toISOString(),
                    approver: 'Not Assigned',
                    scenarioId: currentScenario.id,
                    scenarioType: scenarioType,
                    organization: currentScenario.organization,
                    roleTitle: hire.roleTitle,
                    team: hire.team,
                    directManager: hire.directManager,
                    totalCost: hire.totalCost,
                    updatedBy: user.uid,
                    updatedByName: user.displayName || 'Current User'
                };
                console.log('Setting new approval status:', approvalData);
                batch.set(approvalRef, approvalData);
            }
            
            // Update Firebase - ensure no undefined values
            const updatedHires = currentScenario.plannedHires.map(h => {
                if (h.id !== hire.id) return h;
                
                const updatedHire = { ...h };
                
                if (newStatus === null) {
                    // If reverting, remove all approval-related fields
                    delete updatedHire.approvalStatus;
                    delete updatedHire.approver;
                    delete updatedHire.approvalDate;
                    delete updatedHire.approvalComments;
                    delete updatedHire.approvalReverted;
                } else {
                    // If setting to pending, add new values
                    updatedHire.approvalStatus = 'Pending';
                    updatedHire.approver = 'Not Assigned';
                    updatedHire.approvalDate = new Date().toISOString();
                    // Only set these if they have values
                    if (h.approvalComments) updatedHire.approvalComments = h.approvalComments;
                }
                
                return updatedHire;
            });

            const updatedScenario = {
                ...currentScenario,
                plannedHires: updatedHires,
                lastModified: new Date().toISOString()
            };

            // Add scenario update to batch
            const scenarioRef = doc(db, `organizations/${user.orgId}/scenarios/${currentScenario.id}`);
            console.log('Updating scenario at path:', `organizations/${user.orgId}/scenarios/${currentScenario.id}`);
            batch.set(scenarioRef, updatedScenario);

            // Commit all changes
            console.log('Committing batch write...');
            await batch.commit();
            console.log('Batch write successful');

            // Update local state through the callback
            await updateScenario(currentScenario.id, updatedScenario);
            console.log('Local state updated successfully');

        } catch (error) {
            console.error('Error updating approval status:', error);
            alert('Failed to update approval status. Please try again.');
        }
    };

    // Render approval button based on status
    const renderApprovalButton = (hire, scenarioType) => {
        const status = getApprovalStatus(hire.id);
        const isApproved = status === 'Approved';
        const isRejected = status === 'Rejected';
        const isPending = status === 'Pending';

        if (isApproved || isRejected) {
            return (
                <button
                    className="approval-request-button"
                    onClick={() => handleApprovalRequest(hire, scenarioType)}
                    data-status={status.toLowerCase()}
                >
                    Revert {status}
                </button>
            );
        } else if (isPending) {
            return (
                <button
                    className="approval-request-button"
                    onClick={() => handleApprovalRequest(hire, scenarioType)}
                    data-status="pending"
                >
                    Revert Pending
                </button>
            );
        } else {
            return (
                <button
                    className="approval-request-button"
                    onClick={() => handleApprovalRequest(hire, scenarioType)}
                    disabled={!isPlanningOpen || hire.isCompleted}
                >
                    Send for Approval
                </button>
            );
        }
    };

    const formatStartDate = (month, year) => {
        const monthMap = {
            'January': '01', 'February': '02', 'March': '03', 'April': '04',
            'May': '05', 'June': '06', 'July': '07', 'August': '08',
            'September': '09', 'October': '10', 'November': '11', 'December': '12'
        };
        if (!month || !year) return '';
        return `${year}-${monthMap[month]}-01`;
    };

    const addHireToScenario = async () => {
        try {
            // Validate required fields
            if (!currentHire.roleTitle || !currentHire.team || !currentHire.level || !currentHire.directManager) {
                alert('Please fill in all required fields');
                return;
            }

            // Extract active scenario type and org from expanded scenario
            const [activeOrg, activeScenarioType] = (expandedScenario || '').split('-');

            // Find the active scenario using both ID and type/org as fallback
            let scenario = activeScenarioId ? 
                firebaseScenarios?.find(s => s.id === activeScenarioId) : null;
            
            // If not found by ID, try finding by type and org
            if (!scenario?.id && activeScenarioType && activeOrg) {
                scenario = findScenarioForType(activeScenarioType, activeOrg);
            }
            
            // Additional validation to ensure we have a valid scenario
            if (!scenario?.id) {
                console.error('Could not find valid scenario for hire:', { 
                    activeScenarioId, 
                    scenarioType: activeScenarioType,
                    org: activeOrg,
                    scenariosWithoutIds: firebaseScenarios?.filter(s => !s.id).length || 0
                });
                alert('Error: Could not find the active scenario. Please try refreshing the page.');
                return;
            }

            const newHire = {
                id: Date.now().toString(),
                roleTitle: currentHire.roleTitle,
                team: currentHire.team,
                function: currentHire.function,
                level: currentHire.level,
                directManager: currentHire.directManager,
                startDate: formatStartDate(currentHire.preferredStartMonth, currentHire.preferredStartYear),
                preferredStartMonth: currentHire.preferredStartMonth,
                preferredStartYear: currentHire.preferredStartYear,
                priority: currentHire.priority,
                totalCost: calculateBaseCompensation(currentHire),
                organization: scenario.organization,
                isVisible: true,
                isActive: true,
                status: 'Pending',
                approvalStatus: null,
                createdAt: new Date().toISOString(),
                jobId: currentHire.jobId
            };

            // Add exception reason only if it exists and we're in Exceptions scenario
            if (activeScenarioType === 'Exceptions' && currentHire.exceptionReason) {
                newHire.exceptionReason = currentHire.exceptionReason;
            }

            // Create a clean copy of the scenario without any undefined values
            const updatedScenario = {
                ...scenario,
                plannedHires: [...(scenario.plannedHires || []), newHire],
                lastModified: new Date().toISOString(),
                updatedAt: new Date().toISOString()
            };

            // Clean up any undefined values
            Object.keys(updatedScenario).forEach(key => {
                if (updatedScenario[key] === undefined || updatedScenario[key] === null) {
                    delete updatedScenario[key];
                }
            });

            // Ensure we have the scenario ID
            if (!updatedScenario.id) {
                updatedScenario.id = scenario.id;
            }

            await updateScenario(scenario.id, updatedScenario);

            // Reset the form
            setCurrentHire({
                roleTitle: '',
                team: '',
                function: '',
                level: '',
                directManager: '',
                preferredStartMonth: 'January',
                preferredStartYear: '2024',
                priority: 'Medium',
                exceptionReason: '',
                jobId: ''
            });
        } catch (error) {
            console.error('Error adding hire:', error);
            alert('Failed to add hire. Please try again.');
        }
    };

    const handleHireChange = (event) => {
        const { name, value } = event.target;
        setCurrentHire(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const removeHireFromScenario = async (hireId) => {
        const scenario = firebaseScenarios.find(s => s.id === activeScenarioId);
        if (scenario) {
            await updateScenario(scenario.id, {
                ...scenario,
                plannedHires: scenario.plannedHires.filter(hire => hire.id !== hireId),
                lastModified: new Date().toISOString()
            });
        }
    };

    const handleUpdateRole = async (updatedRole) => {
        const compensation = calculateBaseCompensation(updatedRole);
        const scenario = firebaseScenarios.find(s => s.id === activeScenarioId);
        
        if (!scenario) {
            console.error('No active scenario found with ID:', activeScenarioId);
            return;
        }
        
        // Create a deep copy of the scenario to preserve all existing data
        const updatedScenario = JSON.parse(JSON.stringify(scenario));
        
        // Update the specific hire while preserving all other data
        updatedScenario.plannedHires = updatedScenario.plannedHires.map(hire =>
            hire.id === updatedRole.id 
                ? { 
                    ...hire, // Preserve existing hire data
                    ...updatedRole, // Apply updates
                    totalCost: compensation,
                    organization: updatedRole.organization || hire.organization || scenario.organization // Preserve organization
                } 
                : hire
        );

        await updateScenario(scenario.id, updatedScenario);
    };

    // Data processing functions
    const processMonthlyHireData = (scenarios) => {
        if (!scenarios) return [];
        
        const monthlyData = {};
        
        // Initialize all months from Jan-24 to Dec-26
        const startDate = new Date(2024, 0, 1);  // Jan 2024
        const endDate = new Date(2026, 11, 31);  // Dec 2026
        
        for (let date = new Date(startDate); date <= endDate; date.setMonth(date.getMonth() + 1)) {
            const monthYear = `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
            monthlyData[monthYear] = { date: monthYear, hires: 0 };
        }

        scenarios.forEach(scenario => {
            scenario?.plannedHires?.forEach(hire => {
                const startDate = hire?.startDate;
                if (!startDate) return;

                const date = new Date(startDate);
                const monthYear = `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;

                if (monthlyData[monthYear]) {
                    monthlyData[monthYear].hires++;
                }
            });
        });

        return Object.values(monthlyData).sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA - dateB;
        });
    };

    const processCumulativeHireData = (scenarios) => {
        if (!scenarios) return [];
        
        const monthlyData = processMonthlyHireData(scenarios);
        let cumulative = 0;
        
        return monthlyData.map(month => ({
            date: month.date,
            hires: (cumulative += month.hires)
        }));
    };

    const processCumulativeCostData = (scenarios) => {
        if (!scenarios) return [];
        
        const monthlyCosts = {};
        
        // Initialize all months from Jan-24 to Dec-26
        const startDate = new Date(2024, 0, 1);  // Jan 2024
        const endDate = new Date(2026, 11, 31);  // Dec 2026
        
        for (let date = new Date(startDate); date <= endDate; date.setMonth(date.getMonth() + 1)) {
            const monthYear = `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
            monthlyCosts[monthYear] = { date: monthYear, cost: 0 };
        }

        scenarios.forEach(scenario => {
            scenario?.plannedHires?.forEach(hire => {
                const startDate = hire?.startDate;
                if (!startDate) return;

                const date = new Date(startDate);
                const monthYear = `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;

                if (monthlyCosts[monthYear]) {
                    // Divide annual cost by 12 to get monthly cost
                    monthlyCosts[monthYear].cost += (Number(hire?.totalCost) || 0) / 12;
                }
            });
        });

        const sortedMonths = Object.values(monthlyCosts).sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA - dateB;
        });

        let cumulative = 0;
        return sortedMonths.map(month => ({
            date: month.date,
            cost: (cumulative += month.cost)
        }));
    };

    // Constants
    const FUNCTIONS = [
        'Biz Ops & Strategy',
        'Content',
        'Customer Service',
        'Data Analytics',
        'Finance',
        'Graphic Design',
        'Marketing',
        'People',
        'Product Design',
        'Product Management',
        'Sales',
        'SEO',
        'Software Engineering',
        'User Research',
        'Workplace & Culture',
        'Customer Success',
        'RevOps',
        'IT Support'
    ];

    const LEVELS = [
        { id: 'ic1', display: 'IC1' },
        { id: 'ic2', display: 'IC2' },
        { id: 'ic3', display: 'IC3 / M3' },
        { id: 'ic4', display: 'IC4 / M4' },
        { id: 'ic5', display: 'IC5 / M5' },
        { id: 'ic6', display: 'IC6 / M6' },
        { id: 'l1', display: 'L1' },
        { id: 'l2', display: 'L2' },
        { id: 'l3', display: 'L3' },
        { id: 's1', display: 'S1' },
        { id: 's2', display: 'S2' },
        { id: 's3', display: 'S3' },
        { id: 's4', display: 'S4' },
        { id: 's5', display: 'S5' }
    ];

    // Utility functions
    const getQuarterLabel = (date) => {
        const quarterDate = new Date(date);
        const quarter = Math.floor(quarterDate.getMonth() / 3) + 1;
        const year = quarterDate.getFullYear().toString().slice(-2);
        return `Q${quarter}'${year}`;
    };

    const groupHiresByQuarter = (hires) => {
        const grouped = hires.reduce((acc, hire) => {
            const startDate = hire.startDate;
            if (!startDate) return acc;

            const quarterLabel = getQuarterLabel(startDate);
            if (!acc[quarterLabel]) {
                acc[quarterLabel] = [];
            }
            acc[quarterLabel].push(hire);
            return acc;
        }, {});

        return Object.entries(grouped).sort((a, b) => {
            const dateA = new Date(a[1][0].startDate);
            const dateB = new Date(b[1][0].startDate);
            return dateA - dateB;
        });
    };

    const normalizeFunction = (func) => {
        if (!func) return '';
        return func.trim().toLowerCase().replace(/\s+/g, '');
    };

    const getPotentialManagers = useMemo(() => {
        if (!employees) {
            return [];
        }
        
        const managers = employees
            .map(emp => ({
                id: emp.id,
                value: `${emp.firstName} ${emp.surname}`,
                label: `${emp.firstName} ${emp.surname}`,
                team: emp.team || 'No Team'
            }));

        const groupedManagers = managers.reduce((acc, manager) => {
            if (!acc[manager.team]) {
                acc[manager.team] = [];
            }
            acc[manager.team].push(manager);
            return acc;
        }, {});

        return Object.entries(groupedManagers).map(([team, managers]) => ({
            team,
            managers: managers.sort((a, b) => a.label.localeCompare(b.label))
        }));
    }, [employees]);

    // Firebase-specific handlers
    const handleBulkApprovalRequest = async (hires) => {
        if (!Array.isArray(hires) || !Array.isArray(firebaseScenarios)) {
            console.error('Invalid hires or scenarios array');
            return;
        }

        try {
            // Update localStorage first
            const savedStatuses = JSON.parse(localStorage.getItem('approvalStatuses') || '{}');
            
            // Process each hire
            hires.forEach(hire => {
                if (!hire || !hire.id) return;

                // Find the scenario containing this hire
                const scenario = firebaseScenarios.find(s => {
                    if (!s || !Array.isArray(s.plannedHires)) return false;
                    return s.plannedHires.some(h => h && h.id === hire.id);
                });

                if (!scenario) return;
                
                savedStatuses[hire.id] = {
                    status: 'Pending',
                    date: new Date().toISOString(),
                    approver: 'Not Assigned',
                    scenarioId: scenario.id,
                    scenarioType: scenario.name,
                    organization: scenario.organization
                };
            });
            
            localStorage.setItem('approvalStatuses', JSON.stringify(savedStatuses));

            // Update Firebase
            const scenarioUpdates = {};
            
            // Group hires by scenario
            hires.forEach(hire => {
                if (!hire || !hire.id) return;

                const scenario = firebaseScenarios.find(s => {
                    if (!s || !Array.isArray(s.plannedHires)) return false;
                    return s.plannedHires.some(h => h && h.id === hire.id);
                });

                if (!scenario || !scenario.id) return;

                if (!scenarioUpdates[scenario.id]) {
                    scenarioUpdates[scenario.id] = {
                        ...scenario,
                        plannedHires: [...(scenario.plannedHires || [])]
                    };
                }

                scenarioUpdates[scenario.id].plannedHires = scenarioUpdates[scenario.id].plannedHires.map(h =>
                    h && h.id === hire.id 
                        ? { 
                            ...h, 
                            approvalStatus: 'Pending',
                            approver: 'Not Assigned',
                            approvalDate: new Date().toISOString()
                          } 
                        : h
                );
            });

            // Update all affected scenarios
            const updatePromises = Object.entries(scenarioUpdates).map(([scenarioId, updatedScenario]) => 
                updateScenario(scenarioId, {
                    ...updatedScenario,
                    lastModified: new Date().toISOString()
                })
            );
            
            await Promise.all(updatePromises);

            // Trigger storage event to notify other components
            window.dispatchEvent(new Event('storage'));
        } catch (error) {
            console.error('Error in bulk approval request:', error);
            alert('Failed to send roles for approval. Please try again.');
        }
    };

    // Import/Export handlers
    const handleImport = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        reader.onload = async (e) => {
            try {
                const data = new Uint8Array(e.target.result);
                const workbook = read(data, { type: 'array' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = utils.sheet_to_json(worksheet, {
                    raw: false,
                    defval: '',
                    blankrows: false
                });

                if (jsonData.length === 0) {
                    throw new Error('No data found in the Excel file');
                }

                console.log('Imported data:', jsonData);

                const columnNames = Object.keys(jsonData[0]);
                console.log('Found columns:', columnNames);

                const findColumn = (possibleNames) => {
                    const found = columnNames.find(col =>
                        possibleNames.some(name =>
                            col.toLowerCase().replace(/[\s_-]+/g, '') === name.toLowerCase().replace(/[\s_-]+/g, '')
                        )
                    );
                    if (!found) {
                        console.warn(`Could not find column matching any of: ${possibleNames.join(', ')}`);
                    }
                    return found;
                };

                const organizationCol = findColumn(['Organization', 'organisation', 'org']);
                const scenarioTypeCol = findColumn(['Scenario Type', 'scenariotype', 'scenario']);
                const functionCol = findColumn(['Function', 'role function']);
                const levelCol = findColumn(['Level', 'job level', 'role level']);

                if (!organizationCol || !scenarioTypeCol) {
                    throw new Error(`Required columns not found. Looking for 'Organization' and 'Scenario Type'. Found columns: ${columnNames.join(', ')}`);
                }

                // Group the data by scenario type and organization first
                const groupedData = jsonData.reduce((acc, row) => {
                    const scenarioType = row[scenarioTypeCol];
                    const organization = row[organizationCol];
                    const key = `${organization}-${scenarioType}`;
                    
                    if (!acc[key]) {
                        acc[key] = {
                            scenarioType,
                            organization,
                            roles: []
                        };
                    }
                    
                    acc[key].roles.push(row);
                    return acc;
                }, {});

                let importedCount = 0;
                let skippedCount = 0;
                const skippedRows = [];

                // Process each group of roles
                for (const groupKey of Object.keys(groupedData)) {
                    const { scenarioType, organization, roles } = groupedData[groupKey];
                    
                    if (!scenarioType || !organization) {
                        console.warn(`Group ${groupKey} skipped: Missing scenario type or organization`);
                        skippedCount += roles.length;
                        roles.forEach((_, index) => {
                            skippedRows.push({ 
                                row: index + 1, 
                                reason: 'Missing scenario type or organization' 
                            });
                        });
                        continue;
                    }

                    try {
                        // Find existing scenario for this organization and type
                        const existingScenario = firebaseScenarios.find(s => 
                            s.name === scenarioType && s.organization === organization
                        );

                        // Process all roles for this scenario
                        const processedHires = roles.map(row => {
                            // Normalize function name if present
                            let normalizedFunction = '';
                            if (functionCol && row[functionCol]) {
                                const rawFunction = row[functionCol].trim();
                                normalizedFunction = FUNCTIONS.find(f => 
                                    f.toLowerCase() === rawFunction.toLowerCase() ||
                                    f.toLowerCase().replace(/[\s&-]+/g, '') === rawFunction.toLowerCase().replace(/[\s&-]+/g, '')
                                ) || rawFunction;
                            }

                            // Normalize level
                            let normalizedLevel = '';
                            if (levelCol && row[levelCol]) {
                                const rawLevel = row[levelCol].trim().toLowerCase();
                                
                                // Handle manager level mapping (M3-M6 to IC3-IC6)
                                const managerMapping = {
                                    'm3': 'ic3',
                                    'm4': 'ic4',
                                    'm5': 'ic5',
                                    'm6': 'ic6'
                                };
                                
                                // Check if it's a manager level first
                                if (managerMapping[rawLevel]) {
                                    normalizedLevel = managerMapping[rawLevel];
                                } else {
                                    // Try to match against LEVELS array
                                    const matchedLevel = LEVELS.find(l => 
                                        l.id.toLowerCase() === rawLevel ||
                                        l.display.toLowerCase() === rawLevel ||
                                        l.display.toLowerCase().replace(/\s+/g, '') === rawLevel.replace(/\s+/g, '') ||
                                        // Handle cases like "IC1" matching "ic1"
                                        l.id.toLowerCase().replace(/\s+/g, '') === rawLevel.replace(/\s+/g, '') ||
                                        // Handle cases where display includes both IC and M levels
                                        l.display.toLowerCase().split('/').some(part => 
                                            part.trim() === rawLevel
                                        )
                                    );
                                    normalizedLevel = matchedLevel ? matchedLevel.id : rawLevel;
                                }
                            }

                            return {
                                id: Date.now().toString() + Math.random().toString(36).substr(2, 9),
                                roleTitle: row['Role Title'] || '',
                                team: row['Team'] || '',
                                function: normalizedFunction || row[functionCol] || '',
                                level: normalizedLevel,
                                directManager: row['Direct Manager'] || '',
                                preferredStartMonth: row['Preferred Start Month'] || 'January',
                                preferredStartYear: row['Preferred Start Year'] || '2024',
                                priority: row['Priority'] || 'Medium',
                                organization: organization,
                                startDate: formatStartDate(
                                    row['Preferred Start Month'] || 'January',
                                    row['Preferred Start Year'] || '2024'
                                ),
                                jobId: row['Job ID'] || ''
                            };
                        });

                        if (existingScenario) {
                            // Update existing scenario with all new roles
                            await updateScenario(existingScenario.id, {
                                ...existingScenario,
                                plannedHires: [
                                    ...existingScenario.plannedHires,
                                    ...processedHires
                                ],
                                lastModified: new Date().toISOString()
                            });
                        } else {
                            // Create new scenario with all roles
                            const newScenario = {
                                name: scenarioType,
                                organization: organization,
                                description: `${scenarioType} scenario for ${organization}`,
                                plannedHires: processedHires,
                                createdAt: new Date().toISOString(),
                                lastModified: new Date().toISOString()
                            };
                            await createScenario(newScenario);
                        }
                        
                        importedCount += processedHires.length;
                    } catch (error) {
                        console.error(`Error processing group ${groupKey}:`, error);
                        skippedCount += roles.length;
                        roles.forEach((_, index) => {
                            skippedRows.push({ 
                                row: index + 1, 
                                reason: error.message 
                            });
                        });
                    }
                }

                event.target.value = '';

                const summary = `Successfully imported ${importedCount} roles.` + 
                    (skippedCount > 0 ? `\n\nSkipped ${skippedCount} rows:\n${skippedRows.map(
                        r => `Row ${r.row}: ${r.reason}`
                    ).join('\n')}` : '');

                alert(summary);

            } catch (error) {
                console.error('Error importing data:', error);
                alert(error.message || 'Error importing data. Please check the file format and try again.');
            }
        };
    };

    const handleExport = () => {
        if (!firebaseScenarios || !Array.isArray(firebaseScenarios)) {
            alert('No scenarios available to export');
            return;
        }

        // Safely collect all hire data
        const exportData = [];
        for (const scenario of firebaseScenarios) {
            if (!scenario || !Array.isArray(scenario.plannedHires)) continue;
            
            for (const hire of scenario.plannedHires) {
                if (!hire) continue;
                
                exportData.push({
                    'Role Title': hire.roleTitle || '',
                    'Team': hire.team || '',
                    'Function': hire.function || '',
                    'Level': hire.level || '',
                    'Direct Manager': hire.directManager || '',
                    'Preferred Start Month': hire.preferredStartMonth || '',
                    'Preferred Start Year': hire.preferredStartYear || '',
                    'Priority': hire.priority || '',
                    'Organization': scenario.organization || '',
                    'Scenario Type': scenario.name || '',
                    'Total Cost': hire.totalCost || 0,
                    'Exception Reason': hire.exceptionReason || '',
                    'Job ID': hire.jobId || ''
                });
            }
        }

        if (exportData.length === 0) {
            alert('No data to export');
            return;
        }

        try {
            const ws = utils.json_to_sheet(exportData);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Scenarios");
            writeFile(wb, "scenarios_data.xlsx");
        } catch (error) {
            console.error('Error exporting data:', error);
            alert('Failed to export data. Please try again.');
        }
    };

    const handleExportTemplate = () => {
        const templateHeaders = {
            'Role Title': '',
            'Team': '',
            'Function': '',
            'Level': '',
            'Direct Manager': '',
            'Preferred Start Month': '',
            'Preferred Start Year': '',
            'Priority': '',
            'Organization': '',
            'Scenario Type': '',
            'Job ID': ''
        };

        const ws = utils.json_to_sheet([
            templateHeaders,
            {
                'Preferred Start Month': 'January, February, March, April, May, June, July, August, September, October, November, December',
                'Preferred Start Year': '2024, 2025, 2026',
                'Priority': 'High, Medium, Low',
                'Scenario Type': 'Base Case, Bull Case, Bear Case, Exceptions',
                'Job ID': 'Job ID'
            }
        ]);

        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Template");
        writeFile(wb, "scenarios_template.xlsx");
    };

    // Effects
    useEffect(() => {
        if (error) {
            console.error('Error in ScenarioPlanning:', error);
        }
    }, [error]);

    // Computed values
    const filteredScenarios = getFilteredScenarios();
    const uniqueOrgs = getUniqueOrgs();

    const totalPlannedHires = filteredScenarios?.reduce((total, scenario) => 
        total + (scenario?.plannedHires?.length || 0), 0) || 0;

    const totalCostImpact = filteredScenarios?.reduce((total, scenario) => {
        if (!scenario?.plannedHires) return total;
        return total + scenario.plannedHires.reduce((hireTotal, hire) => 
            hireTotal + (Number(hire?.totalCost) || 0), 0);
    }, 0) || 0;

    const organizationsPlanning = (() => {
        if (!filteredScenarios || !Array.isArray(filteredScenarios)) {
            return uniqueOrgs?.length || 0;
        }

        const orgSet = new Set();
        for (const scenario of filteredScenarios) {
            if (scenario && scenario.organization) {
                orgSet.add(scenario.organization);
            }
        }
        return orgSet.size;
    })();

    const monthlyHireData = processMonthlyHireData(filteredScenarios || []);
    const cumulativeHireData = processCumulativeHireData(filteredScenarios || []);
    const cumulativeCostData = processCumulativeCostData(filteredScenarios || []);

    // Render scenario sections
    const renderScenarioSection = (scenarioType, org) => {
        const scenarioForType = findScenarioForType(scenarioType, org);
        
        return (
            <div key={scenarioType} className="scenario-type-section" data-type={scenarioType}>
                <div className="scenario-type-header"
                    onClick={() => {
                        const newExpandedScenario = expandedScenario === `${org}-${scenarioType}` ? null : `${org}-${scenarioType}`;
                        setExpandedScenario(newExpandedScenario);
                        
                        // Always set the activeScenarioId based on the current state
                        if (!newExpandedScenario) {
                            setActiveScenarioId(null);
                        } else {
                            const scenarioForType = findScenarioForType(scenarioType, org);
                            if (scenarioForType?.id) {
                                setActiveScenarioId(scenarioForType.id);
                            } else {
                                console.warn('Could not find scenario ID for:', { scenarioType, org });
                            }
                        }
                    }}
                >
                    <div className="scenario-type-info">
                        <h4>{scenarioType}</h4>
                        {scenarioForType && Array.isArray(scenarioForType.plannedHires) && (
                            <div className="scenario-stats">
                                <span className="stat-badge">
                                    {scenarioForType.plannedHires.length} hires
                                </span>
                                <span className="stat-badge">
                                    ${scenarioForType.plannedHires.reduce((total, hire) => 
                                        total + (Number(hire?.totalCost) || 0), 0).toLocaleString()}
                                </span>
                            </div>
                        )}
                    </div>
                    <button className={`expand-button ${expandedScenario === `${org}-${scenarioType}` ? 'expanded' : ''}`}>
                        ▼
                    </button>
                </div>

                {expandedScenario === `${org}-${scenarioType}` && (
                    <div className="scenario-content">
                        {scenarioForType ? (
                            <div className="scenario-details">
                                <div className="scenario-form">
                                    {(isPlanningOpen || scenarioType === 'Exceptions') && hasPermission('canCreateScenarios') && (
                                        <div className="planned-hires-section">
                                            <h4>Add New Hire</h4>
                                            <div className="hire-form">
                                                <div className="form-row">
                                                    {/* Row 1 */}
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="roleTitle"
                                                            className="title-field"
                                                            value={currentHire.roleTitle}
                                                            onChange={handleHireChange}
                                                            placeholder="Role Title"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="jobId"
                                                            className="jobid-field"
                                                            value={currentHire.jobId}
                                                            onChange={handleHireChange}
                                                            placeholder="Job ID"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="combobox-wrapper">
                                                            <input
                                                                list="team-options"
                                                                type="text"
                                                                name="team"
                                                                className="team-field"
                                                                value={currentHire.team}
                                                                onChange={handleHireChange}
                                                                placeholder="Team"
                                                            />
                                                            <datalist id="team-options">
                                                                {getUniqueTeams(employees).map(team => (
                                                                    <option key={team} value={team} />
                                                                ))}
                                                            </datalist>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    {/* Row 2 */}
                                                    <div className="form-group">
                                                        <select
                                                            name="function"
                                                            className="function-field"
                                                            value={currentHire.function}
                                                            onChange={handleHireChange}
                                                        >
                                                            <option value="">Function</option>
                                                            {FUNCTIONS.map(func => (
                                                                <option key={func} value={func}>{func}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <select
                                                            name="level"
                                                            className="level-field"
                                                            value={currentHire.level}
                                                            onChange={handleHireChange}
                                                        >
                                                            <option value="">Level</option>
                                                            {LEVELS.map(level => (
                                                                <option key={level.id} value={level.id}>{level.display}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <select
                                                            name="directManager"
                                                            className="manager-field"
                                                            value={currentHire.directManager}
                                                            onChange={handleHireChange}
                                                        >
                                                            <option value="">Select Manager</option>
                                                            {getPotentialManagers.map(group => (
                                                                <optgroup key={group.team} label={group.team}>
                                                                    {group.managers.map(manager => (
                                                                        <option key={manager.id} value={manager.value}>
                                                                            {manager.label}
                                                                        </option>
                                                                    ))}
                                                                </optgroup>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    {/* Row 3 */}
                                                    <div className="form-group">
                                                        <select
                                                            name="preferredStartMonth"
                                                            className="month-field"
                                                            value={currentHire.preferredStartMonth}
                                                            onChange={handleHireChange}
                                                        >
                                                            <option value="January">January</option>
                                                            <option value="February">February</option>
                                                            <option value="March">March</option>
                                                            <option value="April">April</option>
                                                            <option value="May">May</option>
                                                            <option value="June">June</option>
                                                            <option value="July">July</option>
                                                            <option value="August">August</option>
                                                            <option value="September">September</option>
                                                            <option value="October">October</option>
                                                            <option value="November">November</option>
                                                            <option value="December">December</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <select
                                                            name="preferredStartYear"
                                                            className="year-field"
                                                            value={currentHire.preferredStartYear}
                                                            onChange={handleHireChange}
                                                        >
                                                            <option value="2024">2024</option>
                                                            <option value="2025">2025</option>
                                                            <option value="2026">2026</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <select
                                                            name="priority"
                                                            className="priority-field"
                                                            value={currentHire.priority}
                                                            onChange={handleHireChange}
                                                        >
                                                            <option value="High">High</option>
                                                            <option value="Medium">Medium</option>
                                                            <option value="Low">Low</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {scenarioType === 'Exceptions' && (
                                                    <textarea
                                                        name="exceptionReason"
                                                        className="exception-reason-field"
                                                        value={currentHire.exceptionReason}
                                                        onChange={handleHireChange}
                                                        placeholder="Please provide the reason for this exception hire..."
                                                    />
                                                )}
                                                <button 
                                                    className="add-hire-button"
                                                    onClick={addHireToScenario}
                                                >
                                                    Add Hire
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    <div className="planned-hires-list">
                                        {scenarioForType.plannedHires && groupHiresByQuarter(scenarioForType.plannedHires).map(([quarter, quarterHires]) => (
                                            <div key={quarter} className="quarter-group">
                                                <div className="quarter-wrapper">
                                                    <div className="quarter-header">
                                                        <div className="quarter-divider">{quarter}</div>
                                                        {hasPermission('canCreateScenarios') && (
                                                            <button
                                                                onClick={() => handleBulkApprovalRequest(quarterHires)}
                                                                className="bulk-approval-button"
                                                                disabled={!isPlanningOpen || 
                                                                         quarterHires.every(hire => 
                                                                             localApprovalRequests[hire.id] || 
                                                                             hire.isCompleted || 
                                                                             recruitmentStatus[hire.id]?.status === 'Launched'
                                                                         )}
                                                            >
                                                                Send All for Approval
                                                            </button>
                                                        )}
                                                    </div>
                                                    {quarterHires.map(hire => (
                                                        <div key={hire.id} className="hire-summary-item">
                                                            <div className="hire-content">
                                                                <div className="hire-info">
                                                                    <span className="role-title">
                                                                        {hire.isCompleted ? (
                                                                            <span className="completed-hire">
                                                                                {hire.roleTitle}
                                                                            </span>
                                                                        ) : (
                                                                            hire.roleTitle
                                                                        )}
                                                                    </span>
                                                                    {scenarioType === 'Exceptions' && hire.exceptionReason && (
                                                                        <div className="exception-reason">
                                                                            <span className="reason-label">Exception Reason:</span>
                                                                            <span className="reason-text">{hire.exceptionReason}</span>
                                                                        </div>
                                                                    )}
                                                                    <div className="hire-details">
                                                                        {hire.jobId ? <span>Job ID: {hire.jobId}</span> : null}
                                                                        <span>{hire.team}</span>
                                                                        {hire.directManager && <span>Reports to {hire.directManager}</span>}
                                                                        <span>
                                                                            {hire.isCompleted ? 
                                                                                `Start Date: ${new Date(hire.startDate).toLocaleDateString()}` : 
                                                                                `${hire.preferredStartMonth} ${hire.preferredStartYear}`
                                                                            }
                                                                        </span>
                                                                        <span>
                                                                            {hire.isCompleted ? 
                                                                                `${hire.currency || '$'}${Number(hire.totalCost).toLocaleString()}` :
                                                                                `Expected Cost: $${Number(hire.totalCost).toLocaleString()}`
                                                                            }
                                                                        </span>
                                                                        {!hire.isCompleted && (
                                                                            <span className={`priority-dot ${hire.priority.toLowerCase()}`}>
                                                                                {hire.priority}
                                                                            </span>
                                                                        )}
                                                                        {hire.isCompleted && hire.location && (
                                                                            <span className="location">{hire.location}</span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="hire-actions">
                                                                    {hasPermission('canCreateScenarios') && renderApprovalButton(hire, scenarioType)}
                                                                    {hasPermission('canEditScenarios') && (
                                                                        <button
                                                                            className="edit-hire"
                                                                            onClick={() => {
                                                                                console.log('Edit clicked:', { hire, scenarioId: scenarioForType.id });
                                                                                setSelectedRole(hire);
                                                                                setIsModalOpen(true);
                                                                                setActiveScenarioId(scenarioForType.id);
                                                                            }}
                                                                            disabled={getApprovalStatus(hire.id) === 'Approved'}
                                                                        >
                                                                            Edit
                                                                        </button>
                                                                    )}
                                                                    {hasPermission('canDeleteScenarios') && (
                                                                        <button
                                                                            className="remove-hire"
                                                                            onClick={() => removeHireFromScenario(hire.id)}
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : hasPermission('canCreateScenarios') && (
                            <button 
                                className="create-scenario-btn"
                                onClick={() => initializeScenario(scenarioType, org)}
                            >
                                Initialize {scenarioType}
                            </button>
                        )}
                    </div>
                )}
            </div>
        );
    };

    // Get unique organizations from employees
    const organizations = getUniqueOrgs();

    // Handle role deletion
    const handleDeleteRole = (roleId) => {
        const updatedScenarios = scenarios.map(scenario => {
            const updatedHires = scenario.plannedHires.filter(hire => hire.id !== roleId);
            return {
                ...scenario,
                plannedHires: updatedHires
            };
        });
        onScenarioChange(updatedScenarios);
    };

    // Render
    if (error) {
        return (
            <div style={{ padding: '2rem', color: 'red' }}>
                <h2>Error in ScenarioPlanning</h2>
                <pre>{error.toString()}</pre>
                <button onClick={() => setError(null)}>Try Again</button>
            </div>
        );
    }

    return (
        <div className="scenario-planning" onScroll={handleScroll}>
            <div className="summary-block">
                <div className="header-row">
                    <h2>Total Headcount Plan</h2>
                    <div className="header-actions">
                        {hasPermission('canCreateScenarios') && (
                            <div className="planning-toggle">
                                <span className={`status-label ${isPlanningOpen ? 'open' : 'closed'}`}>
                                    Planning {isPlanningOpen ? 'Open' : 'Closed'}
                                </span>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        checked={isPlanningOpen}
                                        onChange={() => {
                                            const newValue = !isPlanningOpen;
                                            setIsPlanningOpen(newValue);
                                            localStorage.setItem('planningStatus', JSON.stringify(newValue));
                                        }}
                                    />
                                    <span className="slider"></span>
                                </label>
                            </div>
                        )}
                        {hasPermission('canCreateScenarios') && (
                            <div className="import-export-actions">
                                <input
                                    type="file"
                                    id="import-scenarios"
                                    style={{ display: 'none' }}
                                    onChange={handleImport}
                                    accept=".xlsx,.xls"
                                />
                                <button 
                                    className="import-button"
                                    onClick={() => document.getElementById('import-scenarios').click()}
                                >
                                    Import
                                </button>
                                <button 
                                    className="export-button"
                                    onClick={handleExport}
                                >
                                    Export
                                </button>
                                <button 
                                    className="template-button"
                                    onClick={handleExportTemplate}
                                >
                                    Template
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                <div className="chart-filters">
                    <div className="filter-group">
                        <select 
                            className="filter-select"
                            onChange={(e) => {
                                setSelectedOrg(e.target.value);
                                localStorage.setItem('selectedOrg', e.target.value);
                            }}
                            value={selectedOrg}
                        >
                            <option value="all">All Organizations</option>
                            {uniqueOrgs.map(org => (
                                <option key={org} value={org}>{org}</option>
                            ))}
                        </select>
                    </div>

                    <div className="filter-group">
                        <select 
                            className="filter-select scenario-filter"
                            onChange={(e) => {
                                setSelectedScenario(e.target.value);
                                localStorage.setItem('selectedScenario', e.target.value);
                            }}
                            value={selectedScenario}
                        >
                            <option value="Base Case" className="base-case">Base Case</option>
                            <option value="Bull Case" className="bull-case">Bull Case</option>
                            <option value="Bear Case" className="bear-case">Bear Case</option>
                            <option value="Exceptions" className="exceptions">Exceptions</option>
                        </select>
                    </div>
                </div>

                <div className="summary-stats">
                    <div className="stat-item">
                        <label>Total Planned Hires</label>
                        <span>{totalPlannedHires}</span>
                    </div>
                    <div className="stat-item">
                        <label>Total Cost Impact</label>
                        <span>${totalCostImpact?.toLocaleString()}</span>
                    </div>
                    <div className="stat-item">
                        <label>Organizations Planning</label>
                        <span>{organizationsPlanning}</span>
                    </div>
                </div>

                <div className="charts-container">
                    <div className="chart-wrapper monthly-hires">
                        <h3>Monthly New Hires</h3>
                        <ResponsiveContainer width="100%" height={200}>
                            <BarChart 
                                data={monthlyHireData}
                                margin={{ top: 20, right: 30, left: 40, bottom: 40 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis 
                                    dataKey="date" 
                                    angle={-45} 
                                    textAnchor="end" 
                                    height={60}
                                    interval={0}
                                />
                                <YAxis allowDecimals={false} />
                                <Tooltip />
                                <Bar dataKey="hires" fill="var(--accent)" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>

                    <div className="chart-wrapper cumulative-hires">
                        <h3>Cumulative Hires</h3>
                        <ResponsiveContainer width="100%" height={200}>
                            <BarChart 
                                data={cumulativeHireData}
                                margin={{ top: 20, right: 30, left: 40, bottom: 40 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis 
                                    dataKey="date" 
                                    angle={-45} 
                                    textAnchor="end" 
                                    height={60}
                                    interval={0}
                                />
                                <YAxis allowDecimals={false} />
                                <Tooltip />
                                <Bar dataKey="hires" fill="var(--success)" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>

                    <div className="chart-wrapper cumulative-cost">
                        <h3>Cumulative Cost Impact</h3>
                        <ResponsiveContainer width="100%" height={200}>
                            <BarChart 
                                data={cumulativeCostData}
                                margin={{ top: 20, right: 30, left: 60, bottom: 40 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis 
                                    dataKey="date" 
                                    angle={-45} 
                                    textAnchor="end" 
                                    height={60}
                                    interval={0}
                                />
                                <YAxis 
                                    tickFormatter={value => `$${(value/1000).toFixed(0)}k`}
                                    width={60}
                                />
                                <Tooltip 
                                    formatter={value => [`$${value.toLocaleString()}`, 'Cost']}
                                    labelFormatter={label => `${label}`}
                                />
                                <Bar dataKey="cost" fill="var(--warning)" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>

            <div className={`scenario-planning-content ${isCondensedView ? 'condensed-view' : ''}`}>
                <div className="org-chart-section">
                    <div className="section-header">
                        <button 
                            className="toggle-visibility"
                            onClick={() => setIsOrgChartVisible(!isOrgChartVisible)}
                        >
                            {isOrgChartVisible ? 'Hide' : 'Show'}
                        </button>
                        <h3>Future Org Chart</h3>
                        <div className="chart-controls">
                            <button 
                                className="toggle-visibility"
                                onClick={() => setShowPlannedHires(!showPlannedHires)}
                            >
                                {showPlannedHires ? 'Hide Planned Hires' : 'Show Planned Hires'}
                            </button>
                        </div>
                    </div>
                    {isOrgChartVisible && (
                        <div className="org-chart-section-content">
                            <ScenarioOrgChart
                                employees={employees}
                                scenarios={scenarios}
                                selectedScenario={selectedScenario}
                                onScenarioSelect={onScenarioChange}
                                showPlannedHires={showPlannedHires}
                                compensationRanges={compensationRanges}
                                exchangeRates={exchangeRates}
                                presentationCurrency={presentationCurrency}
                            />
                        </div>
                    )}
                </div>

                <div className="org-sections">
                    <UnallocatedRoles 
                        scenarios={firebaseScenarios}
                        expandedOrg={expandedOrg}
                        setExpandedOrg={setExpandedOrg}
                        onUpdateScenario={updateScenario}
                    />
                    {organizations.map(org => (
                        <div key={org} className="org-section">
                            <div 
                                className="org-header"
                                onClick={() => setExpandedOrg(expandedOrg === org ? null : org)}
                            >
                                <div className="org-title">
                                    <h3>{org}</h3>
                                    <div className="org-quick-stats">
                                        {['Base Case', 'Bear Case', 'Bull Case'].map(caseType => {
                                            const scenarioForType = firebaseScenarios?.find(s => {
                                                if (!s || typeof s !== 'object') return false;
                                                const isMatchingType = s.name === caseType;
                                                const isMatchingOrg = s.organization === org;
                                                
                                                // Only check plannedHires if we need to
                                                if (isMatchingType && !isMatchingOrg && Array.isArray(s.plannedHires)) {
                                                    return s.plannedHires.some(hire => 
                                                        hire && typeof hire === 'object' && 
                                                        (hire.organization || s.organization) === org
                                                    );
                                                }
                                                return isMatchingType && isMatchingOrg;
                                            });

                                            const totalCost = scenarioForType && Array.isArray(scenarioForType.plannedHires)
                                                ? scenarioForType.plannedHires.reduce((total, hire) => 
                                                    total + (hire && typeof hire === 'object' ? Number(hire.totalCost) || 0 : 0), 0)
                                                : 0;

                                            return (
                                                <div key={caseType} className="case-stat">
                                                    <span className="case-label">{caseType}: </span>
                                                    <span className="case-value">
                                                        ${totalCost.toLocaleString()}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <button className={`expand-button ${expandedOrg === org ? 'expanded' : ''}`}>
                                    ▼
                                </button>
                            </div>

                            {expandedOrg === org && (
                                <div className="org-content expanded">
                                    <div className="scenarios-list">
                                        {['Bear Case', 'Base Case', 'Bull Case', 'Exceptions'].map(scenarioType => {
                                            return renderScenarioSection(scenarioType, org);
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <ScenarioRoleModal
                isOpen={isModalOpen}
                onClose={() => {
                    console.log('Closing modal');
                    setIsModalOpen(false);
                    setSelectedRole(null);
                }}
                role={selectedRole}
                onUpdateRole={handleUpdateRole}
                onDeleteRole={handleDeleteRole}
                compensationRanges={compensationRanges}
                exchangeRates={exchangeRates}
                presentationCurrency={presentationCurrency}
                employees={employees}
            />
        </div>
    );
}

export default ScenarioPlanning;