import React, { useState, useEffect, useContext } from 'react';
import './TATeamModal.css';
import { FirebaseContext } from '../../contexts/FirebaseContext';
import { doc, collection, getDocs, setDoc, updateDoc, query, orderBy } from 'firebase/firestore';
import { db } from '../../config/firebase';

function TATeamModal({ isOpen, onClose }) {
  const { user, getOrganizationUsers } = useContext(FirebaseContext);
  const [activeTab, setActiveTab] = useState('team');
  const [taUsers, setTAUsers] = useState([]);
  const [stages, setStages] = useState([]);
  const [newStage, setNewStage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.orgId) return;

      try {
        // Fetch users with Talent Acquisition role
        const users = await getOrganizationUsers();
        const taUsersFiltered = users.filter(user => user.role === 'Talent Acquisition');
        setTAUsers(taUsersFiltered);

        // Fetch recruitment stages
        const stagesRef = collection(db, `organizations/${user.orgId}/recruitmentStages`);
        const stagesQuery = query(stagesRef, orderBy('order'));
        const stagesSnap = await getDocs(stagesQuery);
        const stagesData = stagesSnap.docs
          .filter(doc => !doc.data().deleted)
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

        // If no stages exist, create default stages
        if (stagesData.length === 0) {
          const defaultStages = [
            { id: '1', name: 'Not Started', order: 0, isDefault: true },
            { id: '2', name: 'Job Description', order: 1 },
            { id: '3', name: 'Sourcing', order: 2 },
            { id: '4', name: 'Interviewing', order: 3 },
            { id: '5', name: 'Offer Stage', order: 4 },
            { id: '6', name: 'Completed', order: 5, isDefault: true },
            { id: '7', name: 'Cancelled', order: 6, isDefault: true }
          ];

          for (const stage of defaultStages) {
            const stageRef = doc(db, `organizations/${user.orgId}/recruitmentStages`, stage.id);
            await setDoc(stageRef, stage);
          }
          setStages(defaultStages);
        } else {
          setStages(stagesData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [isOpen, user?.orgId, getOrganizationUsers]);

  const handleAddStage = async (e) => {
    e.preventDefault();
    if (!user?.orgId || !newStage) return;

    try {
      const stageId = Date.now().toString();
      const stageRef = doc(db, `organizations/${user.orgId}/recruitmentStages`, stageId);
      const stageData = {
        id: stageId,
        name: newStage,
        order: stages.length,
        isDefault: false,
        color: '#3b82f6' // Default color
      };

      await setDoc(stageRef, stageData);
      setStages(prev => [...prev, stageData]);
      setNewStage('');
    } catch (error) {
      console.error('Error adding stage:', error);
    }
  };

  const handleStageColorChange = async (stageId, newColor) => {
    if (!user?.orgId) return;

    try {
      const stageRef = doc(db, `organizations/${user.orgId}/recruitmentStages`, stageId);
      await updateDoc(stageRef, { color: newColor });
      
      setStages(prev => prev.map(stage => 
        stage.id === stageId ? { ...stage, color: newColor } : stage
      ));
    } catch (error) {
      console.error('Error updating stage color:', error);
    }
  };

  const handleMoveStage = async (stageId, direction) => {
    if (!user?.orgId) return;

    const currentIndex = stages.findIndex(s => s.id === stageId);
    if (
      (direction === 'up' && currentIndex === 0) || 
      (direction === 'down' && currentIndex === stages.length - 1)
    ) return;

    try {
      const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
      const updatedStages = [...stages];
      [updatedStages[currentIndex], updatedStages[newIndex]] = 
      [updatedStages[newIndex], updatedStages[currentIndex]];

      const reorderedStages = updatedStages.map((stage, index) => ({
        ...stage,
        order: index
      }));

      // Update order in Firebase
      for (const stage of reorderedStages) {
        const ref = doc(db, `organizations/${user.orgId}/recruitmentStages`, stage.id);
        await updateDoc(ref, { order: stage.order });
      }

      setStages(reorderedStages);
    } catch (error) {
      console.error('Error moving stage:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="ta-team-modal">
        <div className="modal-header">
          <h2>TA Settings</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>

        <div className="settings-tabs">
          <button 
            className={`tab-button ${activeTab === 'team' ? 'active' : ''}`}
            onClick={() => setActiveTab('team')}
          >
            Team Members
          </button>
          <button 
            className={`tab-button ${activeTab === 'stages' ? 'active' : ''}`}
            onClick={() => setActiveTab('stages')}
          >
            Recruitment Stages
          </button>
        </div>

        <div className="modal-content">
          {activeTab === 'team' ? (
            <div className="team-members-list">
              <h3>Talent Acquisition Team Members</h3>
              {taUsers.length === 0 ? (
                <p className="no-members">No Talent Acquisition team members found</p>
              ) : (
                <div className="members-grid">
                  {taUsers.map(member => (
                    <div key={member.uid} className="member-card">
                      <div className="member-info">
                        <h4>{member.displayName}</h4>
                        <p>{member.email}</p>
                        <p className="member-role">{member.role}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="stages-section">
              <form onSubmit={handleAddStage} className="add-stage-form">
                <div className="form-group">
                  <label>New Stage Name:</label>
                  <input
                    type="text"
                    value={newStage}
                    onChange={(e) => setNewStage(e.target.value)}
                    placeholder="Enter stage name"
                    required
                  />
                </div>
                <button type="submit" className="add-button">Add Stage</button>
              </form>

              <div className="stages-list">
                <h3>Current Stages</h3>
                {stages.map((stage, index) => (
                  <div key={stage.id} className="stage-item">
                    <div className="stage-info">
                      <span className="stage-name">{stage.name}</span>
                      <input
                        type="color"
                        value={stage.color || '#3b82f6'}
                        onChange={(e) => handleStageColorChange(stage.id, e.target.value)}
                        className="stage-color-picker"
                      />
                    </div>
                    <div className="stage-actions">
                      <button
                        onClick={() => handleMoveStage(stage.id, 'up')}
                        disabled={index === 0}
                        className="move-button"
                      >
                        ↑
                      </button>
                      <button
                        onClick={() => handleMoveStage(stage.id, 'down')}
                        disabled={index === stages.length - 1}
                        className="move-button"
                      >
                        ↓
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TATeamModal;