import React, { useState, useMemo } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Tree, TreeNode } from 'react-organizational-chart';
import EditEmployeeModal from '../../components/modals/EditEmployeeModal';
import './TeamViewer.css';
import { useRole } from '../../contexts/RoleContext';

const TeamViewer = ({ employees = [], scenarios = [], setEmployees }) => {
  const isLeaver = (employee) => {
    if (!employee.endDate) return false;
    return new Date(employee.endDate) <= new Date();
  };

  const isFutureLeaver = (employee) => {
    if (!employee.endDate) return false;
    return new Date(employee.endDate) > new Date();
  };

  const [selectedTeam, setSelectedTeam] = useState(null);
  const [collapsedOrgs, setCollapsedOrgs] = useState(new Set());
  const [showNewHires, setShowNewHires] = useState(() => {
    // Initialize from localStorage, default to false if not set
    return localStorage.getItem('teamViewerShowNewHires') === 'true';
  });
  const [selectedScenario, setSelectedScenario] = useState(() => {
    // Initialize from localStorage, default to empty string if not set
    return localStorage.getItem('teamViewerSelectedScenario') || '';
  });
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [hidePastLeavers, setHidePastLeavers] = useState(true);

  // Update localStorage when showNewHires changes
  const handleShowNewHiresToggle = () => {
    const newValue = !showNewHires;
    setShowNewHires(newValue);
    localStorage.setItem('teamViewerShowNewHires', newValue);
  };

  // Update localStorage when scenario changes
  const handleScenarioChange = (e) => {
    const newValue = e.target.value;
    setSelectedScenario(newValue);
    localStorage.setItem('teamViewerSelectedScenario', newValue);
  };

  const handleUpdateEmployee = async (updatedEmployee) => {
    const updatedEmployees = employees.map(emp => 
      emp.id === updatedEmployee.id ? updatedEmployee : emp
    );
    await setEmployees(updatedEmployees);
  };

  const handleDeleteEmployee = async (employeeId) => {
    const updatedEmployees = employees.filter(emp => emp.id !== employeeId);
    await setEmployees(updatedEmployees);
  };

  const isLeadershipTeam = (team) => {
    if (!team) return false;
    const normalizedTeam = team.toLowerCase();
    return ['lt', 'leadership', 'leadership team'].includes(normalizedTeam);
  };

  // Get unique scenario names
  const scenarioOptions = useMemo(() => {
    return [...new Set(scenarios.map(s => s.name))];
  }, [scenarios]);

  // Get recruitment status for a planned hire
  const getRecruitmentStatus = (hireId, scenarioName) => {
    const scenario = scenarios.find(s => s.name === scenarioName);
    if (!scenario) return null;

    const hire = scenario.plannedHires.find(h => h.id === hireId);
    return hire?.recruitmentStatus?.status || null;
  };

  // Combine current employees with planned hires from selected scenario
  const combinedEmployees = useMemo(() => {
    if (!showNewHires || !selectedScenario) {
      return employees;
    }

    const selectedScenarioData = scenarios.find(s => s.name === selectedScenario);
    if (!selectedScenarioData) {
      return employees;
    }

    const plannedHires = selectedScenarioData.plannedHires.map(hire => ({
      id: `planned-${hire.id}`,
      originalId: hire.id,
      firstName: hire.roleTitle ? hire.roleTitle.split(' ')[0] : 'New',
      surname: hire.roleTitle ? hire.roleTitle.split(' ').slice(1).join(' ') : 'Hire',
      title: hire.roleTitle || 'New Role',
      org: hire.organization || selectedScenarioData.organization || '',
      team: hire.team || '',
      contractType: 'Full Time',
      isPlannedHire: true,
      scenarioType: selectedScenario,
      recruitmentStatus: hire.recruitmentStatus?.status || null
    }));

    return [...employees, ...plannedHires];
  }, [employees, scenarios, showNewHires, selectedScenario]);

  const getLevelRank = (level) => {
    if (!level) return -1;
    
    // Extract numbers from the level string
    const numbers = level.match(/\d+/);
    if (numbers) {
      return parseInt(numbers[0]);
    }
    
    return -1;
  };

  // Helper function to get employee level from either field
  const getEmployeeLevel = (employee) => employee.jobLevel || employee.level;

  // Group employees by org and then by team
  const organizationGroups = useMemo(() => {
    const groups = {};
    const leadershipTeamMembers = [];

    // Filter employees based on hidePastLeavers setting
    const filteredEmployees = combinedEmployees.filter(emp => {
      if (hidePastLeavers && isLeaver(emp)) {
        return false;
      }
      return true;
    });

    filteredEmployees.forEach(emp => {
      // Handle Leadership Team members
      if (isLeadershipTeam(emp.team)) {
        leadershipTeamMembers.push(emp);
        return;
      }

      const org = emp.org || 'Unassigned Organization';
      const team = emp.team || 'Unassigned Team';
      
      if (!groups[org]) {
        groups[org] = {
          teams: {},
          employeeCount: 0,
          leader: null
        };
      }
      
      if (!groups[org].teams[team]) {
        groups[org].teams[team] = [];
      }
      
      groups[org].teams[team].push(emp);
      groups[org].employeeCount++;
    });

    // Find leaders for each org from Leadership Team
    leadershipTeamMembers.forEach(leader => {
      if (leader.org && groups[leader.org]) {
        if (!groups[leader.org].leader || 
            getLevelRank(getEmployeeLevel(leader)) > getLevelRank(getEmployeeLevel(groups[leader.org].leader))) {
          groups[leader.org].leader = leader;
        }
      }
    });

    // Add Leadership Team as a separate org if there are members
    if (leadershipTeamMembers.length > 0) {
      groups['Leadership Team'] = {
        isLeadershipTeam: true,
        members: leadershipTeamMembers.sort((a, b) => {
          const rankA = getLevelRank(getEmployeeLevel(a));
          const rankB = getLevelRank(getEmployeeLevel(b));
          
          if (rankA === rankB) {
            return (a.title || '').localeCompare(b.title || '');
          }
          
          return rankB - rankA;
        }),
        employeeCount: leadershipTeamMembers.length,
        leader: null
      };
    }

    return groups;
  }, [combinedEmployees, hidePastLeavers]);

  const toggleOrg = (orgName) => {
    setCollapsedOrgs(prev => {
      const newSet = new Set(prev);
      if (newSet.has(orgName)) {
        newSet.delete(orgName);
      } else {
        newSet.add(orgName);
      }
      return newSet;
    });
  };

  // Filter out past leavers if hidePastLeavers is true
  const filteredEmployees = useMemo(() => {
    return employees.filter(employee => {
      if (hidePastLeavers && isLeaver(employee)) {
        return false;
      }
      return true;
    });
  }, [employees, hidePastLeavers]);

  // Group employees by manager
  const employeesByManager = useMemo(() => {
    const groupedEmployees = {};
    filteredEmployees.forEach(employee => {
      const manager = employee.directManager || 'No Manager';
      if (!groupedEmployees[manager]) {
        groupedEmployees[manager] = [];
      }
      groupedEmployees[manager].push(employee);
    });
    return groupedEmployees;
  }, [filteredEmployees]);

  // Add helper function to get unique organizations from current employees
  const getUniqueOrganizations = (employees) => {
    // Filter out empty or null values and get unique organizations
    const orgs = [...new Set(employees.map(emp => emp.org).filter(org => org && org.trim()))];
    // Sort alphabetically
    return orgs.sort();
  };

  // Add helper function to get unique contract types
  const getUniqueContractTypes = () => {
    // Get unique contract types from existing employees
    const contractTypes = [...new Set(employees
      .map(emp => emp.contractType)
      .filter(type => type && type.trim())
    )];
    
    // Sort alphabetically
    return contractTypes.sort();
  };

  // Add helper function to get unique teams
  const getUniqueTeams = (employees) => {
    // Filter out empty or null values and get unique teams
    const teams = [...new Set(employees.map(emp => emp.team).filter(team => team && team.trim()))];
    // Sort alphabetically
    return teams.sort();
  };

  // Get unique organizations, teams, and contract types from current employees
  const uniqueOrganizations = useMemo(() => getUniqueOrganizations(employees), [employees]);
  const uniqueContractTypes = useMemo(() => getUniqueContractTypes(), [employees]);
  const uniqueTeams = useMemo(() => getUniqueTeams(employees), [employees]);

  const TeamNode = ({ employee }) => {
    const { hasPermission } = useRole();
    const nodeClasses = ['team-node'];
    
    // Add planned hire classes
    if (employee.isPlannedHire) {
      nodeClasses.push('planned-hire');
      if (employee.recruitmentStatus === 'Completed') {
        nodeClasses.push('completed-hire');
      }
    }

    // Add leaver classes
    if (isLeaver(employee)) {
      nodeClasses.push('leaver');
    } else if (isFutureLeaver(employee)) {
      nodeClasses.push('future-leaver');
    }
    
    const handleClick = () => {
      if (!employee.isPlannedHire && hasPermission('canEditEmployees')) {  // Only allow editing if user has permission
        setSelectedEmployee(employee);
        setIsEditModalOpen(true);
      }
    };

    return (
      <div 
        className={nodeClasses.join(' ')} 
        onClick={handleClick}
        role="button"
        tabIndex={0}
        style={{ cursor: hasPermission('canEditEmployees') && !employee.isPlannedHire ? 'pointer' : 'default' }}
      >
        <div className="team-node-header">
          <div className="header-left">
            {`${employee.firstName} ${employee.surname}`}
            <div className="node-level">{employee.jobLevel || employee.level || 'No Level'}</div>
          </div>
          {employee.isPlannedHire && (
            <div className="scenario-badge">
              {employee.scenarioType}
            </div>
          )}
        </div>
        <div className="team-node-body">
          <div className="node-title">{employee.title || 'No Title'}</div>
          <div className="node-function">{employee.function || 'No Function'}</div>
        </div>
      </div>
    );
  };

  const renderTeamGroup = (teamName, teamMembers) => {
    // Sort team members by level/seniority in descending order
    const sortedMembers = [...teamMembers].sort((a, b) => {
      const rankA = getLevelRank(getEmployeeLevel(a));
      const rankB = getLevelRank(getEmployeeLevel(b));
      
      if (rankA === rankB) {
        // If levels are equal, sort by title as a fallback
        return (a.title || '').localeCompare(b.title || '');
      }
      
      // Sort in descending order (higher ranks first)
      return rankB - rankA;
    });

    return (
      <div key={teamName} className="team-group">
        <h4 className="team-name">{teamName}</h4>
        <div className="team-members">
          {sortedMembers.map(member => (
            <TeamNode key={member.id} employee={member} />
          ))}
        </div>
      </div>
    );
  };

  const renderOrganizationGroup = (orgName, orgData) => {
    const isCollapsed = collapsedOrgs.has(orgName);
    const leader = orgData.leader;

    return (
      <div key={orgName} className="organization-group">
        <div 
          className="organization-header"
          onClick={() => toggleOrg(orgName)}
        >
          <div className="organization-header-content">
            <h3 className="organization-name">
              <span className="collapse-icon">{isCollapsed ? '►' : '▼'}</span>
              {orgName}
              {leader && (
                <span className="organization-leader">
                  Led by {leader.firstName} {leader.surname}, {leader.title}
                  {leader.level && <span className="leader-level">{leader.level}</span>}
                </span>
              )}
            </h3>
            <div className="organization-stats">
              {!orgData.isLeadershipTeam && (
                <span className="stat-label">Teams: {Object.keys(orgData.teams || {}).length}</span>
              )}
              <span className="stat-label">
                Employees: {orgData.isLeadershipTeam ? orgData.members.length : orgData.employeeCount}
              </span>
            </div>
          </div>
        </div>
        
        {!isCollapsed && (
          <div className={`organization-teams${orgData.isLeadershipTeam ? ' leadership-team' : ''}`}>
            {orgData.isLeadershipTeam ? (
              // Render Leadership Team members directly
              orgData.members.map(member => (
                <TeamNode key={member.id} employee={member} />
              ))
            ) : (
              // Render regular teams
              Object.entries(orgData.teams || {}).map(([teamName, teamMembers]) => 
                renderTeamGroup(teamName, teamMembers)
              )
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="team-viewer">
      <div className="team-viewer-header">
        <div className="header-top">
          <h2>Team Viewer</h2>
          <div className="view-controls">
            <div className="control-buttons">
              <button 
                className={`control-button ${showNewHires ? 'active' : ''}`}
                onClick={handleShowNewHiresToggle}
              >
                {showNewHires ? 'Hide New Hires' : 'Show New Hires'}
              </button>
              <button 
                className={`control-button ${hidePastLeavers ? 'active' : ''}`}
                onClick={() => setHidePastLeavers(!hidePastLeavers)}
              >
                {hidePastLeavers ? 'Show Past Leavers' : 'Hide Past Leavers'}
              </button>
            </div>
            {showNewHires && (
              <select 
                className="scenario-select"
                value={selectedScenario}
                onChange={handleScenarioChange}
              >
                <option value="">Select Scenario</option>
                {scenarioOptions.map(scenario => (
                  <option key={scenario} value={scenario}>
                    {scenario}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
        <div className="team-stats">
          <div className="stat-item">
            <span className="stat-label">Organizations</span>
            <span className="stat-value">{Object.keys(organizationGroups).length}</span>
          </div>
          <div className="stat-item">
            <span className="stat-label">Total Teams</span>
            <span className="stat-value">
              {Object.values(organizationGroups).reduce((acc, org) => 
                acc + (org.isLeadershipTeam ? 0 : Object.keys(org.teams || {}).length), 0
              )}
            </span>
          </div>
          <div className="stat-item">
            <span className="stat-label">Total Employees</span>
            <span className="stat-value">
              {Object.values(organizationGroups).reduce((acc, org) => 
                acc + (org.isLeadershipTeam ? org.members.length : org.employeeCount), 0
              )}
            </span>
          </div>
        </div>
      </div>

      <div className="organizations-container">
        {Object.entries(organizationGroups)
          .sort(([nameA], [nameB]) => {
            // Ensure Leadership Team appears first
            if (nameA === 'Leadership Team') return -1;
            if (nameB === 'Leadership Team') return 1;
            return nameA.localeCompare(nameB);
          })
          .map(([orgName, orgData]) => 
            renderOrganizationGroup(orgName, orgData)
          )}
      </div>

      <EditEmployeeModal
        isOpen={isEditModalOpen && selectedEmployee !== null}
        closeModal={() => {
          setIsEditModalOpen(false);
          setSelectedEmployee(null);
        }}
        employee={selectedEmployee || {}}
        onEditEmployee={handleUpdateEmployee}
        onDeleteEmployee={handleDeleteEmployee}
        existingEmployees={employees}
        organizationOptions={uniqueOrganizations}
        contractTypeOptions={uniqueContractTypes}
        teamOptions={uniqueTeams}
      />
    </div>
  );
};

export default TeamViewer; 