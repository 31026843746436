import React, { useState, useEffect, useMemo, useCallback, useRef, useContext } from 'react';
import './TalentAcquisition.css';
import TATeamModal from '../../components/modals/TATeamModal';
import TARoleModal from '../../components/modals/TARoleModal';
import OfferDetailsModal from '../../components/modals/OfferDetailsModal';
import CommentsPanel from '../../components/panels/CommentsPanel';
import { useFirebase } from '../../contexts/FirebaseContext';
import { doc, collection, setDoc, updateDoc, deleteDoc, serverTimestamp, onSnapshot, runTransaction, writeBatch, getDocs, getDoc, query, where, orderBy, deleteField } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { FirebaseContext } from '../../contexts/FirebaseContext';
import { useRole } from '../../contexts/RoleContext';

function TalentAcquisition() {
  const {
    user: currentUser,
    scenarios,
    employees,
    updateScenario,
    createEmployee: createEmployeeFirebase,
    getOrganizationUsers
  } = useContext(FirebaseContext);

  const { hasPermission } = useRole();

  const [approvedRoles, setApprovedRoles] = useState([]);
  const [hideCompleted, setHideCompleted] = useState(false);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [latestStatusData, setLatestStatusData] = useState({});
  const [taAssignments, setTaAssignments] = useState({});
  const [teamMembers, setTeamMembers] = useState([]);
  const [recruitmentStages, setRecruitmentStages] = useState([]);
  const [error, setError] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [backfills, setBackfills] = useState({});
  const unsubscribers = useRef([]);
  const isMounted = useRef(true);
  const [sortBy, setSortBy] = useState('none');
  const [activeFilter, setActiveFilter] = useState(null);
  const [isCommentsPanelOpen, setIsCommentsPanelOpen] = useState(false);
  const [selectedRoleForComments, setSelectedRoleForComments] = useState(null);

  useEffect(() => {
    const fetchRecruitmentStages = async () => {
      if (!currentUser?.orgId) return;

      try {
        const stagesRef = collection(db, `organizations/${currentUser.orgId}/recruitmentStages`);
        const stagesQuery = query(stagesRef, orderBy('order'));
        const stagesSnap = await getDocs(stagesQuery);
        const stagesData = stagesSnap.docs
          .filter(doc => !doc.data().deleted)
          .map(doc => doc.data());

        // If no stages exist, create default stages
        if (stagesData.length === 0) {
          const defaultStages = [
            { id: '1', name: 'Not Started', order: 0, isDefault: true, color: '#71717a' },
            { id: '2', name: 'Job Description', order: 1, color: '#f59e0b' },
            { id: '3', name: 'Sourcing', order: 2, color: '#f59e0b' },
            { id: '4', name: 'Interviewing', order: 3, color: '#8b5cf6' },
            { id: '5', name: 'Offer Stage', order: 4, color: '#8b5cf6' },
            { id: '6', name: 'Open', order: 5, color: '#3b82f6' },
            { id: '7', name: 'Completed', order: 6, isDefault: true, color: '#10b981' },
            { id: '8', name: 'Cancelled', order: 7, isDefault: true, color: '#ef4444' }
          ];

          for (const stage of defaultStages) {
            const stageRef = doc(db, `organizations/${currentUser.orgId}/recruitmentStages`, stage.id);
            await setDoc(stageRef, stage);
          }
          setRecruitmentStages(defaultStages);
        } else {
          // Sort stages by order
          const sortedStages = [...stagesData].sort((a, b) => a.order - b.order);
          setRecruitmentStages(sortedStages);
        }
      } catch (error) {
        console.error('Error fetching recruitment stages:', error);
        // Fallback to default stages if there's an error
        const defaultStages = [
          { id: '1', name: 'Not Started', order: 0, isDefault: true, color: '#71717a' },
          { id: '2', name: 'Job Description', order: 1, color: '#f59e0b' },
          { id: '3', name: 'Sourcing', order: 2, color: '#f59e0b' },
          { id: '4', name: 'Interviewing', order: 3, color: '#8b5cf6' },
          { id: '5', name: 'Offer Stage', order: 4, color: '#8b5cf6' },
          { id: '6', name: 'Open', order: 5, color: '#3b82f6' },
          { id: '7', name: 'Completed', order: 6, isDefault: true, color: '#10b981' },
          { id: '8', name: 'Cancelled', order: 7, isDefault: true, color: '#ef4444' }
        ];
        setRecruitmentStages(defaultStages);
      }
    };

    fetchRecruitmentStages();
  }, [currentUser?.orgId]);

  // Remove the debug logs for context values
  useEffect(() => {
    console.log('Current User:', currentUser);
    console.log('Scenarios:', scenarios);
    console.log('Employees:', employees);
  }, [currentUser, scenarios, employees]);

  // Remove the debug log for approved roles
  useEffect(() => {
    console.log('Approved Roles:', approvedRoles);
  }, [approvedRoles]);

  // Cleanup function for component unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
      if (unsubscribers.current) {
        unsubscribers.current.forEach(unsubscribe => {
          try {
            if (typeof unsubscribe === 'function') {
              unsubscribe();
            }
          } catch (error) {
            console.error('Error during unsubscribe:', error);
          }
        });
        unsubscribers.current = [];
      }
    };
  }, []);

  // Initialize data and set up listeners
  useEffect(() => {
    isMounted.current = true;

    if (!currentUser?.orgId) {
      return;
    }

    const setupListeners = async () => {
      try {
        // Clear any existing listeners
        if (unsubscribers.current) {
          unsubscribers.current.forEach(unsubscribe => {
            try {
              if (typeof unsubscribe === 'function') {
                unsubscribe();
              }
            } catch (error) {
              console.error('Error during unsubscribe:', error);
            }
          });
          unsubscribers.current = [];
        }

        if (!isMounted.current) return;

        // Set up TA assignments listener
        const assignmentsRef = collection(db, 'organizations', currentUser.orgId, 'taAssignments');
        const unsubAssignments = onSnapshot(assignmentsRef, (snapshot) => {
          if (!isMounted.current) return;
          const assignments = {};
          snapshot.forEach((doc) => {
            assignments[doc.id] = doc.data();
          });
          setTaAssignments(assignments);
        }, error => {
          console.error('Error in assignments listener:', error);
        });
        unsubscribers.current.push(unsubAssignments);

        // Fetch TA users from organization users
        const users = await getOrganizationUsers();
        const taUsers = users.filter(user => user.role === 'Talent Acquisition').map(user => ({
          id: user.uid,
          name: user.displayName,
          role: user.role
        }));
        setTeamMembers(taUsers);

        // Set up recruitment status listener
        const recruitmentStatusRef = collection(db, 'organizations', currentUser.orgId, 'recruitmentStatus');
        const unsubRecruitment = onSnapshot(recruitmentStatusRef, (snapshot) => {
          if (!isMounted.current) return;
          console.log('=== Recruitment Status Update ===');
          console.log('Number of documents:', snapshot.size);
          const statusData = {};
          snapshot.forEach((doc) => {
            statusData[doc.id] = doc.data();
            console.log('Document data:', { id: doc.id, data: doc.data() });
          });
          console.log('Updated latestStatusData:', statusData);
          setLatestStatusData(statusData);
        }, error => {
          console.error('Error in recruitment status listener:', error);
        });
        unsubscribers.current.push(unsubRecruitment);

        // Load user preferences
        const userPrefsRef = doc(db, 'organizations', currentUser.orgId, 'users', currentUser.uid, 'settings', 'preferences');
        const unsubPrefs = onSnapshot(userPrefsRef, (doc) => {
          if (!isMounted.current) return;
          if (doc.exists()) {
            setHideCompleted(doc.data().hideCompleted || false);
          }
        }, error => {
          console.error('Error in preferences listener:', error);
        });
        unsubscribers.current.push(unsubPrefs);

        // Set up backfills listener
        const backfillsRef = collection(db, 'organizations', currentUser.orgId, 'backfills');
        console.log('Setting up backfills listener for org:', currentUser.orgId);
        console.log('Backfills collection path:', `organizations/${currentUser.orgId}/backfills`);
        
        const unsubBackfills = onSnapshot(backfillsRef, (snapshot) => {
          if (!isMounted.current) return;

          console.log('=== Backfills Snapshot Details ===');
          console.log('Snapshot metadata:', {
            hasPendingWrites: snapshot.metadata.hasPendingWrites,
            fromCache: snapshot.metadata.fromCache,
            size: snapshot.size
          });
          
          const backfillData = {};
          snapshot.forEach((doc) => {
            const data = doc.data();
            console.log('=== Processing Backfill Document ===');
            console.log('Document ID:', doc.id);
            console.log('Document exists:', doc.exists());
            console.log('Document data:', {
              ...data,
              _raw: JSON.stringify(data, null, 2)
            });
            console.log('Approval status check:', {
              approvalStatus: data.approvalStatus,
              isApproved: data.approvalStatus === 'Approved' || data.approvalStatus === 'approved',
              isVisible: data.isVisible,
              isActive: data.isActive
            });

            backfillData[doc.id] = {
              id: doc.id,
              ...data
            };
          });

          console.log('=== Final Backfills State ===');
          console.log('Total backfills:', Object.keys(backfillData).length);
          console.log('Backfills by ID:', Object.keys(backfillData));
          console.log('Full backfills data:', JSON.stringify(backfillData, null, 2));
          
          setBackfills(backfillData);
        }, (error) => {
          console.error('=== Backfills Listener Error ===');
          console.error('Error details:', error);
          if (error.code === 'permission-denied') {
            console.error('Permission denied error details:', {
              uid: currentUser.uid,
              email: currentUser.email,
              orgId: currentUser.orgId,
              isAdmin: currentUser.isAdmin,
              status: currentUser.status,
              error: error.message,
              stack: error.stack
            });
          }
        });
        unsubscribers.current.push(unsubBackfills);

        if (isMounted.current) {
          setIsInitialized(true);
        }
      } catch (error) {
        console.error('Error setting up listeners:', error);
        if (isMounted.current) {
          setError('Failed to initialize data. Please refresh the page.');
        }
      }
    };

    setupListeners();

    return () => {
      isMounted.current = false;
      if (unsubscribers.current) {
        unsubscribers.current.forEach(unsubscribe => {
          try {
            if (typeof unsubscribe === 'function') {
              unsubscribe();
            }
          } catch (error) {
            console.error('Error during unsubscribe:', error);
          }
        });
        unsubscribers.current = [];
      }
    };
  }, [currentUser?.orgId, currentUser?.uid]);

  // Process approved backfills directly from backfills collection
  const approvedBackfills = useMemo(() => {
    console.log('=== Raw Backfills Data ===');
    Object.values(backfills).forEach(backfill => {
      console.log('Backfill document:', {
        id: backfill.id,
        fields: Object.keys(backfill),
        data: backfill,
        endDate: backfill.endDate,
        temporaryLeaveEnd: backfill.temporaryLeaveEnd,
        leaverDate: backfill.leaverDate
      });
    });

    return Object.values(backfills)
      .filter(backfill => {
        const isApproved = backfill.approvalStatus === 'Approved' || backfill.approvalStatus === 'approved';
        return isApproved && backfill.isVisible !== false && backfill.isActive !== false;
      })
      .map(backfill => {
        // Get the leaving date from the correct field - prioritize leaverDate
        const leavingDate = backfill.leaverDate || backfill.temporaryLeaveEnd || backfill.endDate;
        
        return {
          ...backfill,
          id: backfill.id,
          isBackfill: true,
          status: latestStatusData[backfill.id]?.status || 'Not Started',
          recruitmentStatus: latestStatusData[backfill.id]?.status || 'Not Started',
          isVisible: true,
          isActive: true,
          roleTitle: backfill.roleTitle,
          organization: backfill.organization || backfill.org,
          team: backfill.team,
          directManager: backfill.directManager,
          startDate: leavingDate,
          leaverDate: leavingDate,
          totalCost: backfill.totalCost || backfill.baseSalary || 0,
          type: backfill.isTemporaryCover ? 'temporary-cover' : 'backfill',
          currentEmployee: backfill.currentEmployee
        };
      });
  }, [backfills, latestStatusData]);

  // Process approved hires from scenarios
  const approvedHires = useMemo(() => {
    if (!scenarios) return [];
    
    return scenarios.flatMap(scenario => {
      if (!scenario?.plannedHires) return [];
      
      return scenario.plannedHires
        .filter(hire => hire.approvalStatus === 'Approved')
        .map(hire => ({
          ...hire,
          scenarioName: scenario.name || scenario.title,
          scenarioId: scenario.id,
          status: latestStatusData[hire.id]?.status || hire.status || 'Not Started',
          recruitmentStatus: latestStatusData[hire.id]?.status || hire.recruitmentStatus || 'Not Started',
          isVisible: true,
          isActive: true,
          isBackfill: false
        }));
    });
  }, [scenarios, latestStatusData]);

  // Combine approved hires and backfills
  useEffect(() => {
    const allRoles = [...approvedHires, ...approvedBackfills].filter(role => {
      return role.isVisible !== false && role.isActive !== false;
    });

    setApprovedRoles(allRoles);
  }, [approvedHires, approvedBackfills]);

  // Save user preferences
  const saveUserPreferences = useCallback(async (preferences) => {
    if (!currentUser?.orgId || !currentUser?.uid) return;

    try {
      const userPrefsRef = doc(db, 'organizations', currentUser.orgId, 'users', currentUser.uid, 'settings', 'preferences');
      await setDoc(userPrefsRef, {
        ...preferences,
        updatedAt: serverTimestamp(),
        updatedBy: currentUser.uid
      }, { merge: true });
    } catch (error) {
      console.error('Error saving preferences:', error);
      setError('Failed to save preferences.');
    }
  }, [currentUser?.orgId, currentUser?.uid]);

  // Add state for offer details modal
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const [offerRole, setOfferRole] = useState(null);

  // Modify handleStatusChange to use stage names
  const handleStatusChange = async (roleId, newStatus) => {
    const validStages = recruitmentStages.map(stage => stage.name);
    if (!validStages.includes(newStatus)) {
      console.error('Invalid recruitment status');
      return;
    }

    console.log('handleStatusChange called with:', { roleId, newStatus });
    if (!currentUser?.orgId) return;

    try {
      // If changing to Offer Stage, show the offer modal
      if (newStatus === 'Offer Stage') {
        console.log('Status is Offer Stage, finding role...');
        const role = approvedRoles.find(r => r.id === roleId);
        if (!role) {
          console.error('Role not found:', roleId);
          return;
        }
        console.log('Found role, opening offer modal:', role);
        setOfferRole(role);
        setIsOfferModalOpen(true);
        return;
      }

      // If changing to Completed status, show the modal
      if (newStatus === 'Completed') {
        console.log('Status is Completed, finding role...');
        const role = approvedRoles.find(r => r.id === roleId);
        if (!role) {
          console.error('Role not found:', roleId);
          return;
        }
        console.log('Found role, opening modal:', role);
        setSelectedRole({...role, recruitmentStatus: newStatus});
        setIsRoleModalOpen(true);
        return;
      }

      // Find the role to check if we're moving away from Completed
      const role = approvedRoles.find(r => r.id === roleId);
      if (role?.recruitmentStatus === 'Completed' && newStatus !== 'Completed') {
        try {
          // Create a regular timestamp for array updates
          const currentTimestamp = new Date().toISOString();

          // Delete the employee record if it exists
          if (role.employeeId) {
            const employeeRef = doc(db, `organizations/${currentUser.orgId}/employees/${role.employeeId}`);
            await deleteDoc(employeeRef);
          }

          // Update the recruitment status document
          const statusRef = doc(db, `organizations/${currentUser.orgId}/recruitmentStatus/${roleId}`);
          await updateDoc(statusRef, {
            recruitmentStatus: newStatus,
            status: newStatus,
            employeeId: deleteField(),
            hireName: deleteField(),
            hireSurname: deleteField(),
            baseSalary: deleteField(),
            location: deleteField(),
            confirmedStartDate: deleteField(),
            currency: deleteField(),
            contractType: deleteField(),
            linkedinProfile: deleteField(),
            convertedToEmployee: deleteField(),
            stipend: deleteField(),
            employerTax: deleteField(),
            nationalInsurance: deleteField(),
            commission: deleteField(),
            adminFees: deleteField(),
            otherRecurringPayments: deleteField(),
            updatedAt: serverTimestamp(),
            updatedBy: currentUser.uid,
            updatedByTab: 'talent'
          });

          // If this role is part of a scenario, update it there too
          if (role.scenarioId) {
            const scenarioRef = doc(db, `organizations/${currentUser.orgId}/scenarios/${role.scenarioId}`);
            const scenarioDoc = await getDoc(scenarioRef);
            
            if (scenarioDoc.exists()) {
              const scenarioData = scenarioDoc.data();
              const updatedHires = scenarioData.plannedHires.map(hire => 
                hire.id === roleId 
                  ? {
                      ...hire,
                      status: newStatus,
                      recruitmentStatus: newStatus,
                      employeeId: null,
                      hireName: null,
                      hireSurname: null,
                      baseSalary: null,
                      location: null,
                      confirmedStartDate: null,
                      currency: null,
                      contractType: null,
                      linkedinProfile: null,
                      convertedToEmployee: null,
                      stipend: null,
                      employerTax: null,
                      nationalInsurance: null,
                      commission: null,
                      adminFees: null,
                      otherRecurringPayments: null,
                      updatedAt: currentTimestamp
                    }
                  : hire
              );

              await updateDoc(scenarioRef, {
                plannedHires: updatedHires,
                lastModified: serverTimestamp()
              });
            }
          }

          // Update the local state
          setApprovedRoles(prevRoles => 
            prevRoles.map(r => 
              r.id === roleId 
                ? {
                    ...r,
                    recruitmentStatus: newStatus,
                    status: newStatus,
                    employeeId: null,
                    hireName: null,
                    hireSurname: null,
                    baseSalary: null,
                    location: null,
                    confirmedStartDate: null,
                    currency: null,
                    contractType: null,
                    linkedinProfile: null,
                    convertedToEmployee: null,
                    stipend: null,
                    employerTax: null,
                    nationalInsurance: null,
                    commission: null,
                    adminFees: null,
                    otherRecurringPayments: null
                  }
                : r
            )
          );

          return;
        } catch (error) {
          console.error('Error updating role status:', error);
          setError('Failed to update status. Please try again.');
          return;
        }
      }

      // For all other status changes, proceed with normal update
      await runTransaction(db, async (transaction) => {
        // First, do all reads
        const statusRef = doc(db, 'organizations', currentUser.orgId, 'recruitmentStatus', roleId);
        const statusDoc = await transaction.get(statusRef);

        // Find the role and its scenario
        const role = approvedRoles.find(r => r.id === roleId);
        let scenarioDoc = null;
        let scenarioRef = null;
        let backfillRef = null;
        let backfillDoc = null;

        if (role?.scenarioId) {
          scenarioRef = doc(db, 'organizations', currentUser.orgId, 'scenarios', role.scenarioId);
          scenarioDoc = await transaction.get(scenarioRef);
        }

        // If this is a backfill, get the backfill document
        if (role?.isBackfill) {
          backfillRef = doc(db, 'organizations', currentUser.orgId, 'backfills', roleId);
          backfillDoc = await transaction.get(backfillRef);
        }

        // Create a regular timestamp for array updates
        const currentTimestamp = new Date().toISOString();

        // Check if we're moving away from Offer Stage
        const isMovingFromOfferStage = role?.recruitmentStatus === 'Offer Stage' && newStatus !== 'Offer Stage';
        
        // Check if we're moving away from Completed status
        const isMovingFromCompleted = statusDoc.exists() && statusDoc.data().status === 'Completed' && newStatus !== 'Completed';

        // Get existing data to preserve fields that should not be removed
        const existingData = statusDoc.exists() ? statusDoc.data() : {};

        // Now do all writes
        const updateData = {
          id: roleId,
          status: newStatus,
          recruitmentStatus: newStatus,
          updatedAt: serverTimestamp(),
          updatedBy: currentUser.uid,
          updatedByTab: 'talent',
          isActive: true,
          isVisible: true,
          // Preserve employeeId if it exists and we're not moving from Completed status
          ...(existingData.employeeId && !isMovingFromCompleted && { employeeId: existingData.employeeId })
        };

        // If moving away from Offer Stage, remove offer-related fields
        if (isMovingFromOfferStage) {
          // Add fields to be removed with null values - only offer-specific fields
          updateData.offerCountry = null;
          updateData.offerCurrency = null;
          updateData.offerAmount = null;
          // Do not remove level, locationMultiplier, or frameworkCurrency as they are used for budget calculation
        }

        // If moving away from Completed status, remove employee-related fields and delete the employee document
        if (isMovingFromCompleted) {
          const statusData = statusDoc.data();
          if (statusData.employeeId) {
            // Delete the employee document
            const employeeRef = doc(db, 'organizations', currentUser.orgId, 'employees', statusData.employeeId);
            transaction.delete(employeeRef);

            // Remove employee-related fields
            updateData.employeeId = deleteField();
            updateData.hireName = deleteField();
            updateData.baseSalary = deleteField();
            updateData.location = deleteField();
            updateData.confirmedStartDate = deleteField();
            updateData.currency = deleteField();
            updateData.convertedToEmployee = deleteField();
          }
        }

        if (statusDoc.exists()) {
          if (isMovingFromOfferStage || isMovingFromCompleted) {
            // Use deleteField() to remove fields
            const fieldsToDelete = {
              ...(isMovingFromOfferStage && {
                offerCountry: deleteField(),
                offerCurrency: deleteField(),
                offerAmount: deleteField()
              }),
              ...(isMovingFromCompleted && {
                employeeId: deleteField(),
                hireName: deleteField(),
                baseSalary: deleteField(),
                location: deleteField(),
                confirmedStartDate: deleteField(),
                currency: deleteField(),
                convertedToEmployee: deleteField()
              })
            };
            transaction.update(statusRef, { ...updateData, ...fieldsToDelete });
          } else {
            transaction.update(statusRef, updateData);
          }
        } else {
          transaction.set(statusRef, updateData);
        }

        // Update scenario if it exists
        if (scenarioDoc && scenarioDoc.exists()) {
          const scenarioData = scenarioDoc.data();
          const updatedHires = scenarioData.plannedHires.map(hire => 
            hire.id === roleId 
              ? { 
                  ...hire, 
                  status: newStatus, 
                  recruitmentStatus: newStatus, 
                  updatedAt: currentTimestamp,
                  ...(isMovingFromOfferStage && {
                    offerCountry: null,
                    offerCurrency: null,
                    offerAmount: null
                  }),
                  ...(isMovingFromCompleted && {
                    employeeId: null,
                    hireName: null,
                    baseSalary: null,
                    location: null,
                    confirmedStartDate: null,
                    currency: null,
                    convertedToEmployee: null
                  })
                }
              : hire
          );

          transaction.update(scenarioRef, {
            plannedHires: updatedHires,
            lastModified: serverTimestamp()
          });
        }

        // Update backfill document if it exists
        if (backfillDoc && backfillDoc.exists()) {
          const backfillData = backfillDoc.data();
          transaction.update(backfillRef, {
            ...updateData,
            // Preserve employeeId if it exists in the backfill document
            ...(backfillData.employeeId && !isMovingFromCompleted && { employeeId: backfillData.employeeId }),
            updatedAt: serverTimestamp()
          });
        }
      });

      console.log('Successfully updated status for role:', roleId, 'to:', newStatus);
    } catch (error) {
      console.error('Error updating status:', error);
      setError('Failed to update status. Please try again.');
    }
  };

  // Add handler for offer details save
  const handleOfferDetailsSave = async (offerDetails) => {
    if (!currentUser?.orgId || !offerRole) return;

    try {
      await runTransaction(db, async (transaction) => {
        // First, do all reads
        const statusRef = doc(db, 'organizations', currentUser.orgId, 'recruitmentStatus', offerRole.id);
        const statusDoc = await transaction.get(statusRef);

        // Find the role and its scenario
        let scenarioDoc = null;
        let scenarioRef = null;
        let backfillRef = null;
        let backfillDoc = null;

        if (offerRole?.scenarioId) {
          scenarioRef = doc(db, 'organizations', currentUser.orgId, 'scenarios', offerRole.scenarioId);
          scenarioDoc = await transaction.get(scenarioRef);
        }

        // If this is a backfill, get the backfill document
        if (offerRole?.isBackfill) {
          backfillRef = doc(db, 'organizations', currentUser.orgId, 'backfills', offerRole.id);
          backfillDoc = await transaction.get(backfillRef);
        }

        // Create a regular timestamp for array updates
        const currentTimestamp = new Date();
        const timestampForArray = {
          seconds: Math.floor(currentTimestamp.getTime() / 1000),
          nanoseconds: (currentTimestamp.getTime() % 1000) * 1000000
        };

        // Now do all writes
        const updateData = {
          id: offerRole.id,
          status: 'Offer Stage',
          recruitmentStatus: 'Offer Stage',
          updatedAt: serverTimestamp(), // Keep serverTimestamp for top-level fields
          updatedBy: currentUser.uid,
          updatedByTab: 'talent',
          isActive: true,
          isVisible: true,
          level: offerDetails.level,
          offerCountry: offerDetails.country,
          offerCurrency: offerDetails.currency,
          offerAmount: offerDetails.offerAmount,
          compensationRange: offerDetails.compensationRange,
          locationMultiplier: offerDetails.locationMultiplier,
          frameworkCurrency: offerDetails.frameworkCurrency
        };

        // Create a version of updateData with the array-safe timestamp
        const arrayUpdateData = {
          ...updateData,
          updatedAt: timestampForArray // Use array-safe timestamp format
        };

        if (statusDoc.exists()) {
          transaction.update(statusRef, updateData);
        } else {
          transaction.set(statusRef, updateData);
        }

        // Update scenario if it exists
        if (scenarioDoc && scenarioDoc.exists()) {
          const scenarioData = scenarioDoc.data();
          const updatedHires = scenarioData.plannedHires.map(hire => 
            hire.id === offerRole.id 
              ? { 
                  ...hire, 
                  status: 'Offer Stage',
                  recruitmentStatus: 'Offer Stage',
                  ...arrayUpdateData // Use array-safe version of the data
                }
              : hire
          );

          transaction.update(scenarioRef, {
            plannedHires: updatedHires,
            lastModified: serverTimestamp() // Keep serverTimestamp for top-level fields
          });
        }

        // Update backfill document if it exists
        if (backfillDoc && backfillDoc.exists()) {
          transaction.update(backfillRef, {
            ...updateData,
            status: 'Offer Stage',
            recruitmentStatus: 'Offer Stage',
            updatedAt: serverTimestamp() // Keep serverTimestamp for top-level fields
          });
        }
      });

      setIsOfferModalOpen(false);
      setOfferRole(null);
      console.log('Successfully saved offer details');
    } catch (error) {
      console.error('Error saving offer details:', error);
      setError('Failed to save offer details. Please try again.');
    }
  };

  // Filter roles for display
  const filteredRoles = useMemo(() => {
    const filtered = approvedRoles.filter(role => {
      // Basic validation
      if (!role.roleTitle || !role.team || !role.organization) {
        return false;
      }

      // Filter completed roles if hideCompleted is true
      if (hideCompleted && role.recruitmentStatus === 'Completed') {
        return false;
      }

      // Filter by active status filter if set
      if (activeFilter && role.recruitmentStatus !== activeFilter) {
        return false;
      }
      
      return true;
    }).map(role => {
      // Get the status data for this role
      const statusData = latestStatusData[role.id] || {};
      
      return {
        ...role,
        // Use the scenario name from the status document if available
        scenarioName: statusData.scenarioName || role.scenarioName || role.scenarioTitle || 
          (role.scenarioId ? scenarios?.find(s => s.id === role.scenarioId)?.name : undefined)
      };
    });

    return filtered;
  }, [approvedRoles, hideCompleted, scenarios, latestStatusData, activeFilter]);

  // Helper function for role status
  const getRoleStatus = useCallback((role) => {
    if (!role) return { status: 'Not Started', statusClass: 'not-started' };
    const status = (role.recruitmentStatus || role.status || 'Not Started').toString();
    const statusClass = status.toLowerCase().replace(/\s+/g, '-');
    const stage = recruitmentStages.find(s => s.name === status);
    const color = stage?.color;
    return { status, statusClass, color };
  }, [recruitmentStages]);

  // Handle TA assignment
  const assignTAtoRole = useCallback(async (roleId, taId) => {
    if (!currentUser?.orgId) return;

    try {
      const assignmentRef = doc(db, 'organizations', currentUser.orgId, 'taAssignments', roleId);
      await setDoc(assignmentRef, {
        taId,
        assignedAt: serverTimestamp(),
        updatedBy: currentUser.uid
      });
    } catch (error) {
      console.error('Error assigning TA:', error);
      setError('Failed to assign TA. Please try again.');
    }
  }, [currentUser?.orgId, currentUser?.uid]);

  // Modal handlers
  const handleModalClose = useCallback(() => {
    setIsRoleModalOpen(false);
    setSelectedRole(null);
  }, []);

  const handleRoleUpdate = useCallback(async (updatedRole, newEmployee) => {
    if (!currentUser?.orgId) return;

    try {
      await runTransaction(db, async (transaction) => {
        const statusRef = doc(db, 'organizations', currentUser.orgId, 'recruitmentStatus', updatedRole.id);
        const statusDoc = await transaction.get(statusRef);
        
        // Handle scenario update only for non-backfill roles
        let scenarioDoc = null;
        if (!updatedRole.isBackfill && updatedRole.scenarioId) {
          const scenarioRef = doc(db, 'organizations', currentUser.orgId, 'scenarios', updatedRole.scenarioId);
          scenarioDoc = await transaction.get(scenarioRef);
          if (!scenarioDoc.exists()) {
            throw new Error('Scenario not found');
          }
        }

        // Create a regular timestamp for array updates
        const currentTimestamp = new Date();
        const timestampForArray = {
          seconds: Math.floor(currentTimestamp.getTime() / 1000),
          nanoseconds: (currentTimestamp.getTime() % 1000) * 1000000
        };

        // Get existing status data
        const existingStatus = statusDoc.exists() ? statusDoc.data() : {};

        // Determine if this is just a Job ID update
        const isJobIdUpdate = Object.keys(updatedRole).every(key => {
          if (key === 'jobId') return true;
          if (key === 'id') return true;
          if (key === 'isBackfill') return true;
          if (key === 'updatedAt') return true;
          if (key === 'updatedBy') return true;
          if (key === 'updatedByTab') return true;
          return updatedRole[key] === existingStatus[key];
        });

        // Base role update data
        const roleUpdate = {
          updatedAt: timestampForArray,
          updatedBy: currentUser.uid,
          updatedByTab: 'talent',
          isActive: true,
          // Preserve existing status if this is just a Job ID update
          status: isJobIdUpdate ? existingStatus.status : (updatedRole.status || existingStatus.status || 'Not Started'),
          recruitmentStatus: isJobIdUpdate ? existingStatus.recruitmentStatus : (updatedRole.recruitmentStatus || existingStatus.recruitmentStatus || 'Not Started'),
          // Only set these fields if this is not just a Job ID update
          ...(isJobIdUpdate ? {} : {
            convertedToEmployee: updatedRole.convertedToEmployee || existingStatus.convertedToEmployee || false,
            hireName: updatedRole.hireName || existingStatus.hireName || null,
            baseSalary: updatedRole.baseSalary || existingStatus.baseSalary || null,
            location: updatedRole.location || existingStatus.location || null,
            confirmedStartDate: updatedRole.confirmedStartDate || existingStatus.confirmedStartDate || null,
            currency: updatedRole.currency || existingStatus.currency || null
          })
        };

        // Add Job ID if it's being updated
        if (updatedRole.jobId !== undefined) {
          roleUpdate.jobId = updatedRole.jobId;
        }

        // Only add employeeId if newEmployee exists and has an id
        if (newEmployee?.id) {
          roleUpdate.employeeId = newEmployee.id;
        }

        // Update status document
        transaction.set(statusRef, {
          id: updatedRole.id,
          ...roleUpdate,
          updatedAt: serverTimestamp(),
          isVisible: true
        });

        // Update scenario only for non-backfill roles
        if (scenarioDoc) {
          const scenarioData = scenarioDoc.data();
          const updatedHires = scenarioData.plannedHires.map(hire => 
            hire.id === updatedRole.id 
              ? { ...hire, ...roleUpdate }
              : hire
          );

          const scenarioRef = doc(db, 'organizations', currentUser.orgId, 'scenarios', updatedRole.scenarioId);
          transaction.update(scenarioRef, {
            plannedHires: updatedHires,
            lastModified: serverTimestamp()
          });
        }

        // Update backfill document if this is a backfill
        if (updatedRole.isBackfill) {
          const backfillRef = doc(db, 'organizations', currentUser.orgId, 'backfills', updatedRole.id);
          transaction.update(backfillRef, {
            ...roleUpdate,
            updatedAt: serverTimestamp()
          });

          // If this is just a Job ID update, also update the original employee's record
          if (isJobIdUpdate && updatedRole.jobId !== undefined) {
            const originalEmployeeId = updatedRole.id.replace('backfill-', '');
            const employeeRef = doc(db, 'organizations', currentUser.orgId, 'employees', originalEmployeeId);
            const employeeDoc = await transaction.get(employeeRef);

            if (employeeDoc.exists()) {
              transaction.update(employeeRef, {
                jobId: updatedRole.jobId,
                updatedAt: serverTimestamp()
              });
            }
          }
        }

        // Create employee if provided (only for non-Job ID updates)
        if (!isJobIdUpdate && newEmployee?.id) {
          const employeeRef = doc(db, 'organizations', currentUser.orgId, 'employees', newEmployee.id);
          const employeeData = {
            id: newEmployee.id,
            firstName: newEmployee.firstName || '',
            surname: newEmployee.surname || '',
            title: updatedRole.roleTitle || '',
            org: updatedRole.organization || '',
            team: updatedRole.team || '',
            directManager: updatedRole.directManager || '',
            baseSalary: updatedRole.baseSalary || null,
            currency: updatedRole.currency || null,
            location: updatedRole.location || null,
            startDate: updatedRole.confirmedStartDate || null,
            isActive: true,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            orgId: currentUser.orgId,
            originalRoleId: updatedRole.id
          };

          // Only add optional fields if they exist and are not null/undefined
          if (updatedRole.function) {
            employeeData.function = updatedRole.function;
          }
          if (updatedRole.level) {
            employeeData.level = updatedRole.level;
          }
          if (updatedRole.jobId !== undefined) {
            employeeData.jobId = updatedRole.jobId;
          }

          transaction.set(employeeRef, employeeData);
        }
      });

      handleModalClose();
    } catch (error) {
      console.error('Error updating role:', error);
      setError('Failed to update role. Please try again.');
    }
  }, [currentUser?.orgId, currentUser?.uid, handleModalClose]);

  // Add this function to group and sort roles
  const groupedRoles = useMemo(() => {
    if (sortBy === 'none') {
      return { ungrouped: filteredRoles };
    }

    return filteredRoles.reduce((groups, role) => {
      const key = sortBy === 'status' 
        ? (role.recruitmentStatus || 'Not Started')
        : (role.organization || 'Uncategorized');
      
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(role);
      return groups;
    }, {});
  }, [filteredRoles, sortBy]);

  // Add this function to get sorted group keys
  const sortedGroupKeys = useMemo(() => {
    if (sortBy === 'none') return ['ungrouped'];
    return Object.keys(groupedRoles).sort((a, b) => {
      if (sortBy === 'status') {
        // Custom sort order for recruitment stages
        const stageOrder = recruitmentStages.reduce((order, stage, index) => {
          order[stage.name] = index;
          return order;
        }, {});
        return (stageOrder[a] || 999) - (stageOrder[b] || 999);
      }
      return a.localeCompare(b);
    });
  }, [groupedRoles, sortBy, recruitmentStages]);

  // Add this new function before the return statement
  const handleJobIdUpdate = useCallback(async (roleId, jobId) => {
    if (!currentUser?.orgId) return;

    try {
      await runTransaction(db, async (transaction) => {
        // Get the current role data
        const statusRef = doc(db, 'organizations', currentUser.orgId, 'recruitmentStatus', roleId);
        const statusDoc = await transaction.get(statusRef);
        const role = approvedRoles.find(r => r.id === roleId);

        if (!role) {
          throw new Error('Role not found');
        }

        // Update recruitment status document
        const updateData = {
          jobId: jobId,
          updatedAt: serverTimestamp(),
          updatedBy: currentUser.uid,
          updatedByTab: 'talent'
        };

        if (statusDoc.exists()) {
          transaction.update(statusRef, updateData);
        } else {
          transaction.set(statusRef, {
            id: roleId,
            ...updateData,
            status: role.status || 'Not Started',
            recruitmentStatus: role.recruitmentStatus || 'Not Started',
            isActive: true,
            isVisible: true
          });
        }

        // If this is a backfill, update the original employee's record
        if (role.isBackfill && role.currentEmployee) {
          // Extract employee ID from the backfill ID (remove 'backfill-' prefix)
          const originalEmployeeId = roleId.replace('backfill-', '');
          const employeeRef = doc(db, 'organizations', currentUser.orgId, 'employees', originalEmployeeId);
          const employeeDoc = await transaction.get(employeeRef);

          if (employeeDoc.exists()) {
            transaction.update(employeeRef, {
              jobId: jobId,
              updatedAt: serverTimestamp()
            });
          }
        }

        // If this is part of a scenario, update the scenario
        if (role.scenarioId) {
          const scenarioRef = doc(db, 'organizations', currentUser.orgId, 'scenarios', role.scenarioId);
          const scenarioDoc = await transaction.get(scenarioRef);

          if (scenarioDoc.exists()) {
            const scenarioData = scenarioDoc.data();
            const updatedHires = scenarioData.plannedHires.map(hire => 
              hire.id === roleId 
                ? { ...hire, jobId: jobId }
                : hire
            );

            transaction.update(scenarioRef, {
              plannedHires: updatedHires,
              lastModified: serverTimestamp()
            });
          }
        }

        // If this is a backfill, update the backfill document
        if (role.isBackfill) {
          const backfillRef = doc(db, 'organizations', currentUser.orgId, 'backfills', roleId);
          const backfillDoc = await transaction.get(backfillRef);

          if (backfillDoc.exists()) {
            transaction.update(backfillRef, {
              jobId: jobId,
              updatedAt: serverTimestamp()
            });
          }
        }
      });

      console.log('Successfully updated Job ID');
    } catch (error) {
      console.error('Error updating Job ID:', error);
      setError('Failed to update Job ID. Please try again.');
    }
  }, [currentUser?.orgId, currentUser?.uid, approvedRoles]);

  // Update effect to handle localStorage data for comments panel
  useEffect(() => {
    const shouldOpenComments = localStorage.getItem('openCommentsPanel');
    const roleId = localStorage.getItem('commentsPanelRoleId');
    const roleName = localStorage.getItem('commentsPanelRoleName');

    if (shouldOpenComments === 'true' && roleId && roleName && approvedRoles.length > 0) {
      // Find the role in our approved roles
      const role = approvedRoles.find(r => r.id === roleId);
      if (role) {
        setSelectedRoleForComments(role);
        setIsCommentsPanelOpen(true);
      }

      // Clear the localStorage
      localStorage.removeItem('openCommentsPanel');
      localStorage.removeItem('commentsPanelRoleId');
      localStorage.removeItem('commentsPanelRoleName');
    }
  }, [approvedRoles]); // Keep approvedRoles as dependency since we need it to find the role

  // Add this effect after the other useEffect hooks
  useEffect(() => {
    // Handler for the custom event
    const handleOpenCommentsPanel = (event) => {
      const { roleId, roleName } = event.detail;
      // Find the role in our approved roles
      const role = approvedRoles.find(r => r.id === roleId);
      if (role) {
        setSelectedRoleForComments(role);
        setIsCommentsPanelOpen(true);
      }
    };

    // Add event listener
    window.addEventListener('openCommentsPanelForRole', handleOpenCommentsPanel);

    // Cleanup
    return () => {
      window.removeEventListener('openCommentsPanelForRole', handleOpenCommentsPanel);
    };
  }, [approvedRoles]); // Add approvedRoles as dependency since we need it to find the role

  return (
    <div className="talent-acquisition">
      <div className="ta-header">
        <div className="ta-header-top">
          <h2>Talent Acquisition Pipeline</h2>
          <div className="header-buttons">
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="sort-select"
            >
              <option value="none">No Grouping</option>
              <option value="status">Group by Status</option>
              <option value="organization">Group by Organization</option>
            </select>
            <button 
              className="hide-completed-button"
              onClick={() => setHideCompleted(!hideCompleted)}
            >
              {hideCompleted ? 'Show Completed' : 'Hide Completed'}
            </button>
            {hasPermission('canEditTalentAcquisition') && (
              <button 
                className="manage-team-button"
                onClick={() => setIsTeamModalOpen(true)}
              >
                Manage TA Settings
              </button>
            )}
          </div>
        </div>

        <div className="pipeline-stats">
          <div className="stage-stats-grid">
            {recruitmentStages.map(stage => {
              const count = approvedRoles.filter(
                role => role.recruitmentStatus === stage.name
              ).length;
              const percentage = (count / approvedRoles.length) * 100 || 0;
              
              return (
                <div 
                  key={stage.id} 
                  className={`stage-stat-item ${activeFilter === stage.name ? 'active' : ''}`}
                  onClick={() => {
                    if (activeFilter === stage.name) {
                      setActiveFilter(null); // Clear filter if clicking the active one
                    } else {
                      setActiveFilter(stage.name); // Set new filter
                    }
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="stage-stat-label">{stage.name}</div>
                  <div className="stage-stat-count">{count}</div>
                  <div className="stage-stat-bar">
                    <div 
                      className="stage-stat-bar-fill"
                      style={{ 
                        width: `${approvedRoles.length ? (count / approvedRoles.length) * 100 : 0}%`,
                        background: stage.color
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="roles-list">
        {sortedGroupKeys.map(groupKey => (
          <div key={groupKey} className="role-group">
            {groupKey !== 'ungrouped' && (
              <h3 className="group-header">
                {groupKey}
                <span className="group-count">
                  ({groupedRoles[groupKey].length} {groupedRoles[groupKey].length === 1 ? 'role' : 'roles'})
                </span>
              </h3>
            )}
            {groupedRoles[groupKey].map(role => {
              const { statusClass, color } = getRoleStatus(role);
              return (
                <div 
                  key={role.id} 
                  className={`role-card ${statusClass}`}
                  onClick={(e) => {
                    // Don't trigger if clicking on controls or chat icon
                    if (e.target.closest('.role-controls') || e.target.closest('.chat-icon')) return;
                    if (e.target.tagName.toLowerCase() === 'select' || e.target.tagName.toLowerCase() === 'option') return;
                    setSelectedRole(role);
                    setIsRoleModalOpen(true);
                  }}
                  style={{ 
                    cursor: 'pointer',
                    borderLeftColor: color
                  }}
                >
                  <div className="role-header">
                    <div className="role-title">
                      <div className="title-and-badges">
                        <h3>{role.roleTitle}</h3>
                        <div className="status-badges">
                          {role.jobId && (
                            <span className="job-id">
                              ID: {role.jobId}
                            </span>
                          )}
                          <span className={`role-type ${role.type || (role.isBackfill ? 'backfill' : 'new-position')}`}>
                            {role.isBackfill ? 
                              (role.type === 'temporary-cover' ? 'Temporary Backfill' : 'Backfill') 
                              : 'New Position'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="role-controls">
                      {hasPermission('canEditTalentAcquisition') && (
                        <>
                          <select
                            value={role.recruitmentStatus || 'Not Started'}
                            onChange={(e) => handleStatusChange(role.id, e.target.value)}
                            className={`status-select ${statusClass}`}
                            style={{
                              color: color
                            }}
                          >
                            {recruitmentStages.map(stage => (
                              <option key={stage.id} value={stage.name}>{stage.name}</option>
                            ))}
                          </select>
                          <select
                            value={taAssignments[role.id]?.taId || ''}
                            onChange={(e) => assignTAtoRole(role.id, e.target.value)}
                            className="ta-select"
                          >
                            <option value="">Assign TA</option>
                            {teamMembers.map(member => (
                              <option key={member.id} value={member.id}>
                                {member.name} ({member.role})
                              </option>
                            ))}
                          </select>
                          <button
                            className="chat-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedRoleForComments(role);
                              setIsCommentsPanelOpen(true);
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                            </svg>
                          </button>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="role-details">
                    <div className="detail-group">
                      <label>Organization</label>
                      <span>{role.organization}</span>
                    </div>
                    {!role.isBackfill ? (
                      <>
                        <div className="detail-group">
                          <label>Target Start</label>
                          <span>{role.startDate ? new Date(role.startDate).toLocaleDateString() : 'Not set'}</span>
                        </div>
                        <div className="detail-group">
                          <label>Budget</label>
                          <span>{role.totalCost ? `USD ${Number(role.totalCost).toLocaleString()}` : 'Not set'}</span>
                        </div>
                        <div className="detail-group">
                          <label>Scenario</label>
                          <span>{role.scenarioName || 'N/A'}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="detail-group">
                          <label>Current Employee</label>
                          <span>{role.currentEmployee}</span>
                        </div>
                        <div className="detail-group">
                          <label>Leaving Date</label>
                          <span>{role.leaverDate ? new Date(role.leaverDate).toLocaleDateString() : 'Not set'}</span>
                        </div>
                        <div className="detail-group">
                          <label>Budget</label>
                          <span>{role.totalCost ? `USD ${Number(role.totalCost).toLocaleString()}` : 'Not set'}</span>
                        </div>
                      </>
                    )}
                    {taAssignments[role.id]?.taId && (
                      <div className="detail-group">
                        <label>Assigned TA</label>
                        <span>
                          {teamMembers.find(m => m.id === taAssignments[role.id]?.taId)?.name || 'Unknown'}
                        </span>
                      </div>
                    )}
                    {role.offerAmount && (
                      <div className="detail-group">
                        <label>Offer Amount</label>
                        <span>{role.offerCurrency} {Number(role.offerAmount).toLocaleString()}</span>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>

      {/* Add the CommentsPanel component */}
      <CommentsPanel
        isOpen={isCommentsPanelOpen}
        onClose={() => {
          setIsCommentsPanelOpen(false);
          setSelectedRoleForComments(null);
        }}
        roleId={selectedRoleForComments?.id}
        roleName={selectedRoleForComments?.roleTitle}
      />

      {/* Only render the modal when we have a selectedRole */}
      {selectedRole && (
        <TARoleModal
          isOpen={isRoleModalOpen}
          role={selectedRole}
          onClose={handleModalClose}
          onUpdateRole={handleRoleUpdate}
          recruitmentStatus={selectedRole.recruitmentStatus}
          employees={employees}
        />
      )}

      {isTeamModalOpen && (
        <TATeamModal
          isOpen={isTeamModalOpen}
          onClose={() => setIsTeamModalOpen(false)}
        />
      )}

      {offerRole && (
        <OfferDetailsModal
          isOpen={isOfferModalOpen}
          onClose={() => {
            setIsOfferModalOpen(false);
            setOfferRole(null);
          }}
          role={offerRole}
          onSave={handleOfferDetailsSave}
        />
      )}

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}
    </div>
  );
}

export default TalentAcquisition;