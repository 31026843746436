import React, { useState, useEffect } from 'react';
import './CompensationFramework.css';
import { useFirebase } from '../../contexts/FirebaseContext';
import { updateLocationTiers, getLocationTiers } from '../../services/firestore';

const DEFAULT_TIERS = {
  'Tier 1': 1.0,
  'Tier 2': 0.9,
  'Tier 3': 0.8,
  'Tier 4': 0.7,
  'Tier 5': 0.6
};

const LocationTiers = ({ employees = [] }) => {
  const { user } = useFirebase();
  const [locationTiers, setLocationTiers] = useState({
    tiers: DEFAULT_TIERS,
    assignments: {}
  });
  const [locationSearch, setLocationSearch] = useState('');

  // Load location tiers from Firebase when component mounts
  useEffect(() => {
    const loadTiers = async () => {
      if (!user?.orgId) return;

      try {
        const savedTiers = await getLocationTiers(user.orgId);
        if (savedTiers) {
          setLocationTiers({
            tiers: savedTiers.tiers || DEFAULT_TIERS,
            assignments: savedTiers.assignments || {}
          });
        }
      } catch (error) {
        console.error('Error loading location tiers:', error);
        setLocationTiers({
          tiers: DEFAULT_TIERS,
          assignments: {}
        });
      }
    };

    loadTiers();
  }, [user?.orgId]);

  // Get unique locations from employees
  const locations = [...new Set(employees.map(emp => emp.location).filter(Boolean))].sort();

  // Filter locations based on search
  const filteredLocations = locations.filter(location =>
    location.toLowerCase().includes(locationSearch.toLowerCase())
  );

  const handleTierMultiplierChange = async (tierName, value) => {
    if (!user?.orgId) return;

    const newTiers = {
      ...locationTiers,
      tiers: {
        ...locationTiers.tiers,
        [tierName]: parseFloat(value) || 0
      }
    };
    setLocationTiers(newTiers);
    
    try {
      await updateLocationTiers(user.orgId, newTiers);
    } catch (error) {
      console.error('Error saving tier multiplier:', error);
    }
  };

  const handleLocationAssignment = async (location, tier) => {
    if (!user?.orgId) return;

    const newTiers = {
      ...locationTiers,
      assignments: {
        ...locationTiers.assignments,
        [location]: tier
      }
    };
    setLocationTiers(newTiers);
    
    try {
      await updateLocationTiers(user.orgId, newTiers);
    } catch (error) {
      console.error('Error saving location assignment:', error);
    }
  };

  // Sort tiers in ascending order (Tier 1 first)
  const sortedTierEntries = locationTiers.tiers ? 
    Object.entries(locationTiers.tiers)
      .sort(([a], [b]) => {
        const aNum = parseInt(a.split(' ')[1]);
        const bNum = parseInt(b.split(' ')[1]);
        return aNum - bNum;
      }) : [];

  return (
    <div className="framework-section">
      <h2 className="compensation-header-title">Location Tiers</h2>
      <div className="location-tiers-container">
        <div className="tiers-grid">
          <h3>Tier Multipliers</h3>
          {sortedTierEntries.map(([tier, multiplier]) => (
            <div key={tier} className="tier-row">
              <span>{tier}</span>
              <input
                type="number"
                step="0.01"
                min="0"
                max="2"
                value={multiplier}
                onChange={(e) => handleTierMultiplierChange(tier, e.target.value)}
                placeholder="Multiplier"
              />
            </div>
          ))}
        </div>

        <div className="location-assignments">
          <h3>Location Assignments</h3>
          <div className="location-search">
            <input
              type="text"
              value={locationSearch}
              onChange={(e) => setLocationSearch(e.target.value)}
              placeholder="Search locations..."
              className="location-search-input"
            />
          </div>
          <div className="location-grid">
            {filteredLocations.map(location => (
              <div key={location} className="location-row">
                <span className="location-name">{location}</span>
                <select
                  value={locationTiers.assignments?.[location] || 'Tier 2'}
                  onChange={(e) => handleLocationAssignment(location, e.target.value)}
                >
                  {sortedTierEntries.map(([tier]) => (
                    <option key={tier} value={tier}>{tier}</option>
                  ))}
                </select>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationTiers; 