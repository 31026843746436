import React, { useMemo, useState } from 'react';
import { useFirebase } from '../../contexts/FirebaseContext';
import { doc, setDoc, deleteDoc, writeBatch, deleteField } from 'firebase/firestore';
import { db } from '../../config/firebase';
import './LeaversPage.css';
import LeaverDetailsModal from './LeaverDetailsModal';
import { useRole } from '../../contexts/RoleContext';

const LeaverCard = ({ employee }) => {
  const { updateEmployee, user } = useFirebase();
  const { hasPermission } = useRole();
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  // Map IC/M levels to standardized format
  const mapLevel = (level) => {
    if (!level) return null;
    const match = level.match(/^(IC|M)([3-6])$/i);
    if (match) {
      const number = match[2];
      return `ic${number}`;  // This maps to our standardized format (ic3 -> "IC3 / M3" in display)
    }
    return level.toLowerCase();  // Return as-is for other levels (l1, s1, etc)
  };

  // Handle both regular end dates and temporary leave dates
  const endDate = new Date(employee.temporaryLeave?.endDate || employee.endDate);
  const startDate = new Date(employee.temporaryLeave?.startDate || employee.startDate);
  const today = new Date();
  const timeUntilLeaving = endDate - today;
  const daysUntilLeaving = Math.ceil(timeUntilLeaving / (1000 * 60 * 60 * 24));
  const employmentDuration = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24 * 365));

  const getStatusBadge = () => {
    if (employee.temporaryLeave) {
      return <span className="status-badge temporary">Temporary Leave</span>;
    } else if (endDate > today) {
      return <span className="status-badge pending">Upcoming</span>;
    } else if (endDate < today) {
      return <span className="status-badge completed">Past</span>;
    }
    return null;
  };

  const handleBackfillUpdate = async (needsBackfill) => {
    if (!user?.orgId) {
      console.error('No organization ID available');
      return;
    }

    try {
      const batch = writeBatch(db);
      const backfillId = `backfill-${employee.id}`;

      // Update employee record
      const employeeRef = doc(db, `organizations/${user.orgId}/employees/${employee.id}`);
      if (needsBackfill === undefined) {
        // Reset the backfill status by removing the field
        batch.update(employeeRef, {
          needsBackfill: deleteField()
        });
        // Delete backfill record if it exists
        const backfillRef = doc(db, `organizations/${user.orgId}/backfills/${backfillId}`);
        batch.delete(backfillRef);
      } else {
        // Update employee record with new backfill status
        batch.update(employeeRef, {
          needsBackfill
        });

        // Handle backfill record
        const backfillRef = doc(db, `organizations/${user.orgId}/backfills/${backfillId}`);
        
        if (needsBackfill) {
          // Create backfill record
          const backfillData = {
            id: backfillId,
            roleTitle: employee.jobTitle || employee.title,
            organization: employee.org,
            team: employee.team,
            directManager: employee.directManager,
            currentEmployee: `${employee.firstName} ${employee.surname}`,
            leaverDate: employee.temporaryLeave?.endDate || employee.endDate,
            totalCost: employee.baseSalary || 0,
            isBackfill: true,
            isTemporaryCover: !!employee.temporaryLeave,
            status: 'Pending',
            approvalStatus: 'Pending',
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            createdBy: user.uid,
            orgId: user.orgId,
            isVisible: true,
            isActive: true,
            function: employee.function,
            level: mapLevel(employee.level || employee.jobLevel),
            // Add temporary leave details if applicable
            ...(employee.temporaryLeave && {
              temporaryLeaveType: employee.temporaryLeave.leaveType,
              temporaryLeaveStart: employee.temporaryLeave.startDate,
              temporaryLeaveEnd: employee.temporaryLeave.endDate
            })
          };
          batch.set(backfillRef, backfillData);
        } else {
          // Delete backfill record if it exists
          batch.delete(backfillRef);
        }
      }

      await batch.commit();
      console.log(`Successfully ${needsBackfill === undefined ? 'reset' : needsBackfill ? 'created' : 'removed'} backfill record`);
    } catch (error) {
      console.error('Error updating backfill status:', error);
      alert('Error updating backfill status. Please try again.');
    }
  };

  const handleCardClick = (e) => {
    // Don't open modal if clicking on backfill buttons
    if (e.target.closest('.backfill-actions')) {
      return;
    }
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="leaver-card" onClick={handleCardClick}>
        <div className="leaver-card-name">
          <h3>{`${employee.firstName} ${employee.surname}`}</h3>
          {employee.temporaryLeave && (
            <span className="leave-type">{employee.temporaryLeave.leaveType}</span>
          )}
        </div>
        <div className="leaver-card-header">
          <div className="header-content">
            <span className="leaver-title">{employee.title}</span>
            <span className="leaver-team">{employee.team || 'Not Specified'}</span>
          </div>
          {getStatusBadge()}
        </div>
        <div className="leaver-card-body">
          <div className="leaver-info">
            <span className="label">Organization</span>
            <span>{employee.org || 'Not Specified'}</span>
          </div>
          <div className="leaver-info">
            <span className="label">Manager</span>
            <span>{employee.directManager || 'Not Specified'}</span>
          </div>
          <div className="leaver-info">
            <span className="label">{employee.temporaryLeave ? 'Return Date' : 'End Date'}</span>
            <span>{endDate.toLocaleDateString()}</span>
          </div>
          {!employee.temporaryLeave && (
            <div className="leaver-info">
              <span className="label">Employment Duration</span>
              <span>{employmentDuration} {employmentDuration === 1 ? 'year' : 'years'}</span>
            </div>
          )}
          {daysUntilLeaving > 0 && (
            <div className="leaver-info highlight">
              <span className="label">{employee.temporaryLeave ? 'Time Until Return' : 'Time Until Leaving'}</span>
              <span>{daysUntilLeaving} {daysUntilLeaving === 1 ? 'day' : 'days'}</span>
            </div>
          )}
          {hasPermission('canSeeBackfillButtons') && (
            <div className="backfill-actions">
              {employee.needsBackfill === undefined ? (
                <>
                  <button
                    className="backfill-button needed"
                    onClick={() => handleBackfillUpdate(true)}
                  >
                    Backfill Needed
                  </button>
                  <button
                    className="backfill-button not-needed"
                    onClick={() => handleBackfillUpdate(false)}
                  >
                    No Backfill
                  </button>
                </>
              ) : (
                <div className="backfill-status">
                  <span className={`status-indicator ${employee.needsBackfill ? 'needed' : 'not-needed'}`}>
                    {employee.needsBackfill ? 'Backfill Needed' : 'No Backfill Required'}
                  </span>
                  <button
                    className="change-status-button"
                    onClick={() => handleBackfillUpdate(undefined)}
                  >
                    Change
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <LeaverDetailsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        employee={employee}
        user={user}
      />
    </>
  );
};

const LeaversPage = () => {
  const { employees } = useFirebase();
  const [sortBy, setSortBy] = useState('end-date'); // 'end-date', 'start-date', 'duration'
  
  const { upcomingLeavers, pastLeavers, temporaryLeavers } = useMemo(() => {
    const today = new Date();
    const upcoming = [];
    const past = [];
    const temporary = [];

    employees.forEach(employee => {
      // Check for temporary leave first
      if (employee.temporaryLeave && employee.temporaryLeave.status === 'temporary') {
        temporary.push(employee);
        return;
      }

      if (employee.endDate) {
        const endDate = new Date(employee.endDate);
        if (endDate > today) {
          upcoming.push(employee);
        } else {
          past.push(employee);
        }
      }
    });

    const sortFunctions = {
      'end-date': (a, b) => new Date(a.endDate || a.temporaryLeave?.endDate) - new Date(b.endDate || b.temporaryLeave?.endDate),
      'start-date': (a, b) => new Date(b.startDate || b.temporaryLeave?.startDate) - new Date(a.startDate || a.temporaryLeave?.startDate),
      'duration': (a, b) => {
        const durationA = new Date(a.endDate || a.temporaryLeave?.endDate) - new Date(a.startDate || a.temporaryLeave?.startDate);
        const durationB = new Date(b.endDate || b.temporaryLeave?.endDate) - new Date(b.startDate || b.temporaryLeave?.startDate);
        return durationB - durationA;
      }
    };

    upcoming.sort(sortFunctions[sortBy]);
    past.sort(sortFunctions[sortBy]);
    temporary.sort(sortFunctions[sortBy]);

    return { upcomingLeavers: upcoming, pastLeavers: past, temporaryLeavers: temporary };
  }, [employees, sortBy]);

  return (
    <div className="leavers-page">
      <div className="page-header">
        <h1>Employee Transitions</h1>
        <div className="sort-controls">
          <button 
            className={sortBy === 'end-date' ? 'active' : ''} 
            onClick={() => setSortBy('end-date')}
          >
            Sort by End Date
          </button>
          <button 
            className={sortBy === 'start-date' ? 'active' : ''} 
            onClick={() => setSortBy('start-date')}
          >
            Sort by Start Date
          </button>
          <button 
            className={sortBy === 'duration' ? 'active' : ''} 
            onClick={() => setSortBy('duration')}
          >
            Sort by Duration
          </button>
        </div>
      </div>
      
      <section className="leavers-section">
        <h2>Upcoming Transitions ({upcomingLeavers.length})</h2>
        {upcomingLeavers.length === 0 ? (
          <p className="no-data">No upcoming transitions</p>
        ) : (
          <div className="leaver-cards">
            {upcomingLeavers.map(employee => (
              <LeaverCard key={employee.id} employee={employee} />
            ))}
          </div>
        )}
      </section>

      <section className="leavers-section">
        <h2>Temporary Positions ({temporaryLeavers.length})</h2>
        {temporaryLeavers.length === 0 ? (
          <p className="no-data">No temporary positions</p>
        ) : (
          <div className="leaver-cards">
            {temporaryLeavers.map(employee => (
              <LeaverCard key={employee.id} employee={employee} />
            ))}
          </div>
        )}
      </section>

      <section className="leavers-section">
        <h2>Past Transitions ({pastLeavers.length})</h2>
        {pastLeavers.length === 0 ? (
          <p className="no-data">No past transitions</p>
        ) : (
          <div className="leaver-cards">
            {pastLeavers.map(employee => (
              <LeaverCard key={employee.id} employee={employee} />
            ))}
          </div>
        )}
      </section>
    </div>
  );
};

export default LeaversPage; 