import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import './CompensationFramework.css';
import { useFirebase } from '../../contexts/FirebaseContext';
import { updateCompensationRanges, getCompensationRanges } from '../../services/firestore';
import LocationTiers from './LocationTiers';

function CompensationFramework({ employees = [], scenarios = [] }) {
  const { user } = useFirebase();
  const [selectedFunction, setSelectedFunction] = useState('Software Engineering');
  const [compensationRanges, setCompensationRanges] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  
  const functions = [
    'Biz Ops & Strategy',
    'Content',
    'Customer Service',
    'Data Analytics',
    'Finance',
    'Graphic Design',
    'Marketing',
    'People',
    'Product Design',
    'Product Management',
    'Sales',
    'SEO',
    'Software Engineering',
    'User Research',
    'Workplace & Culture',
    'Customer Success',
    'RevOps',
    'IT Support'
  ];

  const levels = [
    { id: 'ic1', display: 'IC1' },
    { id: 'ic2', display: 'IC2' },
    { id: 'ic3', display: 'IC3 / M3' },
    { id: 'ic4', display: 'IC4 / M4' },
    { id: 'ic5', display: 'IC5 / M5' },
    { id: 'ic6', display: 'IC6 / M6' },
    { id: 'l1', display: 'L1' },
    { id: 'l2', display: 'L2' },
    { id: 'l3', display: 'L3' },
    { id: 's1', display: 'S1' },
    { id: 's2', display: 'S2' },
    { id: 's3', display: 'S3' },
    { id: 's4', display: 'S4' },
    { id: 's5', display: 'S5' }
  ];

  const currencies = ['USD', 'EUR', 'GBP', 'CHF', 'SEK', 'PHP', 'INR'];

  // Load compensation ranges from Firebase when component mounts or when user changes
  useEffect(() => {
    const loadRanges = async () => {
      if (!user?.orgId) return;

      try {
        const ranges = await getCompensationRanges(user.orgId);
        console.log('Loaded compensation ranges:', ranges);
        if (ranges) {
          setCompensationRanges(ranges);
          // Update selected currency if it exists in the ranges
          if (ranges.currency) {
            console.log('Setting currency from loaded ranges:', ranges.currency);
            setSelectedCurrency(ranges.currency);
          }
        }
      } catch (error) {
        console.error('Error loading compensation ranges:', error);
      }
    };

    loadRanges();
  }, [user?.orgId]);

  const handleRangeChange = async (level, type, value) => {
    if (!user?.orgId) return;

    const newRanges = {
      ...compensationRanges,
      [`${level}-${selectedFunction}`]: {
        ...compensationRanges[`${level}-${selectedFunction}`],
        [type]: parseFloat(value) || 0
      },
      currency: selectedCurrency
    };
    
    try {
      await updateCompensationRanges(user.orgId, newRanges);
      setCompensationRanges(newRanges);
    } catch (error) {
      console.error('Error saving compensation ranges to Firebase:', error);
    }
  };

  const handleCurrencyChange = async (event) => {
    if (!user?.orgId) return;

    const newCurrency = event.target.value;
    console.log('Currency change requested:', { from: selectedCurrency, to: newCurrency });

    const updatedRanges = {
      ...compensationRanges,
      currency: newCurrency
    };

    try {
      await updateCompensationRanges(user.orgId, updatedRanges);
      setCompensationRanges(updatedRanges);
      setSelectedCurrency(newCurrency);
      console.log('Successfully saved updated ranges with new currency');
    } catch (error) {
      console.error('Error saving currency change:', error);
    }
  };

  const handleImport = (event) => {
    if (!user?.orgId) return;

    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = async (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        const newRanges = {};
        const errors = [];

        jsonData.forEach((row, index) => {
          const rawFunctionName = row['Function'];
          const normalizedFunction = normalizeFunctionName(rawFunctionName);
          const levelName = row['Level']?.toLowerCase();
          
          if (levelName?.startsWith('m') && jsonData.some(r => 
            r['Function'] === rawFunctionName && 
            r['Level']?.toLowerCase() === levelName.replace('m', 'ic')
          )) {
            return;
          }

          const matchedFunction = functions.find(f => 
            normalizeFunctionName(f) === normalizedFunction
          );

          const matchedLevel = levels.find(l => 
            l.id.toLowerCase() === levelName
          );

          if (matchedFunction && matchedLevel) {
            const key = `${matchedLevel.id}-${matchedFunction}`;
            newRanges[key] = {
              min: parseFloat(row['Minimum']) || 0,
              mid: parseFloat(row['Mid']) || 0,
              max: parseFloat(row['Maximum']) || 0
            };
          } else {
            errors.push(`Row ${index + 1}: Could not match "${rawFunctionName}" and/or "${levelName}"`);
          }
        });

        if (Object.keys(newRanges).length > 0) {
          const rangesWithMetadata = {
            ...newRanges,
            currency: selectedCurrency
          };

          try {
            await updateCompensationRanges(user.orgId, rangesWithMetadata);
            setCompensationRanges(rangesWithMetadata);
            alert(`Import completed. ${Object.keys(newRanges).length} entries imported and saved.`);
          } catch (error) {
            console.error('Error saving compensation ranges to Firebase:', error);
            alert('Failed to save data. Please try again.');
          }
        } else {
          alert('No valid data could be imported. Please check the file format.');
        }

        if (errors.length > 0) {
          console.log('Import errors:', errors.join('\n'));
        }

      } catch (error) {
        console.error('Error importing data:', error);
        alert('Error importing data. Please check the file format and try again.');
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const normalizeFunctionName = (name) => {
    if (!name) return '';
    return name
      .trim()
      .toLowerCase()
      .replace(/\s+/g, ' ')
      .replace(/&/g, 'and')
      .replace(/\./g, '')
      .replace(/-/g, ' ');
  };

  const handleDownloadTemplate = () => {
    const template = {
      'Compensation Ranges': functions.flatMap(func =>
        levels.map(level => ({
          'Function': func,
          'Level': level.id.toUpperCase(),
          'Minimum': '',
          'Mid': '',
          'Maximum': ''
        }))
      )
    };

    const ws = XLSX.utils.json_to_sheet(template['Compensation Ranges']);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Compensation Ranges');
    XLSX.writeFile(wb, 'compensation_template.xlsx');
  };

  // Rest of the JSX remains the same
  return (
    <div className="compensation-framework">
      <div className="framework-section">
        <div className="compensation-header">
          <div className="compensation-header-content">
            <h2 className="compensation-header-title">Base Compensation Ranges</h2>
            <div className="compensation-currency-selector">
              <select
                value={selectedCurrency}
                onChange={handleCurrencyChange}
                className="compensation-currency-select"
              >
                {currencies.map(currency => (
                  <option key={currency} value={currency}>{currency}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="compensation-header-actions">
            <input
              type="file"
              id="import-compensation"
              accept=".xlsx,.xls"
              onChange={handleImport}
              style={{ display: 'none' }}
            />
            <button 
              className="compensation-action-button"
              onClick={() => document.getElementById('import-compensation').click()}
            >
              Import Data
            </button>
            <button 
              className="compensation-action-button"
              onClick={handleDownloadTemplate}
            >
              Download Template
            </button>
          </div>
        </div>
        
        <div className="function-tabs-wrapper">
          <div className="function-tabs-container">
            <div className="function-tabs">
              {functions.map(func => (
                <button
                  key={func}
                  className={`function-tab ${selectedFunction === func ? 'active' : ''}`}
                  onClick={() => setSelectedFunction(func)}
                >
                  {func}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="compensation-matrix">
          <table>
            <thead>
              <tr>
                <th>Level</th>
                <th>Minimum</th>
                <th>Mid-Point</th>
                <th>Maximum</th>
              </tr>
            </thead>
            <tbody>
              {levels.map(level => (
                <tr key={level.id}>
                  <td>{level.display}</td>
                  <td>
                    <input
                      type="number"
                      value={compensationRanges[`${level.id}-${selectedFunction}`]?.min || ''}
                      onChange={(e) => handleRangeChange(level.id, 'min', e.target.value)}
                      placeholder={`0 ${selectedCurrency}`}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={compensationRanges[`${level.id}-${selectedFunction}`]?.mid || ''}
                      onChange={(e) => handleRangeChange(level.id, 'mid', e.target.value)}
                      placeholder={`0 ${selectedCurrency}`}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={compensationRanges[`${level.id}-${selectedFunction}`]?.max || ''}
                      onChange={(e) => handleRangeChange(level.id, 'max', e.target.value)}
                      placeholder={`0 ${selectedCurrency}`}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <LocationTiers employees={employees} />
    </div>
  );
}

export default CompensationFramework;