import { Dialog } from '@headlessui/react';
import { useState, useRef, useEffect } from 'react';
import './AddEmployeeModal.css';

export default function AddEmployeeModal({ isOpen, closeModal, onAddEmployee, existingEmployees, organizationOptions, contractTypeOptions, teamOptions }) {
  const initialFormData = {
    firstName: '',
    surname: '',
    title: '',
    org: '',
    function: '',
    team: '',
    jobLevel: '',
    location: '',
    directManager: '',
    startDate: '',
    resignationDate: '',
    endDate: '',
    lastDayWorked: '',
    contractType: '',
    baseSalary: '',
    currency: 'USD',
    stipend: '',
    employerTax: '',
    nationalInsurance: '',
    commission: '',
    adminFees: '',
    otherRecurringPayments: '',
    employeeId: '',
    jobId: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [openCombobox, setOpenCombobox] = useState(null);

  // Add refs for each combobox
  const orgComboboxRef = useRef(null);
  const teamComboboxRef = useRef(null);
  const levelComboboxRef = useRef(null);
  const locationComboboxRef = useRef(null);
  const managerComboboxRef = useRef(null);
  const contractTypeComboboxRef = useRef(null);
  const currencyComboboxRef = useRef(null);

  // Add search states for comboboxes
  const [orgSearch, setOrgSearch] = useState('');
  const [teamSearch, setTeamSearch] = useState('');
  const [levelSearch, setLevelSearch] = useState('');
  const [locationSearch, setLocationSearch] = useState('');
  const [managerSearch, setManagerSearch] = useState('');
  const [contractTypeSearch, setContractTypeSearch] = useState('');
  const [currencySearch, setCurrencySearch] = useState('');

  // Define levels array
  const levels = [
    { id: 'ic1', display: 'IC1' },
    { id: 'ic2', display: 'IC2' },
    { id: 'ic3', display: 'IC3 / M3' },
    { id: 'ic4', display: 'IC4 / M4' },
    { id: 'ic5', display: 'IC5 / M5' },
    { id: 'ic6', display: 'IC6 / M6' },
    { id: 'l1', display: 'L1' },
    { id: 'l2', display: 'L2' },
    { id: 'l3', display: 'L3' },
    { id: 's1', display: 'S1' },
    { id: 's2', display: 'S2' },
    { id: 's3', display: 'S3' },
    { id: 's4', display: 'S4' },
    { id: 's5', display: 'S5' }
  ];

  // Define currencies array
  const currencies = ['USD', 'EUR', 'GBP', 'CHF', 'SEK', 'PHP', 'INR'];

  // Add click outside handler for comboboxes
  useEffect(() => {
    const handleClickOutside = (event) => {
      const refs = [
        orgComboboxRef,
        teamComboboxRef,
        levelComboboxRef,
        locationComboboxRef,
        managerComboboxRef,
        contractTypeComboboxRef,
        currencyComboboxRef
      ];
      
      if (!refs.some(ref => ref.current && ref.current.contains(event.target))) {
        setOpenCombobox(null);
        setOrgSearch('');
        setTeamSearch('');
        setLevelSearch('');
        setLocationSearch('');
        setManagerSearch('');
        setContractTypeSearch('');
        setCurrencySearch('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'First name is required';
    if (!formData.surname) newErrors.surname = 'Surname is required';
    if (!formData.title) newErrors.title = 'Title is required';
    if (!formData.org) newErrors.org = 'Organization is required';
    if (!formData.team) newErrors.team = 'Team is required';
    if (!formData.jobLevel) newErrors.jobLevel = 'Job level is required';
    if (!formData.location) newErrors.location = 'Location is required';
    if (!formData.startDate) newErrors.startDate = 'Start date is required';
    if (!formData.contractType) newErrors.contractType = 'Contract type is required';
    if (!formData.baseSalary) newErrors.baseSalary = 'Base salary is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const numericFields = {
        baseSalary: Number(formData.baseSalary),
        stipend: Number(formData.stipend) || 0,
        employeeTax: Number(formData.employerTax) || 0,
        nationalInsurance: Number(formData.nationalInsurance) || 0,
        commission: Number(formData.commission) || 0,
        platformFees: Number(formData.adminFees) || 0,
        otherRecurringPayments: Number(formData.otherRecurringPayments) || 0,
      };

      const submissionData = {
        ...formData,
        ...numericFields,
        level: formData.jobLevel,
        platformFees: numericFields.platformFees,
        employeeTax: numericFields.employeeTax,
      };

      Object.keys(submissionData).forEach(key => {
        if (submissionData[key] === undefined) {
          delete submissionData[key];
        }
      });

      onAddEmployee(submissionData);
      closeModal();
      setFormData(initialFormData);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  // Get unique locations from existing employees
  const locationOptions = [...new Set(existingEmployees.map(emp => emp.location).filter(Boolean))].sort();

  // Get manager options
  const managerOptions = [...new Set(existingEmployees.map(emp => 
    `${emp.firstName} ${emp.surname}`
  ))].sort();

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-panel">
        <h2 className="modal-title">Add New Employee</h2>
        <form onSubmit={handleSubmit} className="modal-form">
          <div className="form-grid">
            <div className="form-group">
              <label htmlFor="firstName">First Name*</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className={errors.firstName ? 'error' : ''}
              />
              {errors.firstName && <span className="error-message">{errors.firstName}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="surname">Surname*</label>
              <input
                type="text"
                id="surname"
                name="surname"
                value={formData.surname}
                onChange={handleChange}
                className={errors.surname ? 'error' : ''}
              />
              {errors.surname && <span className="error-message">{errors.surname}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="title">Title*</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className={errors.title ? 'error' : ''}
              />
              {errors.title && <span className="error-message">{errors.title}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="org">Organization*</label>
              <div className="combobox-container" ref={orgComboboxRef}>
                <input
                  type="text"
                  className="combobox-input"
                  value={orgSearch || formData.org}
                  onChange={(e) => {
                    setOrgSearch(e.target.value);
                    setFormData(prev => ({ ...prev, org: e.target.value }));
                  }}
                  onFocus={() => {
                    setOpenCombobox('org');
                    setOrgSearch(formData.org);
                  }}
                  placeholder="Select organization..."
                  required
                />
                {openCombobox === 'org' && (
                  <div className="combobox-options">
                    {organizationOptions
                      .filter(org => org.toLowerCase().includes((orgSearch || '').toLowerCase()))
                      .map(org => (
                        <div
                          key={org}
                          className="combobox-option"
                          onClick={() => {
                            setFormData(prev => ({ ...prev, org }));
                            setOrgSearch('');
                            setOpenCombobox(null);
                          }}
                        >
                          {org}
                        </div>
                      ))}
                  </div>
                )}
              </div>
              {errors.org && <span className="error-message">{errors.org}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="function">Function</label>
              <input
                type="text"
                id="function"
                name="function"
                value={formData.function}
                onChange={handleChange}
                placeholder="Enter function"
              />
            </div>

            <div className="form-group">
              <label htmlFor="jobLevel">Job Level*</label>
              <div className="combobox-container" ref={levelComboboxRef}>
                <input
                  type="text"
                  className="combobox-input"
                  value={levelSearch || formData.jobLevel}
                  onChange={(e) => {
                    setLevelSearch(e.target.value);
                    setFormData(prev => ({ ...prev, jobLevel: e.target.value }));
                  }}
                  onFocus={() => {
                    setOpenCombobox('level');
                    setLevelSearch(formData.jobLevel);
                  }}
                  placeholder="Select level..."
                  required
                />
                {openCombobox === 'level' && (
                  <div className="combobox-options">
                    {levels
                      .filter(level => level.display.toLowerCase().includes((levelSearch || '').toLowerCase()))
                      .map(level => (
                        <div
                          key={level.id}
                          className="combobox-option"
                          onClick={() => {
                            setFormData(prev => ({ ...prev, jobLevel: level.id }));
                            setLevelSearch('');
                            setOpenCombobox(null);
                          }}
                        >
                          {level.display}
                        </div>
                      ))}
                  </div>
                )}
              </div>
              {errors.jobLevel && <span className="error-message">{errors.jobLevel}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="team">Team*</label>
              <div className="combobox-container" ref={teamComboboxRef}>
                <input
                  type="text"
                  className="combobox-input"
                  value={teamSearch || formData.team}
                  onChange={(e) => {
                    setTeamSearch(e.target.value);
                    setFormData(prev => ({ ...prev, team: e.target.value }));
                  }}
                  onFocus={() => {
                    setOpenCombobox('team');
                    setTeamSearch(formData.team);
                  }}
                  placeholder="Select or enter team..."
                  required
                />
                {openCombobox === 'team' && (
                  <div className="combobox-options">
                    {teamOptions
                      .filter(team => team.toLowerCase().includes((teamSearch || '').toLowerCase()))
                      .map(team => (
                        <div
                          key={team}
                          className="combobox-option"
                          onClick={() => {
                            setFormData(prev => ({ ...prev, team }));
                            setTeamSearch('');
                            setOpenCombobox(null);
                          }}
                        >
                          {team}
                        </div>
                      ))}
                  </div>
                )}
              </div>
              {errors.team && <span className="error-message">{errors.team}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="location">Location*</label>
              <div className="combobox-container" ref={locationComboboxRef}>
                <input
                  type="text"
                  className="combobox-input"
                  value={locationSearch || formData.location}
                  onChange={(e) => {
                    setLocationSearch(e.target.value);
                    setFormData(prev => ({ ...prev, location: e.target.value }));
                  }}
                  onFocus={() => {
                    setOpenCombobox('location');
                    setLocationSearch(formData.location);
                  }}
                  placeholder="Select or enter location..."
                  required
                />
                {openCombobox === 'location' && (
                  <div className="combobox-options">
                    {locationOptions
                      .filter(location => location.toLowerCase().includes((locationSearch || '').toLowerCase()))
                      .map(location => (
                        <div
                          key={location}
                          className="combobox-option"
                          onClick={() => {
                            setFormData(prev => ({ ...prev, location }));
                            setLocationSearch('');
                            setOpenCombobox(null);
                          }}
                        >
                          {location}
                        </div>
                      ))}
                  </div>
                )}
              </div>
              {errors.location && <span className="error-message">{errors.location}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="directManager">Manager</label>
              <div className="combobox-container" ref={managerComboboxRef}>
                <input
                  type="text"
                  className="combobox-input"
                  value={managerSearch || formData.directManager}
                  onChange={(e) => {
                    setManagerSearch(e.target.value);
                    setFormData(prev => ({ ...prev, directManager: e.target.value }));
                  }}
                  onFocus={() => {
                    setOpenCombobox('manager');
                    setManagerSearch(formData.directManager);
                  }}
                  placeholder="Select manager..."
                />
                {openCombobox === 'manager' && (
                  <div className="combobox-options">
                    {managerOptions
                      .filter(manager => manager.toLowerCase().includes((managerSearch || '').toLowerCase()))
                      .map(manager => (
                        <div
                          key={manager}
                          className="combobox-option"
                          onClick={() => {
                            setFormData(prev => ({ ...prev, directManager: manager }));
                            setManagerSearch('');
                            setOpenCombobox(null);
                          }}
                        >
                          {manager}
                        </div>
                      ))}
                  </div>
                )}
              </div>
              {errors.directManager && <span className="error-message">{errors.directManager}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="startDate">Start Date*</label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                className={errors.startDate ? 'error' : ''}
              />
              {errors.startDate && <span className="error-message">{errors.startDate}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="resignationDate">Resignation Date (Optional)</label>
              <input
                type="date"
                id="resignationDate"
                name="resignationDate"
                value={formData.resignationDate}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="endDate">End Date (Optional)</label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="lastDayWorked">Last Day Worked (Optional)</label>
              <input
                type="date"
                id="lastDayWorked"
                name="lastDayWorked"
                value={formData.lastDayWorked}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="contractType">Contract Type*</label>
              <div className="combobox-container" ref={contractTypeComboboxRef}>
                <input
                  type="text"
                  className="combobox-input"
                  value={contractTypeSearch || formData.contractType}
                  onChange={(e) => {
                    setContractTypeSearch(e.target.value);
                    setFormData(prev => ({ ...prev, contractType: e.target.value }));
                  }}
                  onFocus={() => {
                    setOpenCombobox('contractType');
                    setContractTypeSearch(formData.contractType);
                  }}
                  placeholder="Select contract type..."
                  required
                />
                {openCombobox === 'contractType' && (
                  <div className="combobox-options">
                    {contractTypeOptions
                      .filter(type => type.toLowerCase().includes((contractTypeSearch || '').toLowerCase()))
                      .map(type => (
                        <div
                          key={type}
                          className="combobox-option"
                          onClick={() => {
                            setFormData(prev => ({ ...prev, contractType: type }));
                            setContractTypeSearch('');
                            setOpenCombobox(null);
                          }}
                        >
                          {type}
                        </div>
                      ))}
                  </div>
                )}
              </div>
              {errors.contractType && <span className="error-message">{errors.contractType}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="baseSalary">Base Salary*</label>
              <input
                type="number"
                id="baseSalary"
                name="baseSalary"
                value={formData.baseSalary}
                onChange={handleChange}
                className={errors.baseSalary ? 'error' : ''}
              />
              {errors.baseSalary && <span className="error-message">{errors.baseSalary}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="currency">Currency</label>
              <div className="combobox-container" ref={currencyComboboxRef}>
                <input
                  type="text"
                  className="combobox-input"
                  value={currencySearch || formData.currency}
                  onChange={(e) => {
                    setCurrencySearch(e.target.value);
                    setFormData(prev => ({ ...prev, currency: e.target.value }));
                  }}
                  onFocus={() => {
                    setOpenCombobox('currency');
                    setCurrencySearch(formData.currency);
                  }}
                  placeholder="Select currency..."
                />
                {openCombobox === 'currency' && (
                  <div className="combobox-options">
                    {currencies
                      .filter(curr => curr.toLowerCase().includes((currencySearch || '').toLowerCase()))
                      .map(curr => (
                        <div
                          key={curr}
                          className="combobox-option"
                          onClick={() => {
                            setFormData(prev => ({ ...prev, currency: curr }));
                            setCurrencySearch('');
                            setOpenCombobox(null);
                          }}
                        >
                          {curr}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="stipend">Stipend</label>
              <input
                type="number"
                id="stipend"
                name="stipend"
                value={formData.stipend}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="employerTax">Employer Tax</label>
              <input
                type="number"
                id="employerTax"
                name="employerTax"
                value={formData.employerTax}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="nationalInsurance">National Insurance</label>
              <input
                type="number"
                id="nationalInsurance"
                name="nationalInsurance"
                value={formData.nationalInsurance}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="commission">Commission</label>
              <input
                type="number"
                id="commission"
                name="commission"
                value={formData.commission}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="adminFees">Admin Fees</label>
              <input
                type="number"
                id="adminFees"
                name="adminFees"
                value={formData.adminFees}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="otherRecurringPayments">Other Recurring Payments</label>
              <input
                type="number"
                id="otherRecurringPayments"
                name="otherRecurringPayments"
                value={formData.otherRecurringPayments}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="employeeId">Employee ID</label>
              <input
                type="text"
                id="employeeId"
                name="employeeId"
                value={formData.employeeId}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="jobId">Job ID</label>
              <input
                type="text"
                id="jobId"
                name="jobId"
                value={formData.jobId}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="modal-actions">
            <button type="button" onClick={closeModal} className="cancel-button">
              Cancel
            </button>
            <button type="submit" className="submit-button">
              Add Employee
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}