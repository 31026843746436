import React, { useState, useEffect } from 'react';
import { useFirebase } from '../../contexts/FirebaseContext';
import './LandingPage.css';

function LandingPage({ onGetStarted }) {
  const { user, login, logout } = useFirebase();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Check if we're coming from an invite link
    const path = window.location.pathname;
    if (path.startsWith('/invite/')) {
      // Store this information in sessionStorage so it persists through the login flow
      sessionStorage.setItem('openNotifications', 'true');
    }
  }, []);

  const handleGetStarted = async () => {
    if (user) {
      onGetStarted();
    } else {
      setIsLoading(true);
      try {
        await login();
        onGetStarted();
      } catch (error) {
        console.error('Failed to log in:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleAuthClick = async () => {
    setIsLoading(true);
    try {
      if (user) {
        console.log('Attempting logout...');
        await logout();
        console.log('Logout successful');
      } else {
        console.log('Attempting login...');
        await login();
        console.log('Login function called');
      }
    } catch (error) {
      console.error('Auth action failed:', {
        code: error.code,
        message: error.message,
        stack: error.stack
      });
      alert('Error: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="landing-page">
      <header className="landing-header">
        <div className="header-content">
          <div className="logo">
            <img src="/images/logo.png" alt="multipl.org" className="landing-logo-image" />
          </div>
          <div className="header-buttons">
            <button 
              className="login-button" 
              onClick={handleAuthClick}
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : user ? 'Log Out' : 'Log In'}
            </button>
            <button 
              className="header-cta-button" 
              onClick={handleGetStarted}
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Get Started'}
            </button>
          </div>
        </div>
      </header>

      <div className="hero-section">
        <div className="hero-content">
          <h1>
            Visualize and plan your<br />
            <span className="gradient-text">organization's future</span>
          </h1>
          <p className="hero-subtitle">
            The modern platform for workforce planning, headcount<br />
            management, and organizational design
          </p>
          <button 
            className="cta-button" 
            onClick={handleGetStarted}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Get Started'}
          </button>
        </div>
        <div className="hero-image">
          <img 
            src="/images/dashboard-preview.png" 
            alt="Dashboard Preview" 
            className="dashboard-preview"
          />
        </div>
      </div>

      <div className="features-section">
        <div className="features-content">
          <div className="features-header">
            <h2>Everything you need<br /> for strategic <br />workforce planning</h2>
            <p>Powerful tools to help you make data-driven decisions about your<br />organization's growth</p>
          </div>
          
          <div className="feature-grid">
            <div className="feature-card">
              <div className="feature-content">
                <h3>Organization Design</h3>
                <p>Build and visualize your organization structure with interactive org charts</p>
              </div>
            </div>

            <div className="feature-card">
              <div className="feature-content">
                <h3>Headcount Planning</h3>
                <p>Model different growth scenarios and track your hiring plans</p>
              </div>
            </div>

            <div className="feature-card">
              <div className="feature-content">
                <h3>Budget Analysis</h3>
                <p>Get real-time insights into your workforce costs and investments</p>
              </div>
            </div>

            <div className="feature-card">
              <div className="feature-content">
                <h3>Talent Management</h3>
                <p>Track recruitment progress and manage your talent pipeline</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage; 