import React, { useState, useEffect } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import './LeaverDetailsModal.css';

const LEAVER_TYPES = ['Regrettable', 'Non-regrettable', 'RIF'];

const LeaverDetailsModal = ({ isOpen, onClose, employee, user }) => {
  const [formData, setFormData] = useState({
    leaverType: '',
    noticePeriod: '',
    mandatoryMinimumPayout: '',
    basePayPayout: '',
    unusedHolidayPay: '',
    severanceMonths: '',
    incrementalSeverancePayout: '',
    dateOfFinalPayout: ''
  });

  useEffect(() => {
    if (employee?.leaverDetails) {
      setFormData(employee.leaverDetails);
    }
  }, [employee]);

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const employeeRef = doc(db, `organizations/${user.orgId}/employees/${employee.id}`);
      await setDoc(employeeRef, {
        ...employee,
        leaverDetails: formData
      }, { merge: true });
      
      onClose();
    } catch (error) {
      console.error('Error saving leaver details:', error);
      alert('Error saving details. Please try again.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Leaver Details - {employee.firstName} {employee.surname}</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Leaver Type</label>
            <select
              name="leaverType"
              value={formData.leaverType}
              onChange={handleChange}
              required
            >
              <option value="">Select Type</option>
              {LEAVER_TYPES.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Notice Period (days)</label>
            <input
              type="number"
              name="noticePeriod"
              value={formData.noticePeriod}
              onChange={handleChange}
              placeholder="Enter number of days"
              min="0"
            />
          </div>

          <div className="form-group">
            <label>Mandatory Minimum Payout (days)</label>
            <input
              type="number"
              name="mandatoryMinimumPayout"
              value={formData.mandatoryMinimumPayout}
              onChange={handleChange}
              placeholder="Enter number of days"
              min="0"
            />
          </div>

          <div className="form-group">
            <label>Base Pay Payout</label>
            <input
              type="number"
              name="basePayPayout"
              value={formData.basePayPayout}
              onChange={handleChange}
              placeholder="Enter amount"
              min="0"
              step="0.01"
            />
          </div>

          <div className="form-group">
            <label>Unused Holiday Pay</label>
            <input
              type="number"
              name="unusedHolidayPay"
              value={formData.unusedHolidayPay}
              onChange={handleChange}
              placeholder="Enter amount"
              min="0"
              step="0.01"
            />
          </div>

          <div className="form-group">
            <label>Severance Months</label>
            <input
              type="number"
              name="severanceMonths"
              value={formData.severanceMonths}
              onChange={handleChange}
              placeholder="Enter number of months"
              min="0"
              step="0.1"
            />
          </div>

          <div className="form-group">
            <label>Incremental Severance Payout</label>
            <input
              type="number"
              name="incrementalSeverancePayout"
              value={formData.incrementalSeverancePayout}
              onChange={handleChange}
              placeholder="Enter amount"
              min="0"
              step="0.01"
            />
          </div>

          <div className="form-group">
            <label>Date of Final Payout</label>
            <input
              type="date"
              name="dateOfFinalPayout"
              value={formData.dateOfFinalPayout}
              onChange={handleChange}
            />
          </div>

          <div className="modal-actions">
            <button type="button" className="cancel-button" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="submit-button">
              Save Details
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LeaverDetailsModal; 