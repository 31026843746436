import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import * as XLSX from 'xlsx';
import { Tree, TreeNode } from 'react-organizational-chart';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  createColumnHelper,
  getFilteredRowModel,
  getSortedRowModel
} from '@tanstack/react-table';
import AddEmployeeModal from '../../components/modals/AddEmployeeModal';
import EditEmployeeModal from '../../components/modals/EditEmployeeModal';
import ExchangeRateModal from '../../components/modals/ExchangeRateModal';
import GetStartedModal from '../../components/modals/GetStartedModal';
import './CurrentOrganization.css';
import { useFirebase } from '../../contexts/FirebaseContext';
import { doc, setDoc, getDoc, writeBatch } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { isEqual } from 'lodash';
import { updateExchangeRates } from '../../services/firestore';

const CurrentOrganization = ({ 
  employees = [],
  setEmployees, 
  scenarios, 
  exchangeRates, 
  setExchangeRates, 
  presentationCurrency, 
  setPresentationCurrency 
}) => {
  const [uploadError, setUploadError] = useState('');
  const [isAddEmployeeModalOpen, setIsAddEmployeeModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showLeavers, setShowLeavers] = useState(true);
  const [hidePastLeavers, setHidePastLeavers] = useState(true);
  const [hideFutureLeavers, setHideFutureLeavers] = useState(false);
  const [showExchangeRateModal, setShowExchangeRateModal] = useState(false);
  const [rateErrors, setRateErrors] = useState({});
  const { deleteEmployee, createEmployee, user, updateEmployee } = useFirebase();
  const [isGetStartedModalOpen, setIsGetStartedModalOpen] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [availableOrganizations, setAvailableOrganizations] = useState([]);
  const [availableContractTypes, setAvailableContractTypes] = useState([]);
  const [isOrgChartVisible, setIsOrgChartVisible] = useState(() => {
    const saved = localStorage.getItem('isOrgChartVisible');
    return saved !== null ? JSON.parse(saved) : true;
  });

  // Save org chart visibility state when it changes
  useEffect(() => {
    localStorage.setItem('isOrgChartVisible', JSON.stringify(isOrgChartVisible));
  }, [isOrgChartVisible]);

  // Fetch available organizations and contract types from Firebase
  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const metadataRef = doc(db, 'metadata', 'employeeFields');
        const metadataDoc = await getDoc(metadataRef);
        
        if (metadataDoc.exists()) {
          const data = metadataDoc.data();
          if (data.organizations) setAvailableOrganizations(data.organizations);
          if (data.contractTypes) setAvailableContractTypes(data.contractTypes);
        }
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    };

    fetchMetadata();
  }, []);

  // Update metadata when new data is uploaded
  const updateMetadata = async (newOrgs, newContractTypes) => {
    try {
      const metadataRef = doc(db, 'metadata', 'employeeFields');
      const metadataDoc = await getDoc(metadataRef);
      
      let currentOrgs = new Set(availableOrganizations);
      let currentContractTypes = new Set(availableContractTypes);
      
      if (metadataDoc.exists()) {
        const data = metadataDoc.data();
        if (data.organizations) currentOrgs = new Set(data.organizations);
        if (data.contractTypes) currentContractTypes = new Set(data.contractTypes);
      }

      // Merge existing and new values
      const mergedOrgs = [...new Set([...currentOrgs, ...newOrgs])];
      const mergedContractTypes = [...new Set([...currentContractTypes, ...newContractTypes])];

      // Update Firebase and local state
      await setDoc(metadataRef, {
        organizations: mergedOrgs,
        contractTypes: mergedContractTypes,
        updatedAt: new Date()
      }, { merge: true });

      setAvailableOrganizations(mergedOrgs);
      setAvailableContractTypes(mergedContractTypes);

    } catch (error) {
      console.error('Error updating metadata:', error);
    }
  };

  // Add state for transform values
  const [transformValues, setTransformValues] = useState(() => {
    const saved = localStorage.getItem('currentOrgChartTransform');
    return saved ? JSON.parse(saved) : { scale: 0.6, positionX: 0, positionY: 0 };
  });

  // Save transform values when they change
  useEffect(() => {
    localStorage.setItem('currentOrgChartTransform', JSON.stringify(transformValues));
  }, [transformValues]);

  const isLeaver = (employee) => {
    if (!employee.endDate) return false;
    return new Date(employee.endDate) <= new Date();
  };
  
  const isFutureLeaver = (employee) => {
    if (!employee.endDate) return false;
    return new Date(employee.endDate) > new Date();
  };

  const isNewJoiner = (employee) => {
    if (!employee) return false;
    
    if (typeof employee.id === 'string' && employee.id.startsWith('hired-')) {
      return true;
    }
    
    if (employee.startDate) {
      const startDate = new Date(employee.startDate);
      const today = new Date();
      if (startDate > today) {
        return true;
      }
    }
    return false;
  };

  const handleUpdateEmployee = async (updatedEmployee) => {
    try {
      const originalEmployee = employees.find(e => e.id === updatedEmployee.id);
      if (!originalEmployee) {
        throw new Error('Employee not found');
      }

      // Update employee using the context's updateEmployee function
      await updateEmployee(updatedEmployee.id, updatedEmployee);

      // Handle manager name changes in the local state if needed
      const oldManagerName = `${originalEmployee.firstName} ${originalEmployee.surname}`;
      const newManagerName = `${updatedEmployee.firstName} ${updatedEmployee.surname}`;
      const wasNameChanged = oldManagerName !== newManagerName;

      if (wasNameChanged) {
        setEmployees(prev => prev.map(emp => {
          if (emp.directManager === oldManagerName) {
            return {
              ...emp,
              directManager: newManagerName
            };
          }
          return emp;
        }));
      }
    } catch (error) {
      console.error('Error updating employee:', error);
      throw error;
    }
  };

  const handleAddEmployee = async (newEmployee) => {
    const employeeData = {
      ...newEmployee,
      isActive: true,
      source: 'manual-entry'
    };
    await createEmployee(employeeData);
  };

  const handleExport = () => {
    const exportData = employees.map(emp => ({
      'Employee ID': emp.employeeId || '',
      'Job ID': emp.jobId || '',
      'First name': emp.firstName,
      'Surname': emp.surname,
      'Title': emp.title || '',
      'Organization': emp.org || '',
      'Function': emp.function || '',
      'Team': emp.team || '',
      'Job Level': emp.jobLevel || '',
      'Direct Manager': emp.directManager || '',
      'Contract Type': emp.contractType || '',
      'Location (Country)': emp.location || '',
      'Start Date': emp.startDate || '',
      'Resignation Date': emp.resignationDate || '',
      'End Date': emp.endDate || '',
      'Last Day Worked': emp.lastDayWorked || '',
      'Base Salary': emp.baseSalary || 0,
      'Stipend': emp.stipend || 0,
      'Employer Tax': emp.employerTax || 0,
      'National Insurance': emp.nationalInsurance || 0,
      'Commission': emp.commission || 0,
      'Admin Fees': emp.adminFees || 0,
      'Other Recurring Payments': emp.otherRecurringPayments || 0,
      'Currency': emp.currency || '',
      'Total Cost': (
        Number(emp.baseSalary || 0) +
        Number(emp.stipend || 0) +
        Number(emp.employerTax || 0) +
        Number(emp.nationalInsurance || 0) +
        Number(emp.commission || 0) +
        Number(emp.adminFees || 0) +
        Number(emp.otherRecurringPayments || 0)
      ),
      ...emp.customAttributes
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Employees");
    XLSX.writeFile(wb, "organization_data.xlsx");
  };

  const handleExportTemplate = () => {
    const templateHeaders = {
      'Employee ID': '',
      'Job ID': '',
      'First name': '',
      'Surname': '',
      'Title': '',
      'Organization': '',
      'Function': '',
      'Team': '',
      'Job Level': '',
      'Direct Manager': '',
      'Contract Type': '',
      'Location (Country)': '',
      'Start Date': '',
      'Resignation Date': '',
      'End Date': '',
      'Last Day Worked': '',
      'Base Salary': '',
      'Stipend': '',
      'Employer Tax': '',
      'National Insurance': '',
      'Commission': '',
      'Admin Fees': '',
      'Other Recurring Payments': '',
      'Currency': ''
    };

    // Add mandatory flags
    const mandatoryFlags = {
      'First name': 'Required',
      'Surname': 'Required',
      'Title': 'Required',
      'Organization': 'Required',
      'Team': 'Required',
      'Location (Country)': 'Required',
      'Start Date': 'Required',
      'Contract Type': 'Required',
      'Base Salary': 'Required',
      'Currency': 'Required'
    };

    // Get current available currencies from exchange rates
    const currentRates = exchangeRates?.rates || DEFAULT_EXCHANGE_RATES;
    const availableCurrencies = Object.keys(currentRates).join(', ');

    // Add allowed values
    const allowedValues = {
      'Currency': availableCurrencies || 'USD',  // Default to USD if no rates available
      'Organization': 'Any value accepted',
      'Contract Type': 'Any value accepted'
    };

    const ws = XLSX.utils.json_to_sheet([
      templateHeaders,
      mandatoryFlags,
      allowedValues
    ]);

    // Add some styling to the header row
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_col(C) + "1";
      if (!ws[address]) continue;
      ws[address].s = { font: { bold: true }, fill: { fgColor: { rgb: "CCCCCC" } } };
    }

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Template");
    XLSX.writeFile(wb, "organization_template.xlsx");
  };

  // Default exchange rates (1 unit to USD)
  const DEFAULT_EXCHANGE_RATES = {
    USD: 1,
    EUR: 1.1,
    GBP: 1.27,
    CHF: 1.12,
    SEK: 0.096,
    PHP: 0.018,
    INR: 0.012
  };

  const convertCurrency = (amount, fromCurrency) => {
    if (!amount || !fromCurrency) return 0;
    if (fromCurrency === presentationCurrency) return amount;
    
    // Use rates from exchangeRates.rates if available, otherwise use default
    const rates = exchangeRates?.rates || DEFAULT_EXCHANGE_RATES;
    
    // If we don't have a rate for either currency, return the original amount
    if (!rates[fromCurrency] || !rates[presentationCurrency]) {
      console.warn(`Missing exchange rate for ${fromCurrency} or ${presentationCurrency}`);
      return amount;
    }
    
    // Convert using relative rates
    return amount * (rates[fromCurrency] / rates[presentationCurrency]);
  };

  const calculateTotalCost = (employee) => {
    if (!employee) return 0;
    
    const total = (
      Number(employee.baseSalary || 0) +
      Number(employee.stipend || 0) +
      Number(employee.employerTax || 0) +
      Number(employee.nationalInsurance || 0) +
      Number(employee.commission || 0) +
      Number(employee.adminFees || 0) +
      Number(employee.otherRecurringPayments || 0)
    );
    
    // If no currency is specified, use presentation currency
    const currency = employee.currency || presentationCurrency;
    return convertCurrency(total, currency);
  };

  // Helper function to format dates and timestamps
  const formatDate = (date) => {
    if (!date) return '';
    if (date.seconds) { // Firestore timestamp
      return new Date(date.seconds * 1000).toISOString().split('T')[0];
    }
    if (date instanceof Date) {
      return date.toISOString().split('T')[0];
    }
    return date;
  };

  // Filter employees based on showLeavers state
  const filteredEmployees = useMemo(() => {
    return employees.filter(employee => {
      if (hidePastLeavers && isLeaver(employee)) {
        return false;
      }
      if (hideFutureLeavers && isFutureLeaver(employee)) {
        return false;
      }
      return true;
    });
  }, [employees, hidePastLeavers, hideFutureLeavers]);

  // Update the table data to use filtered employees and format dates
  const data = useMemo(() => {
    const formattedData = filteredEmployees.map(employee => {
      const formattedEmployee = {
        ...employee,
        startDate: formatDate(employee.startDate),
        endDate: formatDate(employee.endDate),
        status: isLeaver(employee) ? 'Leaver' : 
                isFutureLeaver(employee) ? 'Future Leaver' : 
                isNewJoiner(employee) ? 'New Joiner' : 'Active',
        totalCostPresentation: convertCurrency(
          (Number(employee.baseSalary || 0) +
          Number(employee.stipend || 0) +
          Number(employee.employerTax || 0) +
          Number(employee.nationalInsurance || 0) +
          Number(employee.commission || 0) +
          Number(employee.adminFees || 0) +
          Number(employee.otherRecurringPayments || 0)),
          employee.currency || presentationCurrency
        )
      };

      delete formattedEmployee.createdAt;
      delete formattedEmployee.updatedAt;
      delete formattedEmployee.__type;
      
      return formattedEmployee;
    });
    return formattedData;
  }, [filteredEmployees, exchangeRates, presentationCurrency, sorting]);

  const columnHelper = createColumnHelper();

  const columns = useMemo(() => [
    columnHelper.accessor('employeeId', {
      header: 'Employee ID',
      cell: info => info.getValue() || '-',
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('jobId', {
      header: 'Job ID',
      cell: info => info.getValue() || '-',
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('firstName', {
      header: 'First Name',
      cell: info => info.getValue(),
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('surname', {
      header: 'Last Name',
      cell: info => info.getValue(),
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('title', {
      header: 'Title',
      cell: info => info.getValue(),
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('org', {
      header: 'Organization',
      cell: info => info.getValue(),
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('function', {
      header: 'Function',
      cell: info => info.getValue() || '-',
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('team', {
      header: 'Team',
      cell: info => info.getValue(),
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor(row => row.jobLevel || row.level, {
      header: 'Job Level',
      cell: info => info.getValue() || '-',
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('location', {
      header: 'Location',
      cell: info => info.getValue(),
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('directManager', {
      header: 'Manager',
      cell: info => info.getValue(),
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('startDate', {
      header: 'Start Date',
      cell: info => info.getValue(),
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.startDate ? new Date(rowA.original.startDate) : new Date(0);
        const b = rowB.original.startDate ? new Date(rowB.original.startDate) : new Date(0);
        return a.getTime() - b.getTime();
      }
    }),
    columnHelper.accessor('endDate', {
      header: 'End Date',
      cell: info => info.getValue(),
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.endDate ? new Date(rowA.original.endDate) : new Date(8640000000000000);
        const b = rowB.original.endDate ? new Date(rowB.original.endDate) : new Date(8640000000000000);
        return a.getTime() - b.getTime();
      }
    }),
    columnHelper.accessor('contractType', {
      header: 'Contract Type',
      cell: info => info.getValue() || '-',
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('baseSalary', {
      header: 'Base Salary',
      cell: info => {
        const value = info.getValue();
        return value != null ? `${Number(value).toLocaleString()} ${info.row.original.currency}` : '-';
      },
      sortingFn: 'number'
    }),
    columnHelper.accessor('stipend', {
      header: 'Stipend',
      cell: info => {
        const value = info.getValue();
        return value != null ? `${Number(value).toLocaleString()} ${info.row.original.currency}` : '-';
      },
      sortingFn: 'number'
    }),
    columnHelper.accessor('employerTax', {
      header: 'Employer Tax',
      cell: info => {
        const value = info.getValue();
        return value != null ? `${Number(value).toLocaleString()} ${info.row.original.currency}` : '-';
      },
      sortingFn: 'number'
    }),
    columnHelper.accessor('nationalInsurance', {
      header: 'National Ins.',
      cell: info => {
        const value = info.getValue();
        return value != null ? `${Number(value).toLocaleString()} ${info.row.original.currency}` : '-';
      },
      sortingFn: 'number'
    }),
    columnHelper.accessor('commission', {
      header: 'Commission',
      cell: info => {
        const value = info.getValue();
        return value != null ? `${Number(value).toLocaleString()} ${info.row.original.currency}` : '-';
      },
      sortingFn: 'number'
    }),
    columnHelper.accessor('adminFees', {
      header: 'Admin Fees',
      cell: info => {
        const value = info.getValue();
        return value != null ? `${Number(value).toLocaleString()} ${info.row.original.currency}` : '-';
      },
      sortingFn: 'number'
    }),
    columnHelper.accessor('otherRecurringPayments', {
      header: 'Other Payments',
      cell: info => {
        const value = info.getValue();
        return value != null ? `${Number(value).toLocaleString()} ${info.row.original.currency}` : '-';
      },
      sortingFn: 'number'
    }),
    columnHelper.accessor(row => {
      const total = (
        Number(row.baseSalary || 0) +
        Number(row.stipend || 0) +
        Number(row.employerTax || 0) +
        Number(row.nationalInsurance || 0) +
        Number(row.commission || 0) +
        Number(row.adminFees || 0) +
        Number(row.otherRecurringPayments || 0)
      );
      return total;
    }, {
      header: 'Total Cost',
      cell: info => {
        const value = info.getValue();
        return value != null ? `${Number(value).toLocaleString()} ${info.row.original.currency}` : '-';
      },
      sortingFn: 'number'
    }),
    columnHelper.accessor(row => calculateTotalCost(row), {
      header: `Total Cost (${presentationCurrency})`,
      cell: info => {
        const value = info.getValue();
        return value != null ? `${Number(value).toLocaleString()} ${presentationCurrency}` : '-';
      },
      sortingFn: 'number'
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: info => info.getValue(),
      sortingFn: 'alphanumeric'
    })
  ], [presentationCurrency]);

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const searchValue = value.toLowerCase();
    const cellValue = String(row.getValue(columnId) || '').toLowerCase();
    return cellValue.includes(searchValue);
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilter,
    onGlobalFilterChange: setGlobalFilter,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter,
      sorting,
    },
    onSortingChange: setSorting,
    enableSorting: true,
    enableMultiSort: false
  });

  // Update the table body rendering to use sorted rows
  const rows = table.getSortedRowModel().rows;

  // Update the table rendering to handle specific fields
  const renderCell = (value, key) => {
    if (value === null || value === undefined) return '';
    if (typeof value === 'object' && value.seconds) {
      return formatDate(value);
    }
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    return value;
  };

  // Update the org chart to use filtered employees
  const buildOrgChartData = (employees) => {
    if (!employees?.length) return null;

    // Create a map of employees by their full name
    const employeeMap = new Map();
    employees.forEach(emp => {
      const fullName = `${emp.firstName} ${emp.surname}`;
      employeeMap.set(fullName, {
        ...emp,
        children: []
      });
    });

    // Root node for employees without a manager
    const root = {
      id: 'root',
      firstName: 'Organization',
      surname: '',
      title: '',
      children: []
    };

    // Build the tree structure
    employees.forEach(emp => {
      const fullName = `${emp.firstName} ${emp.surname}`;
      const employee = employeeMap.get(fullName);
      
      if (emp.directManager && employeeMap.has(emp.directManager)) {
        // If employee has a manager and manager exists in our data
        const manager = employeeMap.get(emp.directManager);
        manager.children.push(employee);
      } else {
        // If no manager or manager not found, add to root
        root.children.push(employee);
      }
    });

    // If root has only one child and it's not a real employee, return that child
    if (root.children.length === 1 && !root.children[0].id.startsWith('root')) {
      return root.children[0];
    }

    return root;
  };

  const OrgChartNode = ({ node }) => {
    const nodeClasses = ['org-node'];
    if (isLeaver(node)) {
      nodeClasses.push('leaver');
    } else if (isFutureLeaver(node)) {
      nodeClasses.push('future-leaver');
    } else if (isNewJoiner(node)) {
      nodeClasses.push('new-hire');
    } else if (node.isBackfill) {
      nodeClasses.push('backfill');
    }

    return (
      <div 
        className={nodeClasses.join(' ')}
        onClick={() => {
          setSelectedEmployee(node);
          setIsEditModalOpen(true);
        }}
        role="button"
        tabIndex={0}
      >
        <div className="org-node-header">{`${node.firstName} ${node.surname}`}</div>
        <div className="org-node-body">
          <div className="node-title">{node.title || 'No Title'}</div>
          <div className="node-contract">{node.contractType || 'No Contract Type'}</div>
          {(isNewJoiner(node) || node.isBackfill) && (
            <div className="node-badge">
              {isNewJoiner(node) ? 'New Hire' : 'Backfill'}
            </div>
          )}
        </div>
      </div>
    );
  };
  
  const renderNode = (node) => (
    <TreeNode label={<OrgChartNode node={node} />}>
      {node.children && node.children.map((childNode, index) => (
        <React.Fragment key={index}>
          {renderNode(childNode)}
        </React.Fragment>
      ))}
    </TreeNode>
  );

  // Add helper function to get unique organizations from current employees
  const getUniqueOrganizations = (employees) => {
    // Filter out empty or null values and get unique organizations
    const orgs = [...new Set(employees.map(emp => emp.org).filter(org => org && org.trim()))];
    // Sort alphabetically
    return orgs.sort();
  };

  // Add helper function to get unique contract types
  const getUniqueContractTypes = () => {
    // Get unique contract types from existing employees
    const contractTypes = [...new Set(employees
      .map(emp => emp.contractType)
      .filter(type => type && type.trim())
    )];
    
    // Sort alphabetically
    return contractTypes.sort();
  };

  // Add helper function to get manager options from current employees
  const getManagerOptions = (employees) => {
    // Get unique manager names and sort alphabetically
    return [...new Set(employees
      .map(emp => `${emp.firstName} ${emp.surname}`)
      .filter(name => name.trim() !== ' ')
    )].sort();
  };

  // Add helper function to get unique teams from current employees
  const getUniqueTeams = (employees) => {
    // Filter out empty or null values and get unique teams
    const teams = [...new Set(employees.map(emp => emp.team).filter(team => team && team.trim()))];
    // Sort alphabetically
    return teams.sort();
  };

  // Get unique organizations, teams, and manager options from current employees
  const uniqueOrganizations = useMemo(() => getUniqueOrganizations(employees), [employees]);
  const uniqueContractTypes = useMemo(() => getUniqueContractTypes(), [employees]);
  const uniqueTeams = useMemo(() => getUniqueTeams(employees), [employees]);
  const managerOptions = useMemo(() => getManagerOptions(employees), [employees]);

  // Modify handleFileUpload to update organizations
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setUploadError('');

    if (file) {
      // Close the Get Started modal immediately when file is selected
      setIsGetStartedModalOpen(false);
      
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const workbook = XLSX.read(e.target.result, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(worksheet, { raw: false });

          const transformedData = data.map((row, index) => {
            const formatDate = (dateStr) => {
              if (!dateStr || dateStr.trim() === '') return '';
              
              const [mm, dd, yy] = dateStr.split('/').map(part => part.trim());
              const year = yy.length === 2 ? `20${yy}` : yy;
              const month = mm.padStart(2, '0');
              const day = dd.padStart(2, '0');
              
              const yearNum = parseInt(year);
              const monthNum = parseInt(month);
              const dayNum = parseInt(day);
              
              if (isNaN(yearNum) || isNaN(monthNum) || isNaN(dayNum)) {
                console.error(`Invalid date parts: ${dateStr}`);
                return '';
              }
              
              if (monthNum < 1 || monthNum > 12) {
                console.error(`Invalid month: ${monthNum}`);
                return '';
              }
              
              if (dayNum < 1 || dayNum > 31) {
                console.error(`Invalid day: ${dayNum}`);
                return '';
              }
              
              return `${year}-${month}-${day}`;
            };

            const startDate = formatDate(row['Start Date']);
            const resignationDate = formatDate(row['Resignation Date']);
            const endDate = formatDate(row['End Date']);
            const lastDayWorked = formatDate(row['Last Day Worked']);

            // Calculate total cost in original currency
            const totalCost = (
              Number(row['Base Salary']) || 0) +
              (Number(row['Stipend']) || 0) +
              (Number(row['Employer Tax']) || 0) +
              (Number(row['National Insurance']) || 0) +
              (Number(row['Commission']) || 0) +
              (Number(row['Admin Fees']) || 0) +
              (Number(row['Other Recurring Payments']) || 0
            );

            // Convert to presentation currency if needed
            const currency = row['Currency'] || presentationCurrency;
            const totalCostPresentation = convertCurrency(totalCost, currency);

            return {
              id: `emp-${Date.now()}-${index}`,
              employeeId: row['Employee ID'] || '',
              jobId: row['Job ID'] || '',
              firstName: row['First name'] || '',
              surname: row['Surname'] || '',
              baseSalary: Number(row['Base Salary']) || 0,
              currency: currency,
              location: row['Location (Country)'] || '',
              jobLevel: row['Job Level'] || '',
              directManager: row['Direct Manager'] || '',
              org: row['Organization'] ? row['Organization'].trim() : '',
              function: row['Function'] ? row['Function'].trim() : '',
              team: row['Team'] || '',
              title: row['Title'] || '',
              contractType: row['Contract Type'] ? row['Contract Type'].trim() : '',
              stipend: Number(row['Stipend']) || 0,
              employerTax: Number(row['Employer Tax']) || 0,
              nationalInsurance: Number(row['National Insurance']) || 0,
              commission: Number(row['Commission']) || 0,
              adminFees: Number(row['Admin Fees']) || 0,
              otherRecurringPayments: Number(row['Other Recurring Payments']) || 0,
              startDate: startDate,
              resignationDate: resignationDate,
              endDate: endDate,
              lastDayWorked: lastDayWorked,
              totalCostPresentation: totalCostPresentation
            };
          });

          // Extract unique organizations and contract types from the uploaded data
          const newOrgs = [...new Set(transformedData.map(emp => emp.org).filter(org => org && org.trim()))];
          const newContractTypes = [...new Set(transformedData.map(emp => emp.contractType).filter(type => type && type.trim()))];

          // Extract unique currencies from the uploaded data
          const newCurrencies = [...new Set(transformedData.map(emp => emp.currency).filter(curr => curr && curr.trim()))];
          
          // Update exchange rates with new currencies if they don't exist
          const currentRates = exchangeRates?.rates || DEFAULT_EXCHANGE_RATES;
          let ratesUpdated = false;
          
          newCurrencies.forEach(currency => {
            if (!currentRates[currency]) {
              currentRates[currency] = 1; // Set default rate of 1, admin can adjust later
              ratesUpdated = true;
            }
          });

          if (ratesUpdated) {
            // Update Firebase with new rates
            const ratesRef = doc(db, 'exchangeRates', 'default');
            await setDoc(ratesRef, { rates: currentRates }, { merge: true });
            
            // Update local state and storage
            setExchangeRates({ rates: currentRates });
            localStorage.setItem('exchangeRates', JSON.stringify({ rates: currentRates }));
          }

          // Update metadata in Firebase with new values
          await updateMetadata(newOrgs, newContractTypes);

          await setEmployees(transformedData);
          
          // Show success message with currency information if new currencies were added
          if (ratesUpdated) {
            alert(`Successfully imported ${transformedData.length} employees. New currencies were detected and added to exchange rates. Please review the exchange rates in the Exchange Rate modal.`);
          } else {
            alert(`Successfully imported ${transformedData.length} employees`);
          }
        } catch (error) {
          console.error('Error parsing Excel file:', error);
          setUploadError(error.message || 'Error parsing Excel file');
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleRateChange = async (newRates) => {
    console.log('Parent handleRateChange called with new rates:', newRates);
    
    try {
      // Update exchange rates in the organization's settings
      await updateExchangeRates(user.orgId, newRates);
      
      // Update the rates in the parent state
      setExchangeRates({ rates: newRates });
      
      // No need to manually refresh costs - Cloud Function will handle it
      console.log('Successfully updated exchange rates');
    } catch (error) {
      console.error('Error updating exchange rates:', error);
      throw error;
    }
  };

  const handleRefreshCosts = () => {
    console.log('Costs are automatically calculated by Firebase Cloud Functions');
  };

  const handleSaveRates = () => {
    console.log('Parent handleSaveRates called');
    setShowExchangeRateModal(false);
  };

  const handleDeleteEmployee = async (employeeId) => {
    try {
      await deleteEmployee(employeeId);
      // Close modal and refresh data if needed
    } catch (error) {
      console.error('Error deleting employee:', error);
      alert('Failed to delete employee. Please try again.');
    }
  };

  // Add function to check and update currencies from existing data
  const updateCurrenciesFromExistingData = useCallback(async () => {
    try {
      // Get unique currencies from existing employees
      const existingCurrencies = [...new Set(employees.map(emp => emp.currency).filter(curr => curr && curr.trim()))];
      
      // Update exchange rates with new currencies if they don't exist
      const currentRates = exchangeRates?.rates || DEFAULT_EXCHANGE_RATES;
      let ratesUpdated = false;
      
      existingCurrencies.forEach(currency => {
        if (!currentRates[currency]) {
          currentRates[currency] = 1; // Set default rate of 1, admin can adjust later
          ratesUpdated = true;
        }
      });

      if (ratesUpdated) {
        // Update Firebase with new rates
        const ratesRef = doc(db, 'exchangeRates', 'default');
        await setDoc(ratesRef, { rates: currentRates }, { merge: true });
        
        // Update local state and storage
        setExchangeRates({ rates: currentRates });
        localStorage.setItem('exchangeRates', JSON.stringify({ rates: currentRates }));
        
        // Notify user
        alert('New currencies were detected in existing employee data and added to exchange rates. Please review the exchange rates in the Exchange Rate modal.');
      }
    } catch (error) {
      console.error('Error updating currencies from existing data:', error);
    }
  }, [employees, exchangeRates, setExchangeRates]);

  // Call the function when employees data changes
  useEffect(() => {
    if (employees.length > 0) {
      updateCurrenciesFromExistingData();
    }
  }, [employees, exchangeRates, updateCurrenciesFromExistingData]);

  return (
    <div className="view-content">
      <div className="view-header">
        <div className="data-summary">
          <p>
            Total Employees
            <span>{filteredEmployees?.length || 0}</span>
          </p>
          <p>
            Teams
            <span>{[...new Set(filteredEmployees?.map(e => e.team) || [])].length}</span>
          </p>
          <p>
            Locations
            <span>{[...new Set(filteredEmployees?.map(e => e.location) || [])].length}</span>
          </p>
        </div>
        <div className="header-actions">
          {employees.length === 0 ? (
            <button 
              className="get-started-btn"
              onClick={() => setIsGetStartedModalOpen(true)}
            >
              Get Started
            </button>
          ) : (
            <div className="button-grid">
              <button 
                className="grid-button"
                onClick={() => setIsAddEmployeeModalOpen(true)}
              >
                Add Employee
              </button>
              <button 
                className="grid-button"
                onClick={() => setShowExchangeRateModal(true)}
              >
                Exchange Rates
              </button>
              <button 
                className={`grid-button ${hidePastLeavers ? 'active' : ''}`}
                onClick={() => setHidePastLeavers(!hidePastLeavers)}
              >
                {hidePastLeavers ? 'Show Past Leavers' : 'Hide Past Leavers'}
              </button>
              <button 
                className={`grid-button ${hideFutureLeavers ? 'active' : ''}`}
                onClick={() => setHideFutureLeavers(!hideFutureLeavers)}
              >
                {hideFutureLeavers ? 'Show Future Leavers' : 'Hide Future Leavers'}
              </button>
              <button 
                className="grid-button"
                onClick={handleExport}
              >
                Export
              </button>
              <button 
                className="grid-button"
                onClick={() => document.getElementById('fileInput').click()}
              >
                Import
              </button>
              <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={handleFileUpload}
                accept=".xlsx,.xls"
              />
            </div>
          )}
        </div>
      </div>
      
      <div className="org-chart-container">
        <div 
          className="org-chart-header"
          onClick={() => setIsOrgChartVisible(!isOrgChartVisible)}
        >
          <div className="org-chart-header-content">
            <h3>
              <span className="collapse-icon">{isOrgChartVisible ? '▼' : '►'}</span>
              Organization Chart
            </h3>
          </div>
        </div>
        {isOrgChartVisible && (
          <div className="zoom-controls">
            <TransformWrapper
              initialScale={transformValues.scale}
              initialPositionX={transformValues.positionX}
              initialPositionY={transformValues.positionY}
              minScale={0.1}
              maxScale={2}
              centerOnInit={true}
              limitToBounds={false}
              panning={{
                disabled: false,
                velocityDisabled: false
              }}
              alignmentAnimation={{ disabled: true }}
              onTransformed={(_, state) => {
                setTransformValues({
                  scale: state.scale,
                  positionX: state.positionX,
                  positionY: state.positionY
                });
              }}
            >
              {({ zoomIn, zoomOut, resetTransform }) => (
                <>
                  <div className="zoom-buttons">
                    <button onClick={() => zoomIn()}>Zoom In</button>
                    <button onClick={() => zoomOut()}>Zoom Out</button>
                    <button onClick={() => {
                      resetTransform();
                      setTransformValues({ scale: 0.4, positionX: 0, positionY: 0 });
                    }}>Reset</button>
                  </div>
                  <div className="chart-wrapper">
                    <TransformComponent>
                      {buildOrgChartData(filteredEmployees) && (
                        <Tree
                          lineWidth={'2px'}
                          lineColor={'#363940'}
                          lineBorderRadius={'10px'}
                          label={<OrgChartNode node={buildOrgChartData(filteredEmployees)} />}
                        >
                          {buildOrgChartData(filteredEmployees)?.children?.map((node, index) => (
                            <React.Fragment key={index}>
                              {renderNode(node)}
                            </React.Fragment>
                          ))}
                        </Tree>
                      )}
                    </TransformComponent>
                  </div>
                </>
              )}
            </TransformWrapper>
          </div>
        )}
      </div>

      <div className="employee-data-container">
        <div className="table-header">
          <div className="table-title">Employee Data</div>
          <div className="search-container">
            <div className="search-wrapper">
              <i className="search-icon">🔍</i>
              <input
                type="text"
                value={globalFilter ?? ''}
                onChange={e => setGlobalFilter(e.target.value)}
                placeholder="Search employees..."
                className="search-input"
              />
              {globalFilter && (
                <button 
                  className="clear-search"
                  onClick={() => setGlobalFilter('')}
                >
                  ✕
                </button>
              )}
            </div>
            {table.getFilteredRowModel().rows.length < data.length && (
              <div className="search-results">
                Found {table.getFilteredRowModel().rows.length} of {data.length} employees
              </div>
            )}
          </div>
        </div>
        <div className="table-wrapper">
          <table>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th 
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      style={{ cursor: header.column.getCanSort() ? 'pointer' : 'default' }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: ' ↑',
                          desc: ' ↓',
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getSortedRowModel().rows.map(row => (
                <tr 
                  key={row.id} 
                  onClick={() => {
                    setSelectedEmployee(row.original);
                    setIsEditModalOpen(true);
                  }}
                  className="clickable-row"
                >
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
              {table.getSortedRowModel().rows.length === 0 && (
                <tr>
                  <td colSpan={columns.length} className="no-results">
                    No employees found matching "{globalFilter}"
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <AddEmployeeModal
        isOpen={isAddEmployeeModalOpen}
        closeModal={() => setIsAddEmployeeModalOpen(false)}
        onAddEmployee={handleAddEmployee}
        existingEmployees={employees}
        organizationOptions={uniqueOrganizations}
        contractTypeOptions={uniqueContractTypes}
        teamOptions={uniqueTeams}
      />
      
      <EditEmployeeModal
        isOpen={isEditModalOpen && selectedEmployee !== null}
        closeModal={() => {
          setIsEditModalOpen(false);
          setSelectedEmployee(null);
        }}
        employee={selectedEmployee || {}}
        onEditEmployee={handleUpdateEmployee}
        onDeleteEmployee={handleDeleteEmployee}
        existingEmployees={employees}
        organizationOptions={uniqueOrganizations}
        contractTypeOptions={uniqueContractTypes}
        teamOptions={uniqueTeams}
      />

      <ExchangeRateModal
        isOpen={showExchangeRateModal}
        onClose={() => setShowExchangeRateModal(false)}
        rates={exchangeRates?.rates || DEFAULT_EXCHANGE_RATES}
        onRateChange={handleRateChange}
        onSave={handleSaveRates}
        errors={rateErrors}
        presentationCurrency={presentationCurrency}
        onPresentationCurrencyChange={setPresentationCurrency}
      />

      <GetStartedModal
        isOpen={isGetStartedModalOpen}
        onClose={() => setIsGetStartedModalOpen(false)}
        onDownloadTemplate={handleExportTemplate}
        onUploadClick={() => document.getElementById('fileInput').click()}
      />
    </div>
  );
};

export default CurrentOrganization; 