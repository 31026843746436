import React, { useState } from 'react';
import './GetStartedModal.css';

const GetStartedModal = ({ isOpen, onClose, onDownloadTemplate, onUploadClick }) => {
  const [templateDownloaded, setTemplateDownloaded] = useState(false);

  if (!isOpen) return null;

  const handleDownloadTemplate = () => {
    onDownloadTemplate();
    setTemplateDownloaded(true);
  };

  return (
    <div className="get-started-modal-overlay">
      <div className="get-started-modal">
        <div className="modal-header">
          <h2>Let's get going with multipl.org</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        
        <div className="modal-content">
          <div className="welcome-section">
            <h3>Your future of simple planning starts here</h3>
            <p>Download the template to start the process, then upload your data!</p>
          </div>

          <div className="options-grid">
            <div className="option-card">
              <div className="step-label">Step one</div>
              <div className="option-icon">📥</div>
              <h4>Download Template</h4>
              <p>Get our pre-formatted Excel template with all required fields and guidelines.</p>
              <button 
                className="primary-button"
                onClick={handleDownloadTemplate}
              >
                Download Template
              </button>
            </div>

            <div className="option-card">
              <div className="step-label">Step two</div>
              <div className="option-icon">📤</div>
              <h4>Upload Organization Data</h4>
              <p>Upload your completed Excel file with your organization's data.</p>
              <button 
                className={`primary-button ${!templateDownloaded ? 'disabled' : ''}`}
                onClick={templateDownloaded ? onUploadClick : undefined}
                disabled={!templateDownloaded}
              >
                Upload Data
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStartedModal; 