import React, { useState, useEffect, useRef } from 'react';
import { countries } from '../../utils/countries';
import { handleNewLocation } from '../../utils/locationUtils';
import './TARoleModal.css';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';

function TARoleModal({ isOpen, onClose, role, recruitmentStatus, onUpdateRole, employees = [] }) {
    const functions = [
      'Biz Ops & Strategy',
      'Content',
      'Customer Service',
      'Data Analytics',
      'Finance',
      'Graphic Design',
      'Marketing',
      'People',
      'Product Design',
      'Product Management',
      'Sales',
      'SEO',
      'Software Engineering',
      'User Research',
      'Workplace & Culture',
      'Customer Success',
      'RevOps',
      'IT Support'
    ];

    const levels = [
      { id: 'ic1', display: 'IC1' },
      { id: 'ic2', display: 'IC2' },
      { id: 'ic3', display: 'IC3 / M3' },
      { id: 'ic4', display: 'IC4 / M4' },
      { id: 'ic5', display: 'IC5 / M5' },
      { id: 'ic6', display: 'IC6 / M6' },
      { id: 'l1', display: 'L1' },
      { id: 'l2', display: 'L2' },
      { id: 'l3', display: 'L3' },
      { id: 's1', display: 'S1' },
      { id: 's2', display: 'S2' },
      { id: 's3', display: 'S3' },
      { id: 's4', display: 'S4' },
      { id: 's5', display: 'S5' }
    ];

    const [currencies, setCurrencies] = useState([]);
    const [contractTypes, setContractTypes] = useState([]);
    
    // Get unique organizations from employees
    const organizations = Array.from(new Set(employees.map(emp => emp.org).filter(Boolean))).sort();

    const [functionSearch, setFunctionSearch] = useState('');
    const [levelSearch, setLevelSearch] = useState('');
    const [contractTypeSearch, setContractTypeSearch] = useState('');
    const [currencySearch, setCurrencySearch] = useState('');

    const [functionDisplay, setFunctionDisplay] = useState(role.function || '');
    const [levelDisplay, setLevelDisplay] = useState(
      levels.find(l => l.id === (role.level || role.jobLevel))?.display || ''
    );
    const [contractTypeDisplay, setContractTypeDisplay] = useState(role.contractType || '');
    const [currencyDisplay, setCurrencyDisplay] = useState(role.currency || '');

    const [locationDisplay, setLocationDisplay] = useState(role.location || '');
    const [locationSearch, setLocationSearch] = useState('');

    const filteredLocations = countries.filter(country => 
      country.toLowerCase().includes(locationSearch.toLowerCase())
    );

    // Fetch currencies and contract types from Firebase on component mount
    useEffect(() => {
      const fetchData = async () => {
        try {
          // Fetch exchange rates for currencies
          const ratesRef = doc(db, `organizations/${role.orgId}/settings/exchangeRates`);
          const ratesSnapshot = await getDoc(ratesRef);
          if (ratesSnapshot.exists()) {
            const data = ratesSnapshot.data();
            if (data.rates) {
              setCurrencies(Object.keys(data.rates));
            }
          }

          // Fetch contract types from metadata
          const metadataRef = doc(db, 'metadata', 'employeeFields');
          const metadataSnapshot = await getDoc(metadataRef);
          if (metadataSnapshot.exists()) {
            const data = metadataSnapshot.data();
            if (data.contractTypes) {
              setContractTypes(data.contractTypes);
            }
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }, [role.orgId]);

    // Update display values when role changes
    useEffect(() => {
      if (role) {
        setFunctionDisplay(role.function || '');
        const levelObj = levels.find(l => l.id === (role.level || role.jobLevel));
        setLevelDisplay(levelObj?.display || '');
        setContractTypeDisplay(role.contractType || '');
        setCurrencyDisplay(role.currency || '');
      }
    }, [role, recruitmentStatus]);

    const [editMode, setEditMode] = useState(true);
    const [locationSuggestions, setLocationSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const suggestionRef = useRef(null);

    const [roleDetails, setRoleDetails] = useState(() => ({
      roleTitle: role.roleTitle || '',
      team: role.team || '',
      organization: role.organization || '',
      directManager: role.directManager || '',
      totalCost: role.totalCost || '',
      startDate: role.startDate || '',
      hireName: role.hireName || '',
      hireSurname: role.hireSurname || '',
      linkedinProfile: role.linkedinProfile || '',
      confirmedStartDate: role.confirmedStartDate || '',
      location: role.location || '',
      baseSalary: role.baseSalary || '',
      currency: role.currency || 'USD',
      contractType: role.contractType || '',
      function: role.function || '',
      jobLevel: role.level || role.jobLevel || '',
      priority: role.priority || 'Medium',
      preferredStartMonth: role.startDate ? new Date(role.startDate).toLocaleString('default', { month: 'long' }) : '',
      preferredStartYear: role.startDate ? new Date(role.startDate).getFullYear().toString() : '',
      stipend: role.stipend || 0,
      employerTax: role.employerTax || 0,
      nationalInsurance: role.nationalInsurance || 0,
      commission: role.commission || 0,
      adminFees: role.adminFees || 0,
      otherRecurringPayments: role.otherRecurringPayments || 0,
      employeeId: role.employeeId || '',
      jobId: role.jobId || ''
    }));

    const filteredFunctions = functions.filter(func => 
      func.toLowerCase().includes(functionSearch.toLowerCase())
    );

    const filteredLevels = levels.filter(level => 
      level.display.toLowerCase().includes(levelSearch.toLowerCase())
    );

    const filteredContractTypes = contractTypes.filter(type => 
      type.toLowerCase().includes(contractTypeSearch.toLowerCase())
    );

    const filteredCurrencies = currencies.filter(currency => 
      currency.toLowerCase().includes(currencySearch.toLowerCase())
    );

    useEffect(() => {
      if (role) {
        setRoleDetails({
          roleTitle: role.roleTitle || '',
          team: role.team || '',
          organization: role.organization || '',
          directManager: role.directManager || '',
          totalCost: role.totalCost || '',
          startDate: role.startDate || '',
          hireName: role.hireName || '',
          hireSurname: role.hireSurname || '',
          linkedinProfile: role.linkedinProfile || '',
          confirmedStartDate: role.confirmedStartDate || '',
          location: role.location || '',
          baseSalary: role.baseSalary || '',
          currency: role.currency || 'USD',
          contractType: role.contractType || '',
          function: role.function || '',
          jobLevel: role.level || role.jobLevel || '',
          priority: role.priority || 'Medium',
          preferredStartMonth: role.startDate ? new Date(role.startDate).toLocaleString('default', { month: 'long' }) : '',
          preferredStartYear: role.startDate ? new Date(role.startDate).getFullYear().toString() : '',
          stipend: role.stipend || 0,
          employerTax: role.employerTax || 0,
          nationalInsurance: role.nationalInsurance || 0,
          commission: role.commission || 0,
          adminFees: role.adminFees || 0,
          otherRecurringPayments: role.otherRecurringPayments || 0,
          employeeId: role.employeeId || '',
          jobId: role.jobId || ''
        });

        setFunctionDisplay(role.function || '');
        // Fix level display by checking both level and jobLevel
        const levelObj = levels.find(l => l.id === (role.level || role.jobLevel));
        setLevelDisplay(levelObj?.display || '');
        setContractTypeDisplay(role.contractType || '');
        setCurrencyDisplay(role.currency || '');
      }
    }, [role, recruitmentStatus]);

    const handleSubmit = async (e) => {
      e.preventDefault();
      
      try {
        console.log('=== Debug: Role Update - Full Details ===');
        console.log('Current role state:', role);
        console.log('Recruitment status prop:', recruitmentStatus);
        console.log('Role details state:', roleDetails);
        console.log('Edit mode:', editMode);
        
        // Check if this is just a Job ID update by comparing the new jobId with the current one
        const isJobIdUpdate = roleDetails.jobId !== role.jobId && 
          Object.keys(roleDetails).length === 1 && 
          'jobId' in roleDetails;

        console.log('Is Job ID update?', isJobIdUpdate);

        // Check if this is a completion or moving away from completed
        const isCompletingHire = recruitmentStatus === 'Completed';
        const isMovingFromCompleted = role.recruitmentStatus === 'Completed' && recruitmentStatus !== 'Completed';

        console.log('Status change analysis:', {
          currentStatus: role.status,
          currentRecruitmentStatus: role.recruitmentStatus,
          incomingRecruitmentStatus: recruitmentStatus,
          isCompletingHire,
          isMovingFromCompleted,
          roleDetailsStatus: roleDetails.status,
          roleDetailsRecruitmentStatus: roleDetails.recruitmentStatus
        });

        if (isJobIdUpdate) {
          console.log('Performing Job ID only update');
          const updatedRole = {
            ...role,
            jobId: roleDetails.jobId,
            // Explicitly preserve these fields
            status: role.status,
            recruitmentStatus: role.recruitmentStatus,
            convertedToEmployee: role.convertedToEmployee,
            employeeId: role.employeeId
          };
          console.log('Updated role for Job ID update:', updatedRole);
          await onUpdateRole(updatedRole);
          onClose();
          return;
        }

        if (isCompletingHire) {
          console.log('Attempting to complete hire - validation starting');
          // ... existing validation code ...
          
          console.log('Hire completion validation passed');
          const newEmployee = {
            id: `hired-${role.id}`,
            firstName: roleDetails.hireName || '',
            surname: roleDetails.hireSurname || '',
            title: roleDetails.roleTitle,
            org: roleDetails.organization,
            team: roleDetails.team,
            directManager: roleDetails.directManager,
            contractType: roleDetails.contractType,
            location: roleDetails.location,
            startDate: roleDetails.confirmedStartDate,
            baseSalary: roleDetails.baseSalary,
            currency: roleDetails.currency,
            linkedinProfile: roleDetails.linkedinProfile,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            source: 'talent-acquisition',
            originalRoleId: role.id
          };

          const updatedRole = {
            ...role,
            ...roleDetails,
            status: 'Completed',
            recruitmentStatus: 'Completed',
            convertedToEmployee: true,
            employeeId: newEmployee.id
          };
          
          console.log('Completing hire with role:', updatedRole);
          console.log('New employee record:', newEmployee);
          await onUpdateRole(updatedRole, newEmployee);
        } else if (isMovingFromCompleted) {
          console.log('Moving away from Completed status - removing employee data');
          const updatedRole = {
            ...role,
            ...roleDetails,
            status: recruitmentStatus,
            recruitmentStatus: recruitmentStatus,
            convertedToEmployee: false,
            employeeId: null,
            hireName: '',
            hireSurname: '',
            baseSalary: '',
            location: '',
            confirmedStartDate: '',
            currency: '',
            contractType: '',
            linkedinProfile: '',
            stipend: '',
            employerTax: '',
            nationalInsurance: '',
            commission: '',
            adminFees: '',
            otherRecurringPayments: '',
            totalCost: ''
          };
          
          console.log('Final role state for uncomplete update:', updatedRole);
          await onUpdateRole(updatedRole, null); // Pass null as second argument to indicate employee deletion
        } else {
          console.log('Performing regular update - preserving status');
          // Regular update without completion
          const updatedRole = {
            ...role,
            ...roleDetails,
            // Only preserve status fields if not completing or uncompleting
            status: role.status,
            recruitmentStatus: role.recruitmentStatus,
            convertedToEmployee: role.convertedToEmployee || false,
            employeeId: role.employeeId
          };
          
          console.log('Final role state for regular update:', updatedRole);
          await onUpdateRole(updatedRole);
        }
        
        onClose();
      } catch (error) {
        console.error('Error updating role:', error);
        if (error.code === 'resource-exhausted') {
          alert('Unable to save to Firebase due to quota limits. Your changes have been saved locally.');
        } else {
          alert('An error occurred while saving. Please try again.');
        }
      }
    };

    // Add location handling functions
    const handleLocationChange = (e) => {
      const input = e.target.value;
      setRoleDetails({...roleDetails, location: input});
      
      if (input.length > 0) {
        const filteredSuggestions = countries.filter(country =>
          country.toLowerCase().includes(input.toLowerCase())
        );
        setLocationSuggestions(filteredSuggestions);
        setShowSuggestions(true);
      } else {
        setLocationSuggestions([]);
        setShowSuggestions(false);
      }
    };

    const handleLocationSelect = (country) => {
      setRoleDetails({...roleDetails, location: country});
      setShowSuggestions(false);
    };

    // Add click outside handler for location suggestions
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (suggestionRef.current && !suggestionRef.current.contains(event.target)) {
          setShowSuggestions(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Add state to track which combobox is open
    const [openCombobox, setOpenCombobox] = useState(null);

    // Add refs for each combobox
    const functionComboboxRef = useRef(null);
    const levelComboboxRef = useRef(null);
    const organizationComboboxRef = useRef(null);
    const monthComboboxRef = useRef(null);
    const yearComboboxRef = useRef(null);
    const priorityComboboxRef = useRef(null);
    const locationComboboxRef = useRef(null);
    const contractTypeComboboxRef = useRef(null);
    const currencyComboboxRef = useRef(null);

    // Update click outside handler
    useEffect(() => {
        const handleClickOutside = (event) => {
            const refs = [
                functionComboboxRef,
                levelComboboxRef,
                organizationComboboxRef,
                monthComboboxRef,
                yearComboboxRef,
                priorityComboboxRef,
                locationComboboxRef,
                contractTypeComboboxRef,
                currencyComboboxRef
            ];
            
            if (!refs.some(ref => ref.current && ref.current.contains(event.target))) {
                setOpenCombobox(null);
                setFunctionSearch('');
                setLevelSearch('');
                setLocationSearch('');
                setContractTypeSearch('');
                setCurrencySearch('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    if (!isOpen) return null;

    return (
      <div className="modal-overlay">
        <div className="ta-role-modal">
          <div className="modal-header">
            <h2>{recruitmentStatus === 'Completed' ? 'Complete Hire' : 'Edit Role Details'}</h2>
            <button className="close-button" onClick={onClose}>&times;</button>
          </div>
  
          <div className="role-details-content">
            <form onSubmit={handleSubmit} className="role-edit-form">
              <div className="form-section">
                <h3>ROLE DETAILS</h3>
                <div className="form-grid role-details-grid">
                  {/* First Row - 5 columns */}
                  <div className="form-group">
                    <label>Role Title</label>
                    <input
                      type="text"
                      value={roleDetails.roleTitle}
                      onChange={(e) => setRoleDetails({...roleDetails, roleTitle: e.target.value})}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Function</label>
                    <div className="combobox-container" ref={functionComboboxRef}>
                      <input
                        type="text"
                        className="combobox-input"
                        value={functionSearch || functionDisplay}
                        onChange={(e) => {
                          setFunctionSearch(e.target.value);
                          setFunctionDisplay(e.target.value);
                          setRoleDetails({...roleDetails, function: e.target.value});
                        }}
                        onFocus={() => {
                          setOpenCombobox('function');
                          setFunctionSearch(functionDisplay);
                        }}
                        placeholder="Search function..."
                        required
                      />
                      {openCombobox === 'function' && (
                        <div className="combobox-options">
                          {filteredFunctions.map(func => (
                            <div
                              key={func}
                              className="combobox-option"
                              onClick={() => {
                                setRoleDetails({...roleDetails, function: func});
                                setFunctionDisplay(func);
                                setFunctionSearch('');
                                setOpenCombobox(null);
                              }}
                            >
                              {func}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Level</label>
                    <div className="combobox-container" ref={levelComboboxRef}>
                      <input
                        type="text"
                        className="combobox-input"
                        value={levelSearch || levelDisplay}
                        onChange={(e) => {
                          setLevelSearch(e.target.value);
                          setLevelDisplay(e.target.value);
                        }}
                        onFocus={() => {
                          setOpenCombobox('level');
                          setLevelSearch(levelDisplay);
                        }}
                        placeholder="Search level..."
                        required
                      />
                      {openCombobox === 'level' && (
                        <div className="combobox-options">
                          {filteredLevels.map(level => (
                            <div
                              key={level.id}
                              className="combobox-option"
                              onClick={() => {
                                setRoleDetails({...roleDetails, jobLevel: level.id});
                                setLevelDisplay(level.display);
                                setLevelSearch('');
                                setOpenCombobox(null);
                              }}
                            >
                              {level.display}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Team</label>
                    <input
                      type="text"
                      value={roleDetails.team}
                      onChange={(e) => setRoleDetails({...roleDetails, team: e.target.value})}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Organization</label>
                    <div className="combobox-container" ref={organizationComboboxRef}>
                      <input
                        type="text"
                        className="combobox-input"
                        value={roleDetails.organization || ''}
                        onChange={(e) => setRoleDetails({...roleDetails, organization: e.target.value})}
                        onFocus={() => setOpenCombobox('organization')}
                        placeholder="Select organization..."
                        required
                      />
                      {openCombobox === 'organization' && (
                        <div className="combobox-options">
                          {organizations.map(org => (
                            <div
                              key={org}
                              className="combobox-option"
                              onClick={() => {
                                setRoleDetails({...roleDetails, organization: org});
                                setOpenCombobox(null);
                              }}
                            >
                              {org}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  
                  {/* Second Row - 5 columns */}
                  <div className="form-group">
                    <label>Job ID</label>
                    <input
                      type="text"
                      value={roleDetails.jobId}
                      onChange={(e) => setRoleDetails({...roleDetails, jobId: e.target.value})}
                    />
                  </div>
                  <div className="form-group">
                    <label>Direct Manager</label>
                    <input
                      type="text"
                      value={roleDetails.directManager}
                      onChange={(e) => setRoleDetails({...roleDetails, directManager: e.target.value})}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Start Month</label>
                    <div className="combobox-container" ref={monthComboboxRef}>
                      <input
                        type="text"
                        className="combobox-input"
                        value={roleDetails.preferredStartMonth}
                        onChange={(e) => setRoleDetails({...roleDetails, preferredStartMonth: e.target.value})}
                        onFocus={() => setOpenCombobox('month')}
                        placeholder="Select month..."
                        required
                      />
                      {openCombobox === 'month' && (
                        <div className="combobox-options">
                          {['January', 'February', 'March', 'April', 'May', 'June', 
                            'July', 'August', 'September', 'October', 'November', 'December'].map(month => (
                            <div
                              key={month}
                              className="combobox-option"
                              onClick={() => {
                                setRoleDetails({...roleDetails, preferredStartMonth: month});
                                setOpenCombobox(null);
                              }}
                            >
                              {month}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Start Year</label>
                    <div className="combobox-container" ref={yearComboboxRef}>
                      <input
                        type="text"
                        className="combobox-input"
                        value={roleDetails.preferredStartYear}
                        onChange={(e) => setRoleDetails({...roleDetails, preferredStartYear: e.target.value})}
                        onFocus={() => setOpenCombobox('year')}
                        placeholder="Select year..."
                        required
                      />
                      {openCombobox === 'year' && (
                        <div className="combobox-options">
                          {['2024', '2025', '2026'].map(year => (
                            <div
                              key={year}
                              className="combobox-option"
                              onClick={() => {
                                setRoleDetails({...roleDetails, preferredStartYear: year});
                                setOpenCombobox(null);
                              }}
                            >
                              {year}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Priority</label>
                    <div className="combobox-container" ref={priorityComboboxRef}>
                      <input
                        type="text"
                        className="combobox-input"
                        value={roleDetails.priority}
                        onChange={(e) => setRoleDetails({...roleDetails, priority: e.target.value})}
                        onFocus={() => setOpenCombobox('priority')}
                        placeholder="Select priority..."
                        required
                      />
                      {openCombobox === 'priority' && (
                        <div className="combobox-options">
                          {['High', 'Medium', 'Low'].map(priority => (
                            <div
                              key={priority}
                              className="combobox-option"
                              onClick={() => {
                                setRoleDetails({...roleDetails, priority: priority});
                                setOpenCombobox(null);
                              }}
                            >
                              {priority}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {(roleDetails.status === 'Completed' || recruitmentStatus === 'Completed' || role.recruitmentStatus === 'Completed') && (
                <div className="form-section">
                  <div className="section-header">
                    <h3>HIRE DETAILS</h3>
                    <div className="section-description">Complete the hire by filling in the employee details below</div>
                  </div>

                  <div className="subsection">
                    <h4>PERSONAL INFORMATION</h4>
                    <div className="form-grid">
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          type="text"
                          value={roleDetails.hireName}
                          onChange={(e) => setRoleDetails({...roleDetails, hireName: e.target.value})}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          value={roleDetails.hireSurname}
                          onChange={(e) => setRoleDetails({...roleDetails, hireSurname: e.target.value})}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>LinkedIn Profile</label>
                        <input
                          type="url"
                          value={roleDetails.linkedinProfile}
                          onChange={(e) => setRoleDetails({...roleDetails, linkedinProfile: e.target.value})}
                          placeholder="https://linkedin.com/in/..."
                        />
                      </div>
                      <div className="form-group">
                        <label>Employee ID</label>
                        <input
                          type="text"
                          value={roleDetails.employeeId}
                          onChange={(e) => setRoleDetails({...roleDetails, employeeId: e.target.value})}
                          placeholder="Optional"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="subsection">
                    <h4>EMPLOYMENT DETAILS</h4>
                    <div className="form-grid">
                      <div className="form-group">
                        <label>Confirmed Start Date</label>
                        <input
                          type="date"
                          value={roleDetails.confirmedStartDate}
                          onChange={(e) => setRoleDetails({...roleDetails, confirmedStartDate: e.target.value})}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>Location</label>
                        <div className="combobox-container" ref={locationComboboxRef}>
                          <input
                            type="text"
                            className="combobox-input"
                            value={locationSearch || locationDisplay}
                            onChange={(e) => {
                              setLocationSearch(e.target.value);
                              setLocationDisplay(e.target.value);
                              setRoleDetails({...roleDetails, location: e.target.value});
                            }}
                            onFocus={() => {
                              setOpenCombobox('location');
                              setLocationSearch(locationDisplay);
                            }}
                            placeholder="Search for a country..."
                            required
                          />
                          {openCombobox === 'location' && (
                            <div className="combobox-options">
                              {filteredLocations.map(country => (
                                <div
                                  key={country}
                                  className="combobox-option"
                                  onClick={() => {
                                    setRoleDetails({...roleDetails, location: country});
                                    setLocationDisplay(country);
                                    setLocationSearch('');
                                    setOpenCombobox(null);
                                  }}
                                >
                                  {country}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Contract Type</label>
                        <div className="combobox-container" ref={contractTypeComboboxRef}>
                          <input
                            type="text"
                            className="combobox-input"
                            value={contractTypeDisplay || roleDetails.contractType}
                            onChange={(e) => {
                              setContractTypeSearch(e.target.value);
                              setContractTypeDisplay(e.target.value);
                              setRoleDetails({...roleDetails, contractType: e.target.value});
                            }}
                            onFocus={() => {
                              setOpenCombobox('contractType');
                              setContractTypeSearch(contractTypeDisplay);
                            }}
                            placeholder="Search contract type..."
                            required
                          />
                          {openCombobox === 'contractType' && (
                            <div className="combobox-options">
                              {filteredContractTypes.map(type => (
                                <div
                                  key={type}
                                  className="combobox-option"
                                  onClick={() => {
                                    setRoleDetails({...roleDetails, contractType: type});
                                    setContractTypeDisplay(type);
                                    setContractTypeSearch('');
                                    setOpenCombobox(null);
                                  }}
                                >
                                  {type}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="subsection">
                    <h4>COMPENSATION</h4>
                    <div className="form-grid">
                      <div className="form-group">
                        <label>Base Salary</label>
                        <input
                          type="number"
                          value={roleDetails.baseSalary}
                          onChange={(e) => setRoleDetails({...roleDetails, baseSalary: e.target.value})}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>Currency</label>
                        <div className="combobox-container" ref={currencyComboboxRef}>
                          <input
                            type="text"
                            className="combobox-input"
                            value={currencyDisplay || roleDetails.currency}
                            onChange={(e) => {
                              setCurrencySearch(e.target.value);
                              setCurrencyDisplay(e.target.value);
                              setRoleDetails({...roleDetails, currency: e.target.value});
                            }}
                            onFocus={() => {
                              setOpenCombobox('currency');
                              setCurrencySearch(currencyDisplay);
                            }}
                            placeholder="Search currency..."
                            required
                          />
                          {openCombobox === 'currency' && (
                            <div className="combobox-options">
                              {filteredCurrencies.map(currency => (
                                <div
                                  key={currency}
                                  className="combobox-option"
                                  onClick={() => {
                                    setRoleDetails({...roleDetails, currency: currency});
                                    setCurrencyDisplay(currency);
                                    setCurrencySearch('');
                                    setOpenCombobox(null);
                                  }}
                                >
                                  {currency}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Stipend</label>
                        <input
                          type="number"
                          value={roleDetails.stipend}
                          onChange={(e) => setRoleDetails({...roleDetails, stipend: e.target.value})}
                        />
                      </div>
                      <div className="form-group">
                        <label>Commission</label>
                        <input
                          type="number"
                          value={roleDetails.commission}
                          onChange={(e) => setRoleDetails({...roleDetails, commission: e.target.value})}
                        />
                      </div>
                      <div className="form-group">
                        <label>Employer Tax</label>
                        <input
                          type="number"
                          value={roleDetails.employerTax}
                          onChange={(e) => setRoleDetails({...roleDetails, employerTax: e.target.value})}
                        />
                      </div>
                      <div className="form-group">
                        <label>National Insurance</label>
                        <input
                          type="number"
                          value={roleDetails.nationalInsurance}
                          onChange={(e) => setRoleDetails({...roleDetails, nationalInsurance: e.target.value})}
                        />
                      </div>
                      <div className="form-group">
                        <label>Admin Fees</label>
                        <input
                          type="number"
                          value={roleDetails.adminFees}
                          onChange={(e) => setRoleDetails({...roleDetails, adminFees: e.target.value})}
                        />
                      </div>
                      <div className="form-group">
                        <label>Other Recurring</label>
                        <input
                          type="number"
                          value={roleDetails.otherRecurringPayments}
                          onChange={(e) => setRoleDetails({...roleDetails, otherRecurringPayments: e.target.value})}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              
              <div className="form-actions">
                <button type="button" onClick={onClose} className="cancel-button">Cancel</button>
                <button type="submit" className="save-button">
                  {recruitmentStatus === 'Completed' ? 'Complete Hire' : 'Save Changes'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
}

export default TARoleModal;