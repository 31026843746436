import React, { useState, useEffect, useContext, useRef } from 'react';
import { FirebaseContext } from '../../contexts/FirebaseContext';
import { doc, collection, addDoc, query, orderBy, onSnapshot, serverTimestamp, deleteDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import MentionsDropdown from './MentionsDropdown';
import './CommentsPanel.css';

export default function CommentsPanel({ isOpen, onClose, roleId, roleName }) {
  const { user: currentUser, getOrganizationUsers } = useContext(FirebaseContext);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [mentionedUsers, setMentionedUsers] = useState([]);
  const [mentionSearch, setMentionSearch] = useState(null);
  const [users, setUsers] = useState([]);
  const textareaRef = useRef(null);

  // Load organization users
  useEffect(() => {
    const loadUsers = async () => {
      try {
        const orgUsers = await getOrganizationUsers();
        console.log('Loaded users:', orgUsers); // Debug log
        if (!orgUsers || orgUsers.length === 0) {
          console.warn('No users loaded from getOrganizationUsers');
        }
        setUsers(orgUsers || []);
      } catch (error) {
        console.error('Error loading users:', error);
      }
    };
    loadUsers();
  }, [getOrganizationUsers]);

  // Load comments
  useEffect(() => {
    if (!roleId || !currentUser?.orgId) return;

    const commentsRef = collection(db, 'organizations', currentUser.orgId, 'roles', roleId, 'comments');
    const q = query(commentsRef, orderBy('timestamp', 'desc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const commentsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setComments(commentsData);
    });

    return () => unsubscribe();
  }, [roleId, currentUser?.orgId]);

  const handleTextareaChange = (e) => {
    const text = e.target.value;
    setNewComment(text);

    // Handle @ mentions
    const lastAtIndex = text.lastIndexOf('@');
    console.log('@ index:', lastAtIndex); // Debug log

    if (lastAtIndex !== -1) {
      const textAfterAt = text.slice(lastAtIndex + 1);
      const searchText = textAfterAt.split(/[\s\n]/)[0]; // Split on whitespace or newline
      console.log('Search text:', searchText); // Debug log
      console.log('Current users:', users); // Debug log

      // Calculate cursor position relative to textarea
      const textBeforeCursor = text.slice(0, e.target.selectionStart);
      const lines = textBeforeCursor.split('\n');
      const currentLineNumber = lines.length;
      const currentLineHeight = 20; // Approximate line height in pixels

      setMentionSearch(searchText);
      console.log('Setting mention search:', searchText); // Debug log
    } else {
      setMentionSearch(null);
    }
  };

  // Function to get caret coordinates in a textarea
  const getCaretCoordinates = (element, position) => {
    const { offsetLeft: elementX, offsetTop: elementY } = element;
    const div = document.createElement('div');
    const copyStyle = getComputedStyle(element);
    
    for (const prop of copyStyle) {
      div.style[prop] = copyStyle[prop];
    }

    div.style.position = 'absolute';
    div.style.visibility = 'hidden';
    div.style.whiteSpace = 'pre-wrap';

    const text = element.value.slice(0, position);
    div.textContent = text;
    
    const span = document.createElement('span');
    span.textContent = element.value.slice(position) || '.';
    div.appendChild(span);
    
    document.body.appendChild(div);
    const { offsetLeft: spanX, offsetTop: spanY } = span;
    document.body.removeChild(div);

    return {
      left: spanX - elementX,
      top: spanY - elementY
    };
  };

  const handleMentionSelect = (user) => {
    if (!user) {
      setMentionSearch(null);
      return;
    }

    const lastAtIndex = newComment.lastIndexOf('@');
    const beforeMention = newComment.slice(0, lastAtIndex);
    const afterMention = newComment.slice(lastAtIndex).split(/[\s\n]/).slice(1).join(' ');
    
    setNewComment(`${beforeMention}@${user.displayName} ${afterMention}`);
    setMentionedUsers([...mentionedUsers, user]);
    setMentionSearch(null);
  };

  const handleSubmitComment = async (e) => {
    e.preventDefault();
    if (!newComment.trim() || !roleId || !currentUser?.orgId) return;

    try {
      const commentsRef = collection(db, 'organizations', currentUser.orgId, 'roles', roleId, 'comments');
      const commentData = {
        text: newComment,
        userId: currentUser.uid,
        userName: currentUser.displayName || 'Anonymous',
        timestamp: serverTimestamp(),
        mentionedUsers: mentionedUsers.map(user => ({
          uid: user.uid,
          displayName: user.displayName,
          email: user.email
        }))
      };

      await addDoc(commentsRef, commentData);

      // Create notifications for mentioned users
      const notificationsRef = collection(db, 'organizations', currentUser.orgId, 'notifications');
      for (const user of mentionedUsers) {
        await addDoc(notificationsRef, {
          type: 'mention',
          userId: user.uid,
          sourceUserId: currentUser.uid,
          sourceUserName: currentUser.displayName || 'Anonymous',
          roleId,
          roleName,
          commentText: newComment,
          timestamp: serverTimestamp(),
          read: false
        });
      }

      setNewComment('');
      setMentionedUsers([]);
      setMentionSearch(null);
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const commentRef = doc(db, 'organizations', currentUser.orgId, 'roles', roleId, 'comments', commentId);
      await deleteDoc(commentRef);
      console.log('Comment deleted successfully');
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`comments-panel ${isOpen ? 'open' : ''}`}>
      <div className="comments-header">
        <div className="comments-header-content">
          <h3>Comments</h3>
          {roleName && <div className="role-title">{roleName}</div>}
        </div>
        <button onClick={onClose} className="close-button">×</button>
      </div>
      
      <div className="comments-list">
        {comments.map(comment => (
          <div 
            key={comment.id} 
            className={`comment ${comment.userId === currentUser?.uid ? 'own-comment' : ''}`}
          >
            <div className="comment-header">
              <span className="comment-author">{comment.userName}</span>
              <div className="comment-actions">
                <span className="comment-time">
                  {comment.timestamp?.toDate().toLocaleString()}
                </span>
                {comment.userId === currentUser?.uid && (
                  <button 
                    className="delete-comment-button"
                    onClick={() => handleDeleteComment(comment.id)}
                    title="Delete comment"
                  >
                    <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M3 6h18"></path>
                      <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                      <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                    </svg>
                  </button>
                )}
              </div>
            </div>
            <div className="comment-bubble">
              <div className="comment-text">{comment.text}</div>
            </div>
          </div>
        ))}
      </div>

      <form onSubmit={handleSubmitComment} className="comment-form">
        <div className="comment-input-container">
          <textarea
            ref={textareaRef}
            value={newComment}
            onChange={handleTextareaChange}
            placeholder="Write a comment... Use @ to mention users"
            className="comment-input"
          />
          {mentionSearch !== null && users.length > 0 && (
            <MentionsDropdown
              users={users}
              searchTerm={mentionSearch}
              onSelect={handleMentionSelect}
            />
          )}
        </div>
        <button type="submit" className="submit-button">
          Send
        </button>
      </form>
    </div>
  );
} 