import React, { useState, useEffect } from 'react';
import './ApprovalQueue.css';
import { doc, deleteDoc, setDoc, writeBatch, collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useFirebase } from '../../contexts/FirebaseContext';

function ApprovalQueue({ scenarios, employees, onUpdateApproval }) {
  const { user, canAccessFeatures } = useFirebase();
  console.log('=== Debug: ApprovalQueue render ===');
  console.log('Props:', {
    scenariosCount: scenarios?.length,
    employeesCount: employees?.length,
    hasUpdateCallback: !!onUpdateApproval
  });

  const [pendingApprovals, setPendingApprovals] = useState([]);
  const [potentialBackfills, setPotentialBackfills] = useState([]);
  const [temporaryCover, setTemporaryCover] = useState([]);
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterPriority, setFilterPriority] = useState('all');
  const [filterOrg, setFilterOrg] = useState('all');
  const [sortBy, setSortBy] = useState('date');
  const [sortDirection, setSortDirection] = useState('asc');
  const [recruitmentStatuses, setRecruitmentStatuses] = useState({});
  const [approvalStatuses, setApprovalStatuses] = useState({});
  const [roleDetails, setRoleDetails] = useState({});

  // Subscribe to recruitment statuses from Firestore
  useEffect(() => {
    if (!user?.orgId) return;

    const recruitmentStatusRef = collection(db, `organizations/${user.orgId}/recruitmentStatus`);
    const unsubscribe = onSnapshot(recruitmentStatusRef, (snapshot) => {
      const statuses = {};
      snapshot.forEach((doc) => {
        statuses[doc.id] = doc.data();
      });
      setRecruitmentStatuses(statuses);
    });

    return () => unsubscribe();
  }, [user?.orgId]);

  // Subscribe to approval statuses from Firestore
  useEffect(() => {
    if (!user?.orgId) {
      console.log('No user.orgId available for approval status subscription');
      return;
    }

    console.log('Setting up approval status subscription for org:', user.orgId);
    const approvalStatusesRef = collection(db, `organizations/${user.orgId}/approvalStatuses`);
    
    const unsubscribe = onSnapshot(approvalStatusesRef, (snapshot) => {
      console.log('=== Received approval status update ===');
      console.log('Number of approval documents:', snapshot.size);
      
      const statuses = {};
      snapshot.forEach((doc) => {
        const data = doc.data();
        statuses[doc.id] = data;
      });
      
      setApprovalStatuses(statuses);

      // Update pending approvals with new statuses
      setPendingApprovals(current =>
        current.map(hire => ({
          ...hire,
          approvalStatus: statuses[hire.id]?.status || 'Pending',
          approvalDate: statuses[hire.id]?.date
        }))
      );
    });

    return () => unsubscribe();
  }, [user?.orgId]);

  // Subscribe to role details from Firestore
  useEffect(() => {
    if (!user?.orgId) return;

    const roleDetailsRef = collection(db, `organizations/${user.orgId}/roleDetails`);
    const unsubscribe = onSnapshot(roleDetailsRef, (snapshot) => {
      const details = {};
      snapshot.forEach((doc) => {
        details[doc.id] = doc.data();
      });
      setRoleDetails(details);
    });

    return () => unsubscribe();
  }, [user?.orgId]);

  // Subscribe to backfills from Firestore
  useEffect(() => {
    if (!user?.orgId) return;

    console.log('=== Setting up backfills subscription in ApprovalQueue ===');
    console.log('User context:', {
      uid: user.uid,
      email: user.email,
      orgId: user.orgId,
      isAdmin: user.isAdmin,
      status: user.status
    });

    const backfillsRef = collection(db, `organizations/${user.orgId}/backfills`);
    console.log('Backfills collection path:', `organizations/${user.orgId}/backfills`);
    
    const unsubscribe = onSnapshot(backfillsRef, (snapshot) => {
      console.log('=== Backfills Snapshot in ApprovalQueue ===');
      console.log('Snapshot metadata:', {
        hasPendingWrites: snapshot.metadata.hasPendingWrites,
        fromCache: snapshot.metadata.fromCache,
        size: snapshot.size
      });

      const backfills = [];
      const tempCover = [];
      
      snapshot.forEach((doc) => {
        const data = doc.data();
        console.log('=== Processing Backfill Document in ApprovalQueue ===');
        console.log('Document ID:', doc.id);
        console.log('Document data:', {
          ...data,
          _raw: JSON.stringify(data, null, 2)
        });

        if (data.isTemporaryCover) {
          tempCover.push({
            ...data,
            id: doc.id
          });
        } else {
          backfills.push({
            ...data,
            id: doc.id
          });
        }
      });
      
      console.log('=== Final Backfills State in ApprovalQueue ===');
      console.log('Regular backfills:', {
        count: backfills.length,
        items: backfills.map(b => ({
          id: b.id,
          roleTitle: b.roleTitle,
          status: b.status,
          approvalStatus: b.approvalStatus
        }))
      });
      console.log('Temporary cover:', {
        count: tempCover.length,
        items: tempCover.map(b => ({
          id: b.id,
          roleTitle: b.roleTitle,
          status: b.status,
          approvalStatus: b.approvalStatus
        }))
      });
      
      setPotentialBackfills(backfills);
      setTemporaryCover(tempCover);
    });

    return () => unsubscribe();
  }, [user?.orgId]);

  // Load initial data and handle storage changes
  useEffect(() => {
    if (!onUpdateApproval || !user?.orgId) return;

    console.log('=== Debug: Setting up initial data handlers ===');
    
    // Subscribe to approval statuses from Firestore
    const approvalStatusesRef = collection(db, `organizations/${user.orgId}/approvalStatuses`);
    const unsubscribe = onSnapshot(approvalStatusesRef, (snapshot) => {
      const statuses = {};
      snapshot.forEach((doc) => {
        const data = doc.data();
        statuses[doc.id] = data;
      });
      
      // Get all hires from all scenarios
      const allHires = (scenarios || []).flatMap(scenario => 
        (scenario?.plannedHires || [])
          .filter(hire => {
            const approvalStatus = statuses[hire.id]?.status;
            return approvalStatus === 'Pending' || approvalStatus === 'Approved' || approvalStatus === 'Rejected';
          })
          .map(hire => ({
            ...hire,
            scenarioName: scenario?.name || scenario?.title || 'Unnamed Scenario',
            scenarioId: scenario?.id,
            approvalStatus: statuses[hire.id]?.status || hire.approvalStatus,
            approvalDate: statuses[hire.id]?.date || hire.approvalDate
          }))
      );

      console.log('Loaded hires with scenario IDs:', allHires);
      setPendingApprovals(allHires);
    });

    return () => unsubscribe();
  }, [scenarios, onUpdateApproval, user?.orgId]);

  // Handle backfill updates
  const handleBackfillUpdate = async (backfillId, newStatus, isTemporary = false) => {
    if (!user?.orgId) {
      console.error('No organization ID available');
      return;
    }

    try {
      console.log('=== Handling Backfill Update ===');
      console.log('Update params:', {
        backfillId,
        newStatus,
        isTemporary
      });

      const currentBackfill = isTemporary 
        ? temporaryCover.find(b => b.id === backfillId)
        : potentialBackfills.find(b => b.id === backfillId);

      if (!currentBackfill) {
        console.error('Backfill not found:', backfillId);
        return;
      }

      console.log('Current backfill data:', {
        ...currentBackfill,
        _raw: JSON.stringify(currentBackfill, null, 2)
      });

      const batch = writeBatch(db);
      const timestamp = new Date().toISOString();

      if (newStatus === 'Reset') {
        console.log('Resetting backfill status');
        // When resetting, delete recruitment status and planned hire
        const recruitmentStatusRef = doc(db, `organizations/${user.orgId}/recruitmentStatus/${backfillId}`);
        const plannedHireRef = doc(db, `organizations/${user.orgId}/plannedHires/${backfillId}`);
        batch.delete(recruitmentStatusRef);
        batch.delete(plannedHireRef);

        // Update backfill record to pending
        const backfillRef = doc(db, `organizations/${user.orgId}/backfills/${backfillId}`);
        const resetData = {
          ...currentBackfill,
          approvalStatus: 'Pending',
          status: 'Pending',
          recruitmentStatus: null,
          isApproved: false,
          updatedAt: new Date().toISOString(),
          updatedBy: user.uid,
          isVisible: true,
          isActive: true,
          latestStatusData: null
        };
        batch.set(backfillRef, resetData);

        // Update approval status
        const approvalRef = doc(db, `organizations/${user.orgId}/approvalStatuses/${backfillId}`);
        batch.set(approvalRef, {
          ...resetData,
          status: 'Pending',
          approvalStatus: 'Pending',
          date: new Date().toISOString(),
          updatedBy: user.uid,
          roleTitle: currentBackfill.roleTitle,
          type: isTemporary ? 'temporary-cover' : 'backfill',
          isApproved: false
        });

      } else {
        console.log('Updating backfill status to:', newStatus);
        
        // Create status data
        const statusData = {
          status: newStatus === 'Approved' ? 'Not Started' : null,
          recruitmentStatus: newStatus === 'Approved' ? 'Not Started' : null,
          updatedAt: timestamp,
          updatedBy: user.uid
        };

        // Common data for all updates
        const commonData = {
          ...currentBackfill,
          approvalStatus: newStatus,
          status: newStatus,
          isApproved: newStatus === 'Approved',
          updatedAt: timestamp,
          updatedBy: user.uid,
          recruitmentStatus: newStatus === 'Approved' ? 'Not Started' : null,
          isVisible: true,
          isActive: true,
          orgId: user.orgId,
          type: isTemporary ? 'temporary-cover' : 'backfill',
          priority: 'Medium',
          status_updated: timestamp,
          hiring_manager: currentBackfill.directManager,
          department: currentBackfill.team,
          role_status: newStatus === 'Approved' ? 'Active' : 'Pending',
          role_type: isTemporary ? 'Temporary Cover' : 'Backfill',
          source: isTemporary ? 'Temporary Cover' : 'Backfill',
          stage: 'Not Started',
          status_text: newStatus === 'Approved' ? 'Active' : 'Pending',
          hiring_type: isTemporary ? 'Temporary' : 'Permanent',
          cost_center: currentBackfill.team,
          location: currentBackfill.location || 'Not Specified',
          salary_band: currentBackfill.baseSalary ? `${currentBackfill.baseSalary}` : 'Not Specified',
          currency: currentBackfill.currency || 'USD',
          latestStatusData: statusData
        };

        console.log('Common data for updates:', {
          ...commonData,
          _raw: JSON.stringify(commonData, null, 2)
        });

        // Update backfill record
        const backfillRef = doc(db, `organizations/${user.orgId}/backfills/${backfillId}`);
        batch.set(backfillRef, commonData);
        console.log('Added backfill update to batch');

        // Update recruitment status if approved
        if (newStatus === 'Approved') {
          console.log('Adding recruitment status update for approved backfill');
          const recruitmentStatusRef = doc(db, `organizations/${user.orgId}/recruitmentStatus/${backfillId}`);
          const recruitmentData = {
            ...commonData,
            status: 'Not Started',
            recruitmentStatus: 'Not Started',
            updatedAt: timestamp,
            updatedBy: user.uid,
            updatedByTab: 'approvals',
            roleTitle: currentBackfill.roleTitle,
            type: isTemporary ? 'temporary-cover' : 'backfill',
            isApproved: true,
            isVisible: true,
            isActive: true,
            latestStatusData: statusData
          };
          batch.set(recruitmentStatusRef, recruitmentData);
          console.log('Added recruitment status update to batch');

          // Add to planned hires
          console.log('Adding to planned hires collection');
          const plannedHireRef = doc(db, `organizations/${user.orgId}/plannedHires/${backfillId}`);
          batch.set(plannedHireRef, commonData);
          console.log('Added planned hire update to batch');
        }

        // Update approval status
        console.log('Updating approval status');
        const approvalRef = doc(db, `organizations/${user.orgId}/approvalStatuses/${backfillId}`);
        const approvalData = {
          ...commonData,
          status: newStatus,
          approvalStatus: newStatus,
          date: timestamp,
          updatedBy: user.uid,
          roleTitle: currentBackfill.roleTitle,
          type: isTemporary ? 'temporary-cover' : 'backfill',
          isApproved: newStatus === 'Approved'
        };
        batch.set(approvalRef, approvalData);
        console.log('Added approval status update to batch');
      }

      console.log('Committing batch write for backfill approval...');
      await batch.commit();
      console.log('Successfully committed batch write');

    } catch (error) {
      console.error('=== Error updating backfill ===');
      console.error('Error details:', {
        error,
        message: error.message,
        code: error.code,
        stack: error.stack
      });
      alert('Error updating backfill status. Please try again.');
    }
  };

  // Check if user has access
  if (!user || !canAccessFeatures()) {
    return (
      <div className="no-access-message">
        <p>You don't have permission to access this feature. Please contact an administrator.</p>
      </div>
    );
  }

  if (!onUpdateApproval) {
    console.error('onUpdateApproval prop is required but not provided');
    return <div>Error: Missing required props</div>;
  }

  // Handle new position approval
  const handleApprovalUpdate = async (roleId, newStatus) => {
    if (!user?.orgId) {
      console.error('No organization ID available');
      return;
    }

    try {
      const currentHire = pendingApprovals.find(hire => hire.id === roleId);
      if (!currentHire) {
        console.error('Role not found:', roleId);
        return;
      }

      console.log('Current hire data:', currentHire);

      // Find the scenario that contains this hire
      const scenario = scenarios.find(s => s.id === currentHire.scenarioId);
      if (!scenario) {
        console.error('Scenario not found:', currentHire.scenarioId);
        return;
      }

      console.log('Found scenario:', scenario.id);

      const batch = writeBatch(db);

      if (newStatus === 'Reset') {
        // When resetting, delete recruitment status and planned hire
        const recruitmentStatusRef = doc(db, `organizations/${user.orgId}/recruitmentStatus/${roleId}`);
        const plannedHireRef = doc(db, `organizations/${user.orgId}/plannedHires/${roleId}`);
        batch.delete(recruitmentStatusRef);
        batch.delete(plannedHireRef);

        // Update approval status to pending
        const approvalRef = doc(db, `organizations/${user.orgId}/approvalStatuses/${roleId}`);
        batch.set(approvalRef, {
          status: 'Pending',
          date: new Date().toISOString(),
          updatedBy: user.uid,
          roleTitle: currentHire.roleTitle,
          type: 'new-position',
          orgId: user.orgId
        });

        // Update the hire in the scenario
        const updatedScenario = {
          ...scenario,
          plannedHires: scenario.plannedHires.map(hire => 
            hire.id === roleId 
              ? { ...hire, approvalStatus: 'Pending', status: 'Pending' }
              : hire
          )
        };

        if (onUpdateApproval) {
          await onUpdateApproval(scenario.id, updatedScenario);
        }
      } else {
        // Common data structure for both approval and plannedHire
        const commonData = {
          id: roleId,
          approvalStatus: newStatus,
          status: newStatus === 'Approved' ? 'Active' : 'Pending',
          updatedAt: new Date().toISOString(),
          created_at: currentHire.createdAt || new Date().toISOString(),
          approvalDate: new Date().toISOString(),
          approver: user.displayName || 'Current User',
          approverId: user.uid,
          roleTitle: currentHire.roleTitle,
          organization: currentHire.organization,
          team: currentHire.team,
          directManager: currentHire.directManager,
          startDate: currentHire.startDate || new Date().toISOString(),
          totalCost: currentHire.totalCost || currentHire.budget || 0,
          isBackfill: false,
          isTemporaryCover: false,
          recruitmentStatus: newStatus === 'Approved' ? 'Not Started' : null,
          isVisible: true,
          isActive: true,
          orgId: user.orgId,
          type: 'new-position',
          priority: currentHire.priority || 'Medium',
          status_updated: new Date().toISOString(),
          hiring_manager: currentHire.directManager,
          department: currentHire.team,
          role_status: newStatus === 'Approved' ? 'Active' : 'Pending',
          role_type: 'New Position',
          source: 'New Position Request',
          stage: 'Not Started',
          status_text: newStatus === 'Approved' ? 'Active' : 'Pending',
          hiring_type: 'Permanent',
          cost_center: currentHire.team,
          location: currentHire.location || 'Not Specified',
          salary_band: currentHire.baseSalary ? `${currentHire.baseSalary}` : 'Not Specified',
          currency: currentHire.currency || 'USD',
          scenarioId: currentHire.scenarioId,
          scenarioName: currentHire.scenarioName
        };

        // Update approval status
        const approvalRef = doc(db, `organizations/${user.orgId}/approvalStatuses/${roleId}`);
        batch.set(approvalRef, {
          status: newStatus,
          date: new Date().toISOString(),
          updatedBy: user.uid,
          roleTitle: currentHire.roleTitle,
          type: 'new-position',
          isApproved: newStatus === 'Approved',
          orgId: user.orgId
        });

        // If approved, update recruitment status and add to planned hires
        if (newStatus === 'Approved') {
          const recruitmentStatusRef = doc(db, `organizations/${user.orgId}/recruitmentStatus/${roleId}`);
          const recruitmentData = {
            status: 'Not Started',
            updatedAt: new Date().toISOString(),
            updatedBy: user.uid,
            updatedByTab: 'approvals',
            roleTitle: currentHire.roleTitle,
            type: 'new-position',
            isApproved: true,
            isVisible: true,
            isActive: true,
            orgId: user.orgId
          };
          batch.set(recruitmentStatusRef, recruitmentData);

          // Add to planned hires
          const plannedHireRef = doc(db, `organizations/${user.orgId}/plannedHires/${roleId}`);
          batch.set(plannedHireRef, commonData);
        }

        // Update the hire in the scenario
        const updatedScenario = {
          ...scenario,
          plannedHires: scenario.plannedHires.map(hire => 
            hire.id === roleId 
              ? { ...hire, ...commonData }
              : hire
          )
        };

        if (onUpdateApproval) {
          console.log('Updating scenario:', scenario.id, updatedScenario);
          await onUpdateApproval(scenario.id, updatedScenario);
        }
      }

      await batch.commit();
      console.log('Successfully updated approval status:', newStatus);

    } catch (error) {
      console.error('Error updating approval:', error);
      alert('Error updating approval status. Please try again.');
    }
  };

  const getUniqueOrgs = () => {
    return [...new Set(pendingApprovals.map(hire => hire.organization))];
  };

  const getPendingCount = () => {
    return pendingApprovals.filter(hire => hire.approvalStatus === 'Pending').length;
  };

  const filteredAndSortedApprovals = () => {
    let filtered = pendingApprovals.filter(hire => {
      if (filterStatus !== 'all' && (!hire.approvalStatus || hire.approvalStatus.toLowerCase() !== filterStatus)) return false;
      if (filterPriority !== 'all' && (!hire.priority || hire.priority.toLowerCase() !== filterPriority.toLowerCase())) return false;
      if (filterOrg !== 'all' && hire.organization !== filterOrg) return false;
      return true;
    });

    return filtered.sort((a, b) => {
      const direction = sortDirection === 'asc' ? 1 : -1;
      
      switch (sortBy) {
        case 'date':
          return direction * (new Date(a.startDate || 0) - new Date(b.startDate || 0));
        case 'cost':
          return direction * (Number(a.totalCost || 0) - Number(b.totalCost || 0));
        case 'priority': {
          const priorityWeight = { 'High': 3, 'Medium': 2, 'Low': 1 };
          return direction * ((priorityWeight[b.priority] || 0) - (priorityWeight[a.priority] || 0));
        }
        default:
          return 0;
      }
    });
  };

  const handleSort = (newSortBy) => {
    if (sortBy === newSortBy) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(newSortBy);
      setSortDirection('asc');
    }
  };

  // Update the role card display to use the correct data
  const renderRoleDetails = (hire) => (
    <>
      <div className="detail-group">
        <label>Organization:</label>
        <span>{hire.organization || 'Not Specified'}</span>
      </div>
      <div className="detail-group">
        <label>Team:</label>
        <span>{hire.team || 'Not Specified'}</span>
      </div>
      <div className="detail-group">
        <label>{hire.isCompleted ? 'Start Date' : 'Target Start'}:</label>
        <span>
          {hire.isCompleted ? 
            (hire.confirmedStartDate ? new Date(hire.confirmedStartDate).toLocaleDateString() : 'Not set') : 
            (hire.startDate ? new Date(hire.startDate).toLocaleDateString() : 'Not set')}
        </span>
      </div>
      <div className="detail-group">
        <label>{hire.isCompleted ? 'Salary' : 'Budget'}:</label>
        <span>
          {hire.isCompleted ? 
            (hire.baseSalary ? `${hire.currency || 'USD'} ${Number(hire.baseSalary).toLocaleString()}` : 'Not set') :
            (hire.totalCost ? `USD ${Number(hire.totalCost).toLocaleString()}` : 'Not set')}
        </span>
      </div>
      {hire.isCompleted && hire.location && (
        <div className="detail-group">
          <label>Location:</label>
          <span>{hire.location}</span>
        </div>
      )}
      <div className="detail-group">
        <label>Priority:</label>
        <span className={`priority-tag ${(hire.priority || 'medium').toLowerCase()}`}>
          {hire.priority || 'Medium'}
        </span>
      </div>
      <div className="detail-group">
        <label>Scenario:</label>
        <span>{hire.scenarioName}</span>
      </div>
      <div className="detail-group">
        <label>Reports To:</label>
        <span>{hire.directManager || 'Not Specified'}</span>
      </div>
    </>
  );

  return (
    <div className="approval-queue">
      <div className="approval-header">
        <h2>Hiring Approvals ({getPendingCount()} pending)</h2>
        <div className="filter-controls">
          <select 
            value={filterStatus} 
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">All Statuses</option>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </select>

          <select 
            value={filterPriority} 
            onChange={(e) => setFilterPriority(e.target.value)}
          >
            <option value="all">All Priorities</option>
            <option value="high">High Priority</option>
            <option value="medium">Medium Priority</option>
            <option value="low">Low Priority</option>
          </select>

          <select 
            value={filterOrg} 
            onChange={(e) => setFilterOrg(e.target.value)}
          >
            <option value="all">All Organizations</option>
            {getUniqueOrgs().map(org => (
              <option key={org} value={org}>{org}</option>
            ))}
          </select>
        </div>

        <div className="sort-controls">
          <button 
            onClick={() => handleSort('date')}
            className={sortBy === 'date' ? 'active' : ''}
          >
            Sort by Date {sortBy === 'date' && (sortDirection === 'asc' ? '↑' : '↓')}
          </button>
          <button 
            onClick={() => handleSort('cost')}
            className={sortBy === 'cost' ? 'active' : ''}
          >
            Sort by Cost {sortBy === 'cost' && (sortDirection === 'asc' ? '↑' : '↓')}
          </button>
          <button 
            onClick={() => handleSort('priority')}
            className={sortBy === 'priority' ? 'active' : ''}
          >
            Sort by Priority {sortBy === 'priority' && (sortDirection === 'asc' ? '↑' : '↓')}
          </button>
        </div>
      </div>

      <div className="approval-sections">
        {/* New Position Requests Section */}
        <section className="new-hires-section">
          <h3>New Position Requests</h3>
          <div className="approval-list">
            {filteredAndSortedApprovals().filter(hire => 
              !recruitmentStatuses[hire.id]?.status || 
              recruitmentStatuses[hire.id]?.status !== 'Completed'
            ).length === 0 ? (
              <div className="no-approvals">
                <p>No new position requests to display</p>
              </div>
            ) : (
              filteredAndSortedApprovals()
                .filter(hire => 
                  !recruitmentStatuses[hire.id]?.status || 
                  recruitmentStatuses[hire.id]?.status !== 'Completed'
                )
                .map(hire => (
                  <div key={hire.id} className={`approval-item ${hire.isCompleted ? 'completed' : ''}`}>
                    <div className="approval-header">
                      <div className="title-and-badge">
                        <div className="title-and-timestamp">
                          <h3>{hire.isCompleted && hire.hireName ? hire.hireName : hire.roleTitle}</h3>
                          {hire.approvalDate && (
                            <span className="timestamp">
                              Last updated: {new Date(hire.approvalDate).toLocaleString()}
                            </span>
                          )}
                        </div>
                        <span className={`request-type-badge ${hire.isCompleted ? 'hired' : 'new'}`}>
                          {hire.isCompleted ? 'Hired' : 'New position'}
                        </span>
                      </div>
                      <div className="status-badges">
                        {hire.approvalStatus === 'Approved' && recruitmentStatuses[hire.id]?.status && (
                          <span className={`recruitment-status ${(recruitmentStatuses[hire.id].status || '').toLowerCase().replace(' ', '-')}`}>
                            <span className="status-label">TA Status:</span> {recruitmentStatuses[hire.id].status}
                          </span>
                        )}
                        <span className={`status-badge ${(hire.approvalStatus || 'pending').toLowerCase()}`}>
                          {hire.approvalStatus || 'Pending'}
                        </span>
                      </div>
                    </div>
                    <div className="approval-details">
                      {renderRoleDetails(hire)}
                      <div className="approval-actions">
                        {(!hire.approvalStatus || hire.approvalStatus === 'Pending') ? (
                          <>
                            <button 
                              onClick={() => handleApprovalUpdate(hire.id, 'Approved')}
                              className="approve-button"
                            >
                              Approve
                            </button>
                            <button 
                              onClick={() => handleApprovalUpdate(hire.id, 'Rejected')}
                              className="reject-button"
                            >
                              Reject
                            </button>
                          </>
                        ) : (
                          <button 
                            onClick={() => {
                              console.log('=== Debug: Reset button clicked ===');
                              console.log('Hire ID:', hire.id);
                              console.log('Current approval status:', hire.approvalStatus);
                              handleApprovalUpdate(hire.id, 'Reset');
                            }}
                            className="reset-button"
                          >
                            Reset Status
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>
        </section>

        {/* Backfills Section */}
        <section className="backfills-section">
          <h3>Temporary Cover Needed</h3>
          <div className="approval-list">
            {temporaryCover.length === 0 ? (
              <div className="no-approvals">
                <p>No temporary cover needed at this time</p>
              </div>
            ) : (
              temporaryCover.map(backfill => (
                <div key={backfill.id} className="approval-item">
                  <div className="approval-header">
                    <div className="title-and-badge">
                      <div className="title-and-timestamp">
                        <h3>{backfill.roleTitle}</h3>
                        {backfill.approvalDate && (
                          <span className="timestamp">
                            Last updated: {new Date(backfill.approvalDate).toLocaleString()}
                          </span>
                        )}
                      </div>
                      <span className="request-type-badge temporary">Temporary Cover</span>
                    </div>
                    <div className="status-badges">
                      {backfill.approvalStatus === 'Approved' && recruitmentStatuses[backfill.id]?.status && (
                        <span className={`recruitment-status ${recruitmentStatuses[backfill.id].status.toLowerCase().replace(' ', '-')}`}>
                          <span className="status-label">TA Status:</span> {recruitmentStatuses[backfill.id].status}
                        </span>
                      )}
                      <span className={`status-badge ${(backfill.approvalStatus || 'pending').toLowerCase()}`}>
                        {backfill.approvalStatus || 'Pending'}
                      </span>
                    </div>
                  </div>
                  <div className="approval-details">
                    <div className="detail-group">
                      <label>Current Employee:</label>
                      <span>{backfill.currentEmployee}</span>
                    </div>
                    <div className="detail-group">
                      <label>Leave Type:</label>
                      <span>{backfill.temporaryLeaveType}</span>
                    </div>
                    <div className="detail-group">
                      <label>Leave Period:</label>
                      <span>{new Date(backfill.temporaryLeaveStart).toLocaleDateString()} - {new Date(backfill.temporaryLeaveEnd).toLocaleDateString()}</span>
                    </div>
                    <div className="detail-group">
                      <label>Organization:</label>
                      <span>{backfill.organization}</span>
                    </div>
                    <div className="detail-group">
                      <label>Team:</label>
                      <span>{backfill.team}</span>
                    </div>
                    <div className="detail-group">
                      <label>Reports To:</label>
                      <span>{backfill.directManager || 'Not Specified'}</span>
                    </div>
                  </div>
                  <div className="approval-actions">
                    {(!backfill.approvalStatus || backfill.approvalStatus === 'Pending') ? (
                      <>
                        <button
                          className="approve-button"
                          onClick={() => handleBackfillUpdate(backfill.id, 'Approved', true)}
                        >
                          Approve
                        </button>
                        <button
                          className="reject-button"
                          onClick={() => handleBackfillUpdate(backfill.id, 'Rejected', true)}
                        >
                          Reject
                        </button>
                      </>
                    ) : (
                      <button
                        className="reset-button"
                        onClick={() => handleBackfillUpdate(backfill.id, 'Reset', true)}
                      >
                        Reset Status
                      </button>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </section>

        <section className="backfills-section">
          <h3>Potential Backfill Positions</h3>
          <div className="approval-list">
            {potentialBackfills.length === 0 ? (
              <div className="no-approvals">
                <p>No potential backfills at this time</p>
              </div>
            ) : (
              potentialBackfills.map(backfill => (
                <div key={backfill.id} className="approval-item">
                  <div className="approval-header">
                    <div className="title-and-badge">
                      <div className="title-and-timestamp">
                        <h3>{backfill.roleTitle}</h3>
                        {backfill.approvalDate && (
                          <span className="timestamp">
                            Last updated: {new Date(backfill.approvalDate).toLocaleString()}
                          </span>
                        )}
                      </div>
                      <span className="request-type-badge backfill">Potential Backfill</span>
                    </div>
                    <div className="status-badges">
                      {backfill.approvalStatus === 'Approved' && recruitmentStatuses[backfill.id]?.status && (
                        <span className={`recruitment-status ${recruitmentStatuses[backfill.id].status.toLowerCase().replace(' ', '-')}`}>
                          <span className="status-label">TA Status:</span> {recruitmentStatuses[backfill.id].status}
                        </span>
                      )}
                      <span className={`status-badge ${(backfill.approvalStatus || 'pending').toLowerCase()}`}>
                        {backfill.approvalStatus || 'Pending'}
                      </span>
                    </div>
                  </div>
                  <div className="approval-details">
                    <div className="detail-group">
                      <label>Current Employee:</label>
                      <span>{backfill.currentEmployee}</span>
                    </div>
                    <div className="detail-group">
                      <label>Expected Leave Date:</label>
                      <span>{new Date(backfill.leaverDate).toLocaleDateString()}</span>
                    </div>
                    <div className="detail-group">
                      <label>Organization:</label>
                      <span>{backfill.organization}</span>
                    </div>
                    <div className="detail-group">
                      <label>Team:</label>
                      <span>{backfill.team}</span>
                    </div>
                    <div className="detail-group">
                      <label>Reports To:</label>
                      <span>{backfill.directManager || 'Not Specified'}</span>
                    </div>
                  </div>
                  <div className="approval-actions">
                    {(!backfill.approvalStatus || backfill.approvalStatus === 'Pending') ? (
                      <>
                        <button
                          className="approve-button"
                          onClick={() => handleBackfillUpdate(backfill.id, 'Approved')}
                        >
                          Approve
                        </button>
                        <button
                          className="reject-button"
                          onClick={() => handleBackfillUpdate(backfill.id, 'Rejected')}
                        >
                          Reject
                        </button>
                      </>
                    ) : (
                      <button
                        className="reset-button"
                        onClick={() => handleBackfillUpdate(backfill.id, 'Reset')}
                      >
                        Reset Status
                      </button>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </section>

        {/* Completed Hires Section */}
        <section className="completed-hires-section">
          <div className="section-wrapper">
            <h3>Completed Hires</h3>
            <div className="approval-list">
              {filteredAndSortedApprovals().filter(hire => 
                recruitmentStatuses[hire.id]?.status === 'Completed'
              ).length === 0 ? (
                <div className="no-approvals">
                  <p>No completed hires to display</p>
                </div>
              ) : (
                filteredAndSortedApprovals()
                  .filter(hire => 
                    recruitmentStatuses[hire.id]?.status === 'Completed'
                  )
                  .map(hire => (
                    <div key={hire.id} className={`approval-item ${hire.isCompleted ? 'completed' : ''}`}>
                      <div className="approval-header">
                        <div className="title-and-badge">
                          <div className="title-and-timestamp">
                            <h3>{hire.isCompleted && hire.hireName ? hire.hireName : hire.roleTitle}</h3>
                            {hire.approvalDate && (
                              <span className="timestamp">
                                Last updated: {new Date(hire.approvalDate).toLocaleString()}
                              </span>
                            )}
                          </div>
                          <span className={`request-type-badge ${hire.isCompleted ? 'hired' : 'new'}`}>
                            {hire.isCompleted ? 'Hired' : 'New position'}
                          </span>
                        </div>
                        <div className="status-badges">
                          {hire.approvalStatus === 'Approved' && recruitmentStatuses[hire.id]?.status && (
                            <span className={`recruitment-status ${(recruitmentStatuses[hire.id].status || '').toLowerCase().replace(' ', '-')}`}>
                              <span className="status-label">TA Status:</span> {recruitmentStatuses[hire.id].status}
                            </span>
                          )}
                          <span className={`status-badge ${(hire.approvalStatus || 'pending').toLowerCase()}`}>
                            {hire.approvalStatus || 'Pending'}
                          </span>
                        </div>
                      </div>
                      <div className="approval-details">
                        {renderRoleDetails(hire)}
                      </div>
                    </div>
                  ))
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ApprovalQueue;