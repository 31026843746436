import React, { createContext, useContext, useState, useEffect } from 'react';
import { useFirebase } from './FirebaseContext';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

const RoleContext = createContext();

export const ROLES = {
  ADMIN: 'Admin',
  TALENT_ACQUISITION: 'Talent Acquisition',
  ORG_PLANNER: 'Org Planner',
  VIEWER: 'Viewer'
};

const ROLE_ACCESS = {
  [ROLES.ADMIN]: {
    allowedRoutes: ['*'],
    defaultRoute: '/',
    navItems: ['*'],
    permissions: {
      canEditTeamViewer: true,
      canAddScenarioRoles: true,
      canEditScenarioRoles: true,
      canSeeBackfillButtons: true,
      canEditTalentAcquisition: true,
      canSeeCurrentOrg: true,
      canSeeApprovals: true,
      canSeeCompensation: true,
      canSeeCommission: true,
      canEditEmployees: true,
      canDeleteEmployees: true,
      canCreateScenarios: true,
      canEditScenarios: true,
      canDeleteScenarios: true,
      canManageRoles: true
    }
  },
  [ROLES.TALENT_ACQUISITION]: {
    allowedRoutes: ['/', '/talent', '/compensation', '/team-viewer'],
    defaultRoute: '/talent',
    navItems: ['organization', 'talent', 'compensation', 'team-viewer'],
    permissions: {
      canEditTeamViewer: true,
      canAddScenarioRoles: true,
      canEditScenarioRoles: true,
      canSeeBackfillButtons: true,
      canEditTalentAcquisition: true,
      canSeeCurrentOrg: true,
      canSeeApprovals: true,
      canSeeCompensation: true,
      canSeeCommission: true,
      canEditEmployees: true,
      canDeleteEmployees: false,
      canCreateScenarios: false,
      canEditScenarios: false,
      canDeleteScenarios: false,
      canManageRoles: false
    }
  },
  [ROLES.ORG_PLANNER]: {
    allowedRoutes: ['/team-viewer', '/scenarios', '/analysis', '/approvals', '/leavers', '/admin'],
    defaultRoute: '/team-viewer',
    navItems: ['team-viewer', 'scenarios', 'analysis', 'approvals', 'leavers', 'admin'],
    permissions: {
      canEditTeamViewer: true,
      canAddScenarioRoles: true,
      canEditScenarioRoles: true,
      canSeeBackfillButtons: true,
      canEditTalentAcquisition: true,
      canSeeCurrentOrg: true,
      canSeeApprovals: true,
      canSeeCompensation: true,
      canSeeCommission: true,
      canEditEmployees: true,
      canDeleteEmployees: true,
      canCreateScenarios: true,
      canEditScenarios: true,
      canDeleteScenarios: true,
      canManageRoles: false
    }
  },
  [ROLES.VIEWER]: {
    allowedRoutes: ['/team-viewer', '/scenarios', '/leavers', '/talent', '/admin'],
    defaultRoute: '/team-viewer',
    navItems: ['team-viewer', 'scenarios', 'leavers', 'talent', 'admin'],
    permissions: {
      canEditTeamViewer: false,
      canAddScenarioRoles: false,
      canEditScenarioRoles: false,
      canSeeBackfillButtons: false,
      canEditTalentAcquisition: false,
      canSeeCurrentOrg: false,
      canSeeApprovals: false,
      canSeeCompensation: false,
      canSeeCommission: false,
      canEditEmployees: false,
      canDeleteEmployees: false,
      canCreateScenarios: false,
      canEditScenarios: false,
      canDeleteScenarios: false,
      canManageRoles: false
    }
  }
};

export const ProtectedRoute = ({ children }) => {
  const { userRole } = useRole();
  const location = useLocation();

  const roleAccess = ROLE_ACCESS[userRole];
  const isAllowed = roleAccess.allowedRoutes.includes('*') || 
                   roleAccess.allowedRoutes.includes(location.pathname);

  if (!isAllowed) {
    return <Navigate to={roleAccess.defaultRoute} replace />;
  }

  return children;
};

export const RoleProvider = ({ children }) => {
  const { user } = useFirebase();
  const [userRole, setUserRole] = useState(ROLES.VIEWER);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user?.isAdmin) {
      setUserRole(ROLES.ADMIN);
    } else if (user?.role) {
      // Check if the user's role matches any of our defined roles
      const matchingRole = Object.values(ROLES).find(role => role === user.role);
      setUserRole(matchingRole || ROLES.VIEWER);
    } else {
      setUserRole(ROLES.VIEWER);
    }
  }, [user]);

  useEffect(() => {
    if (userRole && location.pathname !== '/') {
      const roleAccess = ROLE_ACCESS[userRole];
      const isAllowed = roleAccess.allowedRoutes.includes('*') || 
                       roleAccess.allowedRoutes.includes(location.pathname);

      if (!isAllowed) {
        navigate(roleAccess.defaultRoute);
      }
    }
  }, [userRole, location.pathname, navigate]);

  const canAccessRoute = (route) => {
    const roleAccess = ROLE_ACCESS[userRole];
    return roleAccess.allowedRoutes.includes('*') || 
           roleAccess.allowedRoutes.includes(route);
  };

  const canAccessNavItem = (navItem) => {
    const roleAccess = ROLE_ACCESS[userRole];
    return roleAccess.navItems.includes('*') || 
           roleAccess.navItems.includes(navItem);
  };

  const hasPermission = (permission) => {
    const roleAccess = ROLE_ACCESS[userRole];
    return roleAccess.permissions[permission] || false;
  };

  return (
    <RoleContext.Provider value={{ 
      userRole, 
      canAccessRoute, 
      canAccessNavItem,
      hasPermission,
      ROLES: Object.values(ROLES)
    }}>
      {children}
    </RoleContext.Provider>
  );
};

export const useRole = () => {
  const context = useContext(RoleContext);
  if (!context) {
    throw new Error('useRole must be used within a RoleProvider');
  }
  return context;
};

export default RoleContext; 